/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createLastAsdQuarterBill } from '../fn/billable-services-quarter-controller/create-last-asd-quarter-bill';
import { CreateLastAsdQuarterBill$Params } from '../fn/billable-services-quarter-controller/create-last-asd-quarter-bill';
import { createNewAsdQuarterBill } from '../fn/billable-services-quarter-controller/create-new-asd-quarter-bill';
import { CreateNewAsdQuarterBill$Params } from '../fn/billable-services-quarter-controller/create-new-asd-quarter-bill';
import { DocumentLinkDto } from '../models/document-link-dto';
import { getActiveQuarter } from '../fn/billable-services-quarter-controller/get-active-quarter';
import { GetActiveQuarter$Params } from '../fn/billable-services-quarter-controller/get-active-quarter';
import { getActiveQuarterAndOpenHours } from '../fn/billable-services-quarter-controller/get-active-quarter-and-open-hours';
import { GetActiveQuarterAndOpenHours$Params } from '../fn/billable-services-quarter-controller/get-active-quarter-and-open-hours';
import { getQuarterBillData } from '../fn/billable-services-quarter-controller/get-quarter-bill-data';
import { GetQuarterBillData$Params } from '../fn/billable-services-quarter-controller/get-quarter-bill-data';
import { increaseActiveQuarter } from '../fn/billable-services-quarter-controller/increase-active-quarter';
import { IncreaseActiveQuarter$Params } from '../fn/billable-services-quarter-controller/increase-active-quarter';
import { QuarterAndOpenHoursDto } from '../models/quarter-and-open-hours-dto';
import { QuarterBillDataDto } from '../models/quarter-bill-data-dto';


/**
 * Rest operations on billable services quarter.
 */
@Injectable({ providedIn: 'root' })
export class BillableServicesQuarterControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getActiveQuarter()` */
  static readonly GetActiveQuarterPath = '/billable-services-quarter/{svgId}';

  /**
   * Get active quarter of svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveQuarter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveQuarter$Response(params: GetActiveQuarter$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getActiveQuarter(this.http, this.rootUrl, params, context);
  }

  /**
   * Get active quarter of svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveQuarter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveQuarter(params: GetActiveQuarter$Params, context?: HttpContext): Observable<number> {
    return this.getActiveQuarter$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `increaseActiveQuarter()` */
  static readonly IncreaseActiveQuarterPath = '/billable-services-quarter/{svgId}';

  /**
   * Write new quarter per svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `increaseActiveQuarter()` instead.
   *
   * This method doesn't expect any request body.
   */
  increaseActiveQuarter$Response(params: IncreaseActiveQuarter$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return increaseActiveQuarter(this.http, this.rootUrl, params, context);
  }

  /**
   * Write new quarter per svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `increaseActiveQuarter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  increaseActiveQuarter(params: IncreaseActiveQuarter$Params, context?: HttpContext): Observable<number> {
    return this.increaseActiveQuarter$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `createNewAsdQuarterBill()` */
  static readonly CreateNewAsdQuarterBillPath = '/billable-services-quarter/{svgId}/asd-quarter-bill';

  /**
   * create new asd quarter bill
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewAsdQuarterBill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewAsdQuarterBill$Response(params: CreateNewAsdQuarterBill$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return createNewAsdQuarterBill(this.http, this.rootUrl, params, context);
  }

  /**
   * create new asd quarter bill
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewAsdQuarterBill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewAsdQuarterBill(params: CreateNewAsdQuarterBill$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.createNewAsdQuarterBill$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `createLastAsdQuarterBill()` */
  static readonly CreateLastAsdQuarterBillPath = '/billable-services-quarter/{svgId}/asd-quarter-bill/last';

  /**
   * create last asd quarter bill
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLastAsdQuarterBill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLastAsdQuarterBill$Response(params: CreateLastAsdQuarterBill$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return createLastAsdQuarterBill(this.http, this.rootUrl, params, context);
  }

  /**
   * create last asd quarter bill
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLastAsdQuarterBill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLastAsdQuarterBill(params: CreateLastAsdQuarterBill$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.createLastAsdQuarterBill$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getActiveQuarterAndOpenHours()` */
  static readonly GetActiveQuarterAndOpenHoursPath = '/billable-services-quarter/{svgId}/open-hours';

  /**
   * Get active quarter of svg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveQuarterAndOpenHours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveQuarterAndOpenHours$Response(params: GetActiveQuarterAndOpenHours$Params, context?: HttpContext): Observable<StrictHttpResponse<QuarterAndOpenHoursDto>> {
    return getActiveQuarterAndOpenHours(this.http, this.rootUrl, params, context);
  }

  /**
   * Get active quarter of svg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveQuarterAndOpenHours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveQuarterAndOpenHours(params: GetActiveQuarterAndOpenHours$Params, context?: HttpContext): Observable<QuarterAndOpenHoursDto> {
    return this.getActiveQuarterAndOpenHours$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuarterAndOpenHoursDto>): QuarterAndOpenHoursDto => r.body)
    );
  }

  /** Path part for operation `getQuarterBillData()` */
  static readonly GetQuarterBillDataPath = '/billable-services-quarter/{svgId}/asd-quarter-bill/data';

  /**
   * get quarter bill data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuarterBillData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuarterBillData$Response(params: GetQuarterBillData$Params, context?: HttpContext): Observable<StrictHttpResponse<QuarterBillDataDto>> {
    return getQuarterBillData(this.http, this.rootUrl, params, context);
  }

  /**
   * get quarter bill data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuarterBillData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuarterBillData(params: GetQuarterBillData$Params, context?: HttpContext): Observable<QuarterBillDataDto> {
    return this.getQuarterBillData$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuarterBillDataDto>): QuarterBillDataDto => r.body)
    );
  }

}
