/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllTrainingTypes } from '../fn/training-types-controller/get-all-training-types';
import { GetAllTrainingTypes$Params } from '../fn/training-types-controller/get-all-training-types';
import { TrainingTypeDto } from '../models/training-type-dto';

@Injectable({ providedIn: 'root' })
export class TrainingTypesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTrainingTypes()` */
  static readonly GetAllTrainingTypesPath = '/training-types';

  /**
   * get all available training types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTrainingTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTrainingTypes$Response(params?: GetAllTrainingTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TrainingTypeDto>>> {
    return getAllTrainingTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * get all available training types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTrainingTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTrainingTypes(params?: GetAllTrainingTypes$Params, context?: HttpContext): Observable<Array<TrainingTypeDto>> {
    return this.getAllTrainingTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingTypeDto>>): Array<TrainingTypeDto> => r.body)
    );
  }

}
