/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ParticipantsControllerService } from './services/participants-controller.service';
import { TrainingCustomerControllerService } from './services/training-customer-controller.service';
import { StandardTrainingControllerService } from './services/standard-training-controller.service';
import { ShtTrainingControllerService } from './services/sht-training-controller.service';
import { FireSafetyTrainingControllerService } from './services/fire-safety-training-controller.service';
import { TrainingTypesControllerService } from './services/training-types-controller.service';
import { TrainingSubjectControllerService } from './services/training-subject-controller.service';
import { ParticipationCategoryControllerService } from './services/participation-category-controller.service';
import { TrainingMethodsControllerService } from './services/training-methods-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ParticipantsControllerService,
    TrainingCustomerControllerService,
    StandardTrainingControllerService,
    ShtTrainingControllerService,
    FireSafetyTrainingControllerService,
    TrainingTypesControllerService,
    TrainingSubjectControllerService,
    ParticipationCategoryControllerService,
    TrainingMethodsControllerService,
    ApiConfiguration
  ],
})
export class CustomersTrainingModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersTrainingModule> {
    return {
      ngModule: CustomersTrainingModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersTrainingModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersTrainingModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
