/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FieldOfCareDto } from '../models/field-of-care-dto';
import { getCachedDocument } from '../fn/public-documents-controller/get-cached-document';
import { GetCachedDocument$Params } from '../fn/public-documents-controller/get-cached-document';
import { getCachedDocxDocument } from '../fn/public-documents-controller/get-cached-docx-document';
import { GetCachedDocxDocument$Params } from '../fn/public-documents-controller/get-cached-docx-document';
import { getCachedGifDocument } from '../fn/public-documents-controller/get-cached-gif-document';
import { GetCachedGifDocument$Params } from '../fn/public-documents-controller/get-cached-gif-document';
import { getCachedJpgDocument } from '../fn/public-documents-controller/get-cached-jpg-document';
import { GetCachedJpgDocument$Params } from '../fn/public-documents-controller/get-cached-jpg-document';
import { getCachedPdfDocument } from '../fn/public-documents-controller/get-cached-pdf-document';
import { GetCachedPdfDocument$Params } from '../fn/public-documents-controller/get-cached-pdf-document';
import { getCachedPngDocument } from '../fn/public-documents-controller/get-cached-png-document';
import { GetCachedPngDocument$Params } from '../fn/public-documents-controller/get-cached-png-document';
import { getDocumentTypes } from '../fn/public-documents-controller/get-document-types';
import { GetDocumentTypes$Params } from '../fn/public-documents-controller/get-document-types';
import { getDocumentTypesAsd } from '../fn/public-documents-controller/get-document-types-asd';
import { GetDocumentTypesAsd$Params } from '../fn/public-documents-controller/get-document-types-asd';
import { getDocumentTypesIv } from '../fn/public-documents-controller/get-document-types-iv';
import { GetDocumentTypesIv$Params } from '../fn/public-documents-controller/get-document-types-iv';
import { getFieldsOfCare } from '../fn/public-documents-controller/get-fields-of-care';
import { GetFieldsOfCare$Params } from '../fn/public-documents-controller/get-fields-of-care';
import { ResponseDocumentTypeDto } from '../models/response-document-type-dto';


/**
 * Provides operations to manage public documents.
 */
@Injectable({ providedIn: 'root' })
export class PublicDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDocumentTypes()` */
  static readonly GetDocumentTypesPath = '/public/documents/types';

  /**
   * Get all document types with filtering.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentTypeDto>> {
    return getDocumentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all document types with filtering.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: GetDocumentTypes$Params, context?: HttpContext): Observable<ResponseDocumentTypeDto> {
    return this.getDocumentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentTypeDto>): ResponseDocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `getDocumentTypesIv()` */
  static readonly GetDocumentTypesIvPath = '/public/documents/types/iv';

  /**
   * Get document types - non-ASD, for IV customers, Koodi/BO.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypesIv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesIv$Response(params?: GetDocumentTypesIv$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentTypeDto>> {
    return getDocumentTypesIv(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document types - non-ASD, for IV customers, Koodi/BO.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypesIv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesIv(params?: GetDocumentTypesIv$Params, context?: HttpContext): Observable<ResponseDocumentTypeDto> {
    return this.getDocumentTypesIv$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentTypeDto>): ResponseDocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `getDocumentTypesAsd()` */
  static readonly GetDocumentTypesAsdPath = '/public/documents/types/asd';

  /**
   * Get document types - only ASD
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypesAsd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesAsd$Response(params?: GetDocumentTypesAsd$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseDocumentTypeDto>> {
    return getDocumentTypesAsd(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document types - only ASD
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypesAsd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypesAsd(params?: GetDocumentTypesAsd$Params, context?: HttpContext): Observable<ResponseDocumentTypeDto> {
    return this.getDocumentTypesAsd$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseDocumentTypeDto>): ResponseDocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `getFieldsOfCare()` */
  static readonly GetFieldsOfCarePath = '/public/documents/fields-of-care';

  /**
   * Get available fields of care.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldsOfCare()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsOfCare$Response(params?: GetFieldsOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FieldOfCareDto>>> {
    return getFieldsOfCare(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available fields of care.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFieldsOfCare$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsOfCare(params?: GetFieldsOfCare$Params, context?: HttpContext): Observable<Array<FieldOfCareDto>> {
    return this.getFieldsOfCare$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FieldOfCareDto>>): Array<FieldOfCareDto> => r.body)
    );
  }

  /** Path part for operation `getCachedDocument()` */
  static readonly GetCachedDocumentPath = '/public/documents/download/{uuid}';

  /**
   * Download point for document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocument$Response(params: GetCachedDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocument(params: GetCachedDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCachedPngDocument()` */
  static readonly GetCachedPngDocumentPath = '/public/documents/download/{uuid}.png';

  /**
   * Download point for PNG document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedPngDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedPngDocument$Response(params: GetCachedPngDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedPngDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for PNG document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedPngDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedPngDocument(params: GetCachedPngDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedPngDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCachedPdfDocument()` */
  static readonly GetCachedPdfDocumentPath = '/public/documents/download/{uuid}.pdf';

  /**
   * Download point for PDF document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedPdfDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedPdfDocument$Response(params: GetCachedPdfDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedPdfDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for PDF document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedPdfDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedPdfDocument(params: GetCachedPdfDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedPdfDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCachedJpgDocument()` */
  static readonly GetCachedJpgDocumentPath = '/public/documents/download/{uuid}.jpg';

  /**
   * Download point for JPG document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedJpgDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedJpgDocument$Response(params: GetCachedJpgDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedJpgDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for JPG document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedJpgDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedJpgDocument(params: GetCachedJpgDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedJpgDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCachedGifDocument()` */
  static readonly GetCachedGifDocumentPath = '/public/documents/download/{uuid}.gif';

  /**
   * Download point for GIF document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedGifDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedGifDocument$Response(params: GetCachedGifDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedGifDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for GIF document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedGifDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedGifDocument(params: GetCachedGifDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedGifDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getCachedDocxDocument()` */
  static readonly GetCachedDocxDocumentPath = '/public/documents/download/{uuid}.docx';

  /**
   * Download point for DOCX document from cache.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedDocxDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocxDocument$Response(params: GetCachedDocxDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCachedDocxDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for DOCX document from cache.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedDocxDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocxDocument(params: GetCachedDocxDocument$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCachedDocxDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
