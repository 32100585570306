import { Injectable } from '@angular/core';
import { Observable, forkJoin, iif, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AsiCustomerDto, AsiUserDto } from '@myasi/api/myasi-auth/permissions';

import { isDefined } from '@mysvg/utils';

import { ContextState } from '../../context/enums/context-state.enum';
import { AsiContext } from '../../context/models/asi-context.model';
import { DefaultUserTokenClaims } from '../../context/models/user-token-claims.model';
import { AuthRepositoryService } from '../repositories/auth-repository.service';
import { AuthenticationService } from './authentication.service';
import { OauthWrapperService } from './oauth-wrapper.service';

@Injectable({ providedIn: 'root' })
export class AsiAuthenticationService extends AuthenticationService<AsiContext> {
	constructor(
		private authRepositoryService: AuthRepositoryService,
		private oauthWrapperService: OauthWrapperService<DefaultUserTokenClaims>,
	) {
		super();
	}

	notLoggedIn(): AsiContext {
		return new AsiContext([], ContextState.NOT_LOGGED_IN);
	}

	/**
	 * [NOTE] failed `getDecodedToken` or `getUser` will result by logout (done by mac)
	 * 				failed `getCustomer` will result in staff context and error message (handled by `authRepositoryService`)
	 */
	postLoginFlow(contextId: string | null): Observable<AsiContext> {
		return forkJoin([this.oauthWrapperService.getDecodedToken(), this.authRepositoryService.getUser(), this.getCustomer(contextId)]).pipe(
			map(([userTokenClaims, user, customer]: [DefaultUserTokenClaims, AsiUserDto, AsiCustomerDto]) =>
				this.createContext(userTokenClaims, user, customer),
			),
		);
	}

	private getCustomer(contextId: string | null): Observable<AsiCustomerDto> {
		// parseInt returns NaN for undefined, null and unparsable strings
		const customerId: number = Number.parseInt(contextId, 10);
		return iif(() => !isNaN(customerId), this.authRepositoryService.getCustomer(customerId), of(null));
	}

	private createContext(userTokenClaims: DefaultUserTokenClaims, user: AsiUserDto, customer?: AsiCustomerDto): AsiContext {
		const contextState = this.getContextState(userTokenClaims, user, customer);

		return new AsiContext(
			user.activities,
			contextState,
			// optionals
			user.asdRegistration,
			customer,
			user.email,
			user.firstname,
			user.id,
			user.lastname,
			user.level,
			user.projects,
			user.asiSvgInfoDto,
			user.userName,
		);
	}

	/**
	 * [NOTE] create context by token, user info and customer info
	 *
	 * [CAUTION] if there will be customer users, those checks must be extended by checking user for staff or customer
	 */
	private getContextState(userTokenClaims: DefaultUserTokenClaims, user: AsiUserDto, customer?: AsiCustomerDto): ContextState {
		if (isDefined(userTokenClaims) && isDefined(user) && isDefined(customer)) {
			return ContextState.STAFF_CUSTOMER;
		} else if (isDefined(userTokenClaims) && isDefined(user)) {
			return ContextState.STAFF;
		} else {
			return ContextState.NOT_LOGGED_IN;
		}
	}
}
