import { Pipe, PipeTransform } from '@angular/core';

/**
 * @ngModule UtilsModule
 * @description
 *
 * Converts a number into its roman numeral representation.
 *
 * @usageNotes
 *
 * If a number less than or equal to 0 is provided, no conversion takes place and the number is returned in its original representation.
 *
 */
@Pipe({ name: 'numberToRomanNumeral' })
export class NumberToRomanNumeral implements PipeTransform {
	transform(input: number): string {
		if (input <= 0) return input.toString();
		return this.romanize(input);
	}

	private romanize(input: number) {
		const lookup: { [key: string]: number } = {
			M: 1000,
			CM: 900,
			D: 500,
			CD: 400,
			C: 100,
			XC: 90,
			L: 50,
			XL: 40,
			X: 10,
			IX: 9,
			V: 5,
			IV: 4,
			I: 1,
		};
		let roman = '';
		let i;

		for (i in lookup) {
			while (input >= lookup[i]) {
				roman += i;
				input -= lookup[i];
			}
		}
		return roman;
	}
}
