/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AnnualBudgetDto } from '../models/annual-budget-dto';
import { BudgetOverviewYearDto } from '../models/budget-overview-year-dto';
import { getAnnualBudget } from '../fn/budget-controller/get-annual-budget';
import { GetAnnualBudget$Params } from '../fn/budget-controller/get-annual-budget';
import { getBudgetOverviewPerYear } from '../fn/budget-controller/get-budget-overview-per-year';
import { GetBudgetOverviewPerYear$Params } from '../fn/budget-controller/get-budget-overview-per-year';
import { getDefaultBudgetValues } from '../fn/budget-controller/get-default-budget-values';
import { GetDefaultBudgetValues$Params } from '../fn/budget-controller/get-default-budget-values';
import { getMonthlyBudget } from '../fn/budget-controller/get-monthly-budget';
import { GetMonthlyBudget$Params } from '../fn/budget-controller/get-monthly-budget';
import { updateAnnualBudgetByHours } from '../fn/budget-controller/update-annual-budget-by-hours';
import { UpdateAnnualBudgetByHours$Params } from '../fn/budget-controller/update-annual-budget-by-hours';
import { updateAnnualBudgetByPercentage } from '../fn/budget-controller/update-annual-budget-by-percentage';
import { UpdateAnnualBudgetByPercentage$Params } from '../fn/budget-controller/update-annual-budget-by-percentage';
import { updateDefaultBudgetValues } from '../fn/budget-controller/update-default-budget-values';
import { UpdateDefaultBudgetValues$Params } from '../fn/budget-controller/update-default-budget-values';
import { updateMonthlyBudget } from '../fn/budget-controller/update-monthly-budget';
import { UpdateMonthlyBudget$Params } from '../fn/budget-controller/update-monthly-budget';
import { UsersBudgetDto } from '../models/users-budget-dto';
import { UsersDefaultBudgetDto } from '../models/users-default-budget-dto';


/**
 * Rest operations on budget management.
 */
@Injectable({ providedIn: 'root' })
export class BudgetControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMonthlyBudget()` */
  static readonly GetMonthlyBudgetPath = '/budget-management/{year}/{month}';

  /**
   * Get users monthly budget.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonthlyBudget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyBudget$Response(params: GetMonthlyBudget$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UsersBudgetDto>>> {
    return getMonthlyBudget(this.http, this.rootUrl, params, context);
  }

  /**
   * Get users monthly budget.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonthlyBudget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyBudget(params: GetMonthlyBudget$Params, context?: HttpContext): Observable<Array<UsersBudgetDto>> {
    return this.getMonthlyBudget$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UsersBudgetDto>>): Array<UsersBudgetDto> => r.body)
    );
  }

  /** Path part for operation `updateMonthlyBudget()` */
  static readonly UpdateMonthlyBudgetPath = '/budget-management/{year}/{month}';

  /**
   * Update users monthly budget.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMonthlyBudget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMonthlyBudget$Response(params: UpdateMonthlyBudget$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UsersBudgetDto>>> {
    return updateMonthlyBudget(this.http, this.rootUrl, params, context);
  }

  /**
   * Update users monthly budget.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMonthlyBudget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMonthlyBudget(params: UpdateMonthlyBudget$Params, context?: HttpContext): Observable<Array<UsersBudgetDto>> {
    return this.updateMonthlyBudget$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UsersBudgetDto>>): Array<UsersBudgetDto> => r.body)
    );
  }

  /** Path part for operation `updateAnnualBudgetByPercentage()` */
  static readonly UpdateAnnualBudgetByPercentagePath = '/budget-management/{year}/by-percent';

  /**
   * Update annual budget of users svg by percentage, hours will be calculated and saved.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnnualBudgetByPercentage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnualBudgetByPercentage$Response(params: UpdateAnnualBudgetByPercentage$Params, context?: HttpContext): Observable<StrictHttpResponse<AnnualBudgetDto>> {
    return updateAnnualBudgetByPercentage(this.http, this.rootUrl, params, context);
  }

  /**
   * Update annual budget of users svg by percentage, hours will be calculated and saved.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnnualBudgetByPercentage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnualBudgetByPercentage(params: UpdateAnnualBudgetByPercentage$Params, context?: HttpContext): Observable<AnnualBudgetDto> {
    return this.updateAnnualBudgetByPercentage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnualBudgetDto>): AnnualBudgetDto => r.body)
    );
  }

  /** Path part for operation `updateAnnualBudgetByHours()` */
  static readonly UpdateAnnualBudgetByHoursPath = '/budget-management/{year}/by-hours';

  /**
   * Update annual budget of users svg by hours, percentages will be calculated and saved.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnnualBudgetByHours()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnualBudgetByHours$Response(params: UpdateAnnualBudgetByHours$Params, context?: HttpContext): Observable<StrictHttpResponse<AnnualBudgetDto>> {
    return updateAnnualBudgetByHours(this.http, this.rootUrl, params, context);
  }

  /**
   * Update annual budget of users svg by hours, percentages will be calculated and saved.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnnualBudgetByHours$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnualBudgetByHours(params: UpdateAnnualBudgetByHours$Params, context?: HttpContext): Observable<AnnualBudgetDto> {
    return this.updateAnnualBudgetByHours$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnualBudgetDto>): AnnualBudgetDto => r.body)
    );
  }

  /** Path part for operation `getDefaultBudgetValues()` */
  static readonly GetDefaultBudgetValuesPath = '/budget-management/default-budget-value';

  /**
   * Get users default monthly budget value.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultBudgetValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultBudgetValues$Response(params?: GetDefaultBudgetValues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UsersDefaultBudgetDto>>> {
    return getDefaultBudgetValues(this.http, this.rootUrl, params, context);
  }

  /**
   * Get users default monthly budget value.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultBudgetValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultBudgetValues(params?: GetDefaultBudgetValues$Params, context?: HttpContext): Observable<Array<UsersDefaultBudgetDto>> {
    return this.getDefaultBudgetValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UsersDefaultBudgetDto>>): Array<UsersDefaultBudgetDto> => r.body)
    );
  }

  /** Path part for operation `updateDefaultBudgetValues()` */
  static readonly UpdateDefaultBudgetValuesPath = '/budget-management/default-budget-value';

  /**
   * Update users default monthly budget value.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaultBudgetValues()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDefaultBudgetValues$Response(params: UpdateDefaultBudgetValues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UsersDefaultBudgetDto>>> {
    return updateDefaultBudgetValues(this.http, this.rootUrl, params, context);
  }

  /**
   * Update users default monthly budget value.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDefaultBudgetValues$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDefaultBudgetValues(params: UpdateDefaultBudgetValues$Params, context?: HttpContext): Observable<Array<UsersDefaultBudgetDto>> {
    return this.updateDefaultBudgetValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UsersDefaultBudgetDto>>): Array<UsersDefaultBudgetDto> => r.body)
    );
  }

  /** Path part for operation `getAnnualBudget()` */
  static readonly GetAnnualBudgetPath = '/budget-management/{year}';

  /**
   * Get annual budget of users svg.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnnualBudget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnualBudget$Response(params: GetAnnualBudget$Params, context?: HttpContext): Observable<StrictHttpResponse<AnnualBudgetDto>> {
    return getAnnualBudget(this.http, this.rootUrl, params, context);
  }

  /**
   * Get annual budget of users svg.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnnualBudget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnualBudget(params: GetAnnualBudget$Params, context?: HttpContext): Observable<AnnualBudgetDto> {
    return this.getAnnualBudget$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnualBudgetDto>): AnnualBudgetDto => r.body)
    );
  }

  /** Path part for operation `getBudgetOverviewPerYear()` */
  static readonly GetBudgetOverviewPerYearPath = '/budget-management/overview-per-year';

  /**
   * Get budget overview grouped by year
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBudgetOverviewPerYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBudgetOverviewPerYear$Response(params?: GetBudgetOverviewPerYear$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BudgetOverviewYearDto>>> {
    return getBudgetOverviewPerYear(this.http, this.rootUrl, params, context);
  }

  /**
   * Get budget overview grouped by year
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBudgetOverviewPerYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBudgetOverviewPerYear(params?: GetBudgetOverviewPerYear$Params, context?: HttpContext): Observable<Array<BudgetOverviewYearDto>> {
    return this.getBudgetOverviewPerYear$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BudgetOverviewYearDto>>): Array<BudgetOverviewYearDto> => r.body)
    );
  }

}
