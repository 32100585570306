/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RiskAssessmentDto } from '../../models/risk-assessment-dto';

export interface GetAssessmentsForScopeOfCare$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * The scope of care id
 */
  scopeOfCareId: number;

/**
 * Flag indicating how to display inactive risk assessments. If set to true only active risk assessments are returned, if set to false only inactive risk assessments are returned, if not set all active and inactive assessments are returned.
 */
  active?: boolean;
}

export function getAssessmentsForScopeOfCare(http: HttpClient, rootUrl: string, params: GetAssessmentsForScopeOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RiskAssessmentDto>>> {
  const rb = new RequestBuilder(rootUrl, getAssessmentsForScopeOfCare.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('scopeOfCareId', params.scopeOfCareId, {});
    rb.query('active', params.active, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<RiskAssessmentDto>>;
    })
  );
}

getAssessmentsForScopeOfCare.PATH = '/customers/{customerId}/scopes-of-care/{scopeOfCareId}/risk-assessments';
