/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getVehicleTypes } from '../fn/vehicle-type-controller/get-vehicle-types';
import { GetVehicleTypes$Params } from '../fn/vehicle-type-controller/get-vehicle-types';
import { VehicleTypeDto } from '../models/vehicle-type-dto';

@Injectable({ providedIn: 'root' })
export class VehicleTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVehicleTypes()` */
  static readonly GetVehicleTypesPath = '/vehicle-types';

  /**
   * Get all vehicle types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes$Response(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VehicleTypeDto>>> {
    return getVehicleTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all vehicle types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVehicleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleTypes(params?: GetVehicleTypes$Params, context?: HttpContext): Observable<Array<VehicleTypeDto>> {
    return this.getVehicleTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VehicleTypeDto>>): Array<VehicleTypeDto> => r.body)
    );
  }

}
