/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOccupationalInsuranceAssociationCustomerInfo } from '../fn/occupational-insurance-association-customer-info-controller/create-occupational-insurance-association-customer-info';
import { CreateOccupationalInsuranceAssociationCustomerInfo$Params } from '../fn/occupational-insurance-association-customer-info-controller/create-occupational-insurance-association-customer-info';
import { deleteOccupationalInsuranceAssociationCustomerInfo } from '../fn/occupational-insurance-association-customer-info-controller/delete-occupational-insurance-association-customer-info';
import { DeleteOccupationalInsuranceAssociationCustomerInfo$Params } from '../fn/occupational-insurance-association-customer-info-controller/delete-occupational-insurance-association-customer-info';
import { getAllOccupationalInsuranceAssociations } from '../fn/occupational-insurance-association-customer-info-controller/get-all-occupational-insurance-associations';
import { GetAllOccupationalInsuranceAssociations$Params } from '../fn/occupational-insurance-association-customer-info-controller/get-all-occupational-insurance-associations';
import { getOccupationalInsuranceAssociationCustomerInfo } from '../fn/occupational-insurance-association-customer-info-controller/get-occupational-insurance-association-customer-info';
import { GetOccupationalInsuranceAssociationCustomerInfo$Params } from '../fn/occupational-insurance-association-customer-info-controller/get-occupational-insurance-association-customer-info';
import { OccupationalInsuranceAssociationCustomerInfoDto } from '../models/occupational-insurance-association-customer-info-dto';
import { OccupationalInsuranceAssociationDto } from '../models/occupational-insurance-association-dto';
import { updateOccupationalInsuranceAssociationCustomerInfo } from '../fn/occupational-insurance-association-customer-info-controller/update-occupational-insurance-association-customer-info';
import { UpdateOccupationalInsuranceAssociationCustomerInfo$Params } from '../fn/occupational-insurance-association-customer-info-controller/update-occupational-insurance-association-customer-info';


/**
 * Occupational Insurance Association of customers
 */
@Injectable({ providedIn: 'root' })
export class OccupationalInsuranceAssociationCustomerInfoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOccupationalInsuranceAssociationCustomerInfo()` */
  static readonly GetOccupationalInsuranceAssociationCustomerInfoPath = '/customers/{customerId}/occupational-insurance-association';

  /**
   * Get occupational insurance association info of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOccupationalInsuranceAssociationCustomerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOccupationalInsuranceAssociationCustomerInfo$Response(params: GetOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>> {
    return getOccupationalInsuranceAssociationCustomerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get occupational insurance association info of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOccupationalInsuranceAssociationCustomerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOccupationalInsuranceAssociationCustomerInfo(params: GetOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<OccupationalInsuranceAssociationCustomerInfoDto> {
    return this.getOccupationalInsuranceAssociationCustomerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>): OccupationalInsuranceAssociationCustomerInfoDto => r.body)
    );
  }

  /** Path part for operation `updateOccupationalInsuranceAssociationCustomerInfo()` */
  static readonly UpdateOccupationalInsuranceAssociationCustomerInfoPath = '/customers/{customerId}/occupational-insurance-association';

  /**
   * Update an occupational insurance association info of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOccupationalInsuranceAssociationCustomerInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOccupationalInsuranceAssociationCustomerInfo$Response(params: UpdateOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>> {
    return updateOccupationalInsuranceAssociationCustomerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an occupational insurance association info of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOccupationalInsuranceAssociationCustomerInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOccupationalInsuranceAssociationCustomerInfo(params: UpdateOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<OccupationalInsuranceAssociationCustomerInfoDto> {
    return this.updateOccupationalInsuranceAssociationCustomerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>): OccupationalInsuranceAssociationCustomerInfoDto => r.body)
    );
  }

  /** Path part for operation `createOccupationalInsuranceAssociationCustomerInfo()` */
  static readonly CreateOccupationalInsuranceAssociationCustomerInfoPath = '/customers/{customerId}/occupational-insurance-association';

  /**
   * Create an occupational insurance association info of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOccupationalInsuranceAssociationCustomerInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOccupationalInsuranceAssociationCustomerInfo$Response(params: CreateOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>> {
    return createOccupationalInsuranceAssociationCustomerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an occupational insurance association info of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOccupationalInsuranceAssociationCustomerInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOccupationalInsuranceAssociationCustomerInfo(params: CreateOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<OccupationalInsuranceAssociationCustomerInfoDto> {
    return this.createOccupationalInsuranceAssociationCustomerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<OccupationalInsuranceAssociationCustomerInfoDto>): OccupationalInsuranceAssociationCustomerInfoDto => r.body)
    );
  }

  /** Path part for operation `deleteOccupationalInsuranceAssociationCustomerInfo()` */
  static readonly DeleteOccupationalInsuranceAssociationCustomerInfoPath = '/customers/{customerId}/occupational-insurance-association';

  /**
   * Delete an occupational insurance association info of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOccupationalInsuranceAssociationCustomerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOccupationalInsuranceAssociationCustomerInfo$Response(params: DeleteOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOccupationalInsuranceAssociationCustomerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an occupational insurance association info of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOccupationalInsuranceAssociationCustomerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOccupationalInsuranceAssociationCustomerInfo(params: DeleteOccupationalInsuranceAssociationCustomerInfo$Params, context?: HttpContext): Observable<void> {
    return this.deleteOccupationalInsuranceAssociationCustomerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllOccupationalInsuranceAssociations()` */
  static readonly GetAllOccupationalInsuranceAssociationsPath = '/occupational-insurance-associations';

  /**
   * Get all occupational insurance associations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOccupationalInsuranceAssociations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccupationalInsuranceAssociations$Response(params?: GetAllOccupationalInsuranceAssociations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OccupationalInsuranceAssociationDto>>> {
    return getAllOccupationalInsuranceAssociations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all occupational insurance associations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllOccupationalInsuranceAssociations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccupationalInsuranceAssociations(params?: GetAllOccupationalInsuranceAssociations$Params, context?: HttpContext): Observable<Array<OccupationalInsuranceAssociationDto>> {
    return this.getAllOccupationalInsuranceAssociations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OccupationalInsuranceAssociationDto>>): Array<OccupationalInsuranceAssociationDto> => r.body)
    );
  }

}
