/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceDto } from '../models/billable-service-dto';
import { createOverdraftProposal } from '../fn/sht-training-controller/create-overdraft-proposal';
import { CreateOverdraftProposal$Params } from '../fn/sht-training-controller/create-overdraft-proposal';
import { createShtBillableServices } from '../fn/sht-training-controller/create-sht-billable-services';
import { CreateShtBillableServices$Params } from '../fn/sht-training-controller/create-sht-billable-services';
import { createShtTraining } from '../fn/sht-training-controller/create-sht-training';
import { CreateShtTraining$Params } from '../fn/sht-training-controller/create-sht-training';
import { deleteShtTraining } from '../fn/sht-training-controller/delete-sht-training';
import { DeleteShtTraining$Params } from '../fn/sht-training-controller/delete-sht-training';
import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { getAllModeratorsOfShtTraining } from '../fn/sht-training-controller/get-all-moderators-of-sht-training';
import { GetAllModeratorsOfShtTraining$Params } from '../fn/sht-training-controller/get-all-moderators-of-sht-training';
import { getAllPlacesOfShtTraining } from '../fn/sht-training-controller/get-all-places-of-sht-training';
import { GetAllPlacesOfShtTraining$Params } from '../fn/sht-training-controller/get-all-places-of-sht-training';
import { getAllShtTrainings } from '../fn/sht-training-controller/get-all-sht-trainings';
import { GetAllShtTrainings$Params } from '../fn/sht-training-controller/get-all-sht-trainings';
import { getAllTypesOfShtTraining } from '../fn/sht-training-controller/get-all-types-of-sht-training';
import { GetAllTypesOfShtTraining$Params } from '../fn/sht-training-controller/get-all-types-of-sht-training';
import { getAttendanceCertificateForParticipant } from '../fn/sht-training-controller/get-attendance-certificate-for-participant';
import { GetAttendanceCertificateForParticipant$Params } from '../fn/sht-training-controller/get-attendance-certificate-for-participant';
import { getAttendanceCertificates } from '../fn/sht-training-controller/get-attendance-certificates';
import { GetAttendanceCertificates$Params } from '../fn/sht-training-controller/get-attendance-certificates';
import { getOneShtTrainingById } from '../fn/sht-training-controller/get-one-sht-training-by-id';
import { GetOneShtTrainingById$Params } from '../fn/sht-training-controller/get-one-sht-training-by-id';
import { getRegistrationConfirmationForParticipant } from '../fn/sht-training-controller/get-registration-confirmation-for-participant';
import { GetRegistrationConfirmationForParticipant$Params } from '../fn/sht-training-controller/get-registration-confirmation-for-participant';
import { getRegistrationConfirmations } from '../fn/sht-training-controller/get-registration-confirmations';
import { GetRegistrationConfirmations$Params } from '../fn/sht-training-controller/get-registration-confirmations';
import { getShtTrainingParticipantsListPdf } from '../fn/sht-training-controller/get-sht-training-participants-list-pdf';
import { GetShtTrainingParticipantsListPdf$Params } from '../fn/sht-training-controller/get-sht-training-participants-list-pdf';
import { ResponseShtTrainingDto } from '../models/response-sht-training-dto';
import { ShtTrainingDto } from '../models/sht-training-dto';
import { ShtTrainingModeratorDto } from '../models/sht-training-moderator-dto';
import { ShtTrainingPlaceDto } from '../models/sht-training-place-dto';
import { ShtTrainingTypeDto } from '../models/sht-training-type-dto';
import { updateShtTraining } from '../fn/sht-training-controller/update-sht-training';
import { UpdateShtTraining$Params } from '../fn/sht-training-controller/update-sht-training';

@Injectable({ providedIn: 'root' })
export class ShtTrainingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOneShtTrainingById()` */
  static readonly GetOneShtTrainingByIdPath = '/sht-training/{id}';

  /**
   * get a sht trainings by his id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneShtTrainingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneShtTrainingById$Response(params: GetOneShtTrainingById$Params, context?: HttpContext): Observable<StrictHttpResponse<ShtTrainingDto>> {
    return getOneShtTrainingById(this.http, this.rootUrl, params, context);
  }

  /**
   * get a sht trainings by his id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneShtTrainingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneShtTrainingById(params: GetOneShtTrainingById$Params, context?: HttpContext): Observable<ShtTrainingDto> {
    return this.getOneShtTrainingById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShtTrainingDto>): ShtTrainingDto => r.body)
    );
  }

  /** Path part for operation `updateShtTraining()` */
  static readonly UpdateShtTrainingPath = '/sht-training/{id}';

  /**
   * update an existing sht training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateShtTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShtTraining$Response(params: UpdateShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<ShtTrainingDto>> {
    return updateShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * update an existing sht training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateShtTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShtTraining(params: UpdateShtTraining$Params, context?: HttpContext): Observable<ShtTrainingDto> {
    return this.updateShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShtTrainingDto>): ShtTrainingDto => r.body)
    );
  }

  /** Path part for operation `deleteShtTraining()` */
  static readonly DeleteShtTrainingPath = '/sht-training/{id}';

  /**
   * delete an existing sht training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteShtTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShtTraining$Response(params: DeleteShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * delete an existing sht training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteShtTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShtTraining(params: DeleteShtTraining$Params, context?: HttpContext): Observable<void> {
    return this.deleteShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllShtTrainings()` */
  static readonly GetAllShtTrainingsPath = '/sht-training';

  /**
   * Get all sht trainings which have not yet been billed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllShtTrainings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllShtTrainings$Response(params: GetAllShtTrainings$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseShtTrainingDto>> {
    return getAllShtTrainings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all sht trainings which have not yet been billed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllShtTrainings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllShtTrainings(params: GetAllShtTrainings$Params, context?: HttpContext): Observable<ResponseShtTrainingDto> {
    return this.getAllShtTrainings$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseShtTrainingDto>): ResponseShtTrainingDto => r.body)
    );
  }

  /** Path part for operation `createShtTraining()` */
  static readonly CreateShtTrainingPath = '/sht-training';

  /**
   * create a new sht training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createShtTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createShtTraining$Response(params: CreateShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<ShtTrainingDto>> {
    return createShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new sht training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createShtTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createShtTraining(params: CreateShtTraining$Params, context?: HttpContext): Observable<ShtTrainingDto> {
    return this.createShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShtTrainingDto>): ShtTrainingDto => r.body)
    );
  }

  /** Path part for operation `createOverdraftProposal()` */
  static readonly CreateOverdraftProposalPath = '/sht-training/{id}/overdraft-proposal';

  /**
   * create proposal to overdraft time budget of this training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOverdraftProposal()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOverdraftProposal$Response(params: CreateOverdraftProposal$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceDto>>> {
    return createOverdraftProposal(this.http, this.rootUrl, params, context);
  }

  /**
   * create proposal to overdraft time budget of this training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOverdraftProposal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOverdraftProposal(params: CreateOverdraftProposal$Params, context?: HttpContext): Observable<Array<BillableServiceDto>> {
    return this.createOverdraftProposal$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceDto>>): Array<BillableServiceDto> => r.body)
    );
  }

  /** Path part for operation `createShtBillableServices()` */
  static readonly CreateShtBillableServicesPath = '/sht-training/{id}/billable-services';

  /**
   * create all billable services for this training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createShtBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  createShtBillableServices$Response(params: CreateShtBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceDto>>> {
    return createShtBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * create all billable services for this training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createShtBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createShtBillableServices(params: CreateShtBillableServices$Params, context?: HttpContext): Observable<Array<BillableServiceDto>> {
    return this.createShtBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceDto>>): Array<BillableServiceDto> => r.body)
    );
  }

  /** Path part for operation `getRegistrationConfirmations()` */
  static readonly GetRegistrationConfirmationsPath = '/sht-training/{id}/registration-confirmations';

  /**
   * get pdf doc with all participants registration confirmation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegistrationConfirmations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationConfirmations$Response(params: GetRegistrationConfirmations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentMetadataDto>>> {
    return getRegistrationConfirmations(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with all participants registration confirmation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegistrationConfirmations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationConfirmations(params: GetRegistrationConfirmations$Params, context?: HttpContext): Observable<Array<DocumentMetadataDto>> {
    return this.getRegistrationConfirmations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentMetadataDto>>): Array<DocumentMetadataDto> => r.body)
    );
  }

  /** Path part for operation `getRegistrationConfirmationForParticipant()` */
  static readonly GetRegistrationConfirmationForParticipantPath = '/sht-training/{id}/registration-confirmation/{participantId}';

  /**
   * get pdf doc with one participant registration confirmation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegistrationConfirmationForParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationConfirmationForParticipant$Response(params: GetRegistrationConfirmationForParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return getRegistrationConfirmationForParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with one participant registration confirmation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegistrationConfirmationForParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationConfirmationForParticipant(params: GetRegistrationConfirmationForParticipant$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.getRegistrationConfirmationForParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getShtTrainingParticipantsListPdf()` */
  static readonly GetShtTrainingParticipantsListPdfPath = '/sht-training/{id}/participants-list';

  /**
   * get pdf doc with all participants listed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShtTrainingParticipantsListPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShtTrainingParticipantsListPdf$Response(params: GetShtTrainingParticipantsListPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getShtTrainingParticipantsListPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with all participants listed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShtTrainingParticipantsListPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShtTrainingParticipantsListPdf(params: GetShtTrainingParticipantsListPdf$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getShtTrainingParticipantsListPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getAttendanceCertificates()` */
  static readonly GetAttendanceCertificatesPath = '/sht-training/{id}/attendance-certificates';

  /**
   * get pdf doc with all participants attendance certificates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttendanceCertificates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttendanceCertificates$Response(params: GetAttendanceCertificates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentMetadataDto>>> {
    return getAttendanceCertificates(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with all participants attendance certificates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttendanceCertificates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttendanceCertificates(params: GetAttendanceCertificates$Params, context?: HttpContext): Observable<Array<DocumentMetadataDto>> {
    return this.getAttendanceCertificates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentMetadataDto>>): Array<DocumentMetadataDto> => r.body)
    );
  }

  /** Path part for operation `getAttendanceCertificateForParticipant()` */
  static readonly GetAttendanceCertificateForParticipantPath = '/sht-training/{id}/attendance-certificate/{participantId}';

  /**
   * get pdf doc with one participant attendance certificate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttendanceCertificateForParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttendanceCertificateForParticipant$Response(params: GetAttendanceCertificateForParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return getAttendanceCertificateForParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with one participant attendance certificate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttendanceCertificateForParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttendanceCertificateForParticipant(params: GetAttendanceCertificateForParticipant$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.getAttendanceCertificateForParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getAllTypesOfShtTraining()` */
  static readonly GetAllTypesOfShtTrainingPath = '/sht-training/types';

  /**
   * get all types of SHT trainings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTypesOfShtTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypesOfShtTraining$Response(params?: GetAllTypesOfShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShtTrainingTypeDto>>> {
    return getAllTypesOfShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * get all types of SHT trainings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTypesOfShtTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTypesOfShtTraining(params?: GetAllTypesOfShtTraining$Params, context?: HttpContext): Observable<Array<ShtTrainingTypeDto>> {
    return this.getAllTypesOfShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShtTrainingTypeDto>>): Array<ShtTrainingTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllPlacesOfShtTraining()` */
  static readonly GetAllPlacesOfShtTrainingPath = '/sht-training/places';

  /**
   * get all places of SHT trainings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPlacesOfShtTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlacesOfShtTraining$Response(params?: GetAllPlacesOfShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShtTrainingPlaceDto>>> {
    return getAllPlacesOfShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * get all places of SHT trainings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPlacesOfShtTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPlacesOfShtTraining(params?: GetAllPlacesOfShtTraining$Params, context?: HttpContext): Observable<Array<ShtTrainingPlaceDto>> {
    return this.getAllPlacesOfShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShtTrainingPlaceDto>>): Array<ShtTrainingPlaceDto> => r.body)
    );
  }

  /** Path part for operation `getAllModeratorsOfShtTraining()` */
  static readonly GetAllModeratorsOfShtTrainingPath = '/sht-training/moderators';

  /**
   * get all moderators of SHT trainings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllModeratorsOfShtTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllModeratorsOfShtTraining$Response(params?: GetAllModeratorsOfShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShtTrainingModeratorDto>>> {
    return getAllModeratorsOfShtTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * get all moderators of SHT trainings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllModeratorsOfShtTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllModeratorsOfShtTraining(params?: GetAllModeratorsOfShtTraining$Params, context?: HttpContext): Observable<Array<ShtTrainingModeratorDto>> {
    return this.getAllModeratorsOfShtTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShtTrainingModeratorDto>>): Array<ShtTrainingModeratorDto> => r.body)
    );
  }

}
