/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CentStream } from '../models/cent-stream';
import { createDocument } from '../fn/risk-assessment-controller/create-document';
import { CreateDocument$Params } from '../fn/risk-assessment-controller/create-document';
import { createHazardsExcelDocument } from '../fn/risk-assessment-controller/create-hazards-excel-document';
import { CreateHazardsExcelDocument$Params } from '../fn/risk-assessment-controller/create-hazards-excel-document';
import { createRiskAssessment } from '../fn/risk-assessment-controller/create-risk-assessment';
import { CreateRiskAssessment$Params } from '../fn/risk-assessment-controller/create-risk-assessment';
import { createRiskAssessmentsByTemplates } from '../fn/risk-assessment-controller/create-risk-assessments-by-templates';
import { CreateRiskAssessmentsByTemplates$Params } from '../fn/risk-assessment-controller/create-risk-assessments-by-templates';
import { createTotalDocument } from '../fn/risk-assessment-controller/create-total-document';
import { CreateTotalDocument$Params } from '../fn/risk-assessment-controller/create-total-document';
import { deleteRiskAssessment } from '../fn/risk-assessment-controller/delete-risk-assessment';
import { DeleteRiskAssessment$Params } from '../fn/risk-assessment-controller/delete-risk-assessment';
import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { getAssessmentsForScopeOfCare } from '../fn/risk-assessment-controller/get-assessments-for-scope-of-care';
import { GetAssessmentsForScopeOfCare$Params } from '../fn/risk-assessment-controller/get-assessments-for-scope-of-care';
import { getCategorizedHazardFactors } from '../fn/risk-assessment-controller/get-categorized-hazard-factors';
import { GetCategorizedHazardFactors$Params } from '../fn/risk-assessment-controller/get-categorized-hazard-factors';
import { getDocumentPreview } from '../fn/risk-assessment-controller/get-document-preview';
import { GetDocumentPreview$Params } from '../fn/risk-assessment-controller/get-document-preview';
import { getLatestRiskAssessments } from '../fn/risk-assessment-controller/get-latest-risk-assessments';
import { GetLatestRiskAssessments$Params } from '../fn/risk-assessment-controller/get-latest-risk-assessments';
import { HazardCategoryDto } from '../models/hazard-category-dto';
import { patchActive } from '../fn/risk-assessment-controller/patch-active';
import { PatchActive$Params } from '../fn/risk-assessment-controller/patch-active';
import { patchMeasuresResponsiblePerson } from '../fn/risk-assessment-controller/patch-measures-responsible-person';
import { PatchMeasuresResponsiblePerson$Params } from '../fn/risk-assessment-controller/patch-measures-responsible-person';
import { RiskAssessmentDto } from '../models/risk-assessment-dto';
import { updateRiskAssessment } from '../fn/risk-assessment-controller/update-risk-assessment';
import { UpdateRiskAssessment$Params } from '../fn/risk-assessment-controller/update-risk-assessment';
import { uploadChecklist } from '../fn/risk-assessment-controller/upload-checklist';
import { UploadChecklist$Params } from '../fn/risk-assessment-controller/upload-checklist';


/**
 * Manages the top level for risk assessments (summarized below a scope of care)
 */
@Injectable({ providedIn: 'root' })
export class RiskAssessmentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateRiskAssessment()` */
  static readonly UpdateRiskAssessmentPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}';

  /**
   * Update  assessments for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRiskAssessment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRiskAssessment$Response(params: UpdateRiskAssessment$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
    return updateRiskAssessment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update  assessments for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRiskAssessment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRiskAssessment(params: UpdateRiskAssessment$Params, context?: HttpContext): Observable<RiskAssessmentDto> {
    return this.updateRiskAssessment$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskAssessmentDto>): RiskAssessmentDto => r.body)
    );
  }

  /** Path part for operation `patchActive()` */
  static readonly PatchActivePath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}';

  /**
   * patch the active flag of a risk assessment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchActive$Response(params: PatchActive$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
    return patchActive(this.http, this.rootUrl, params, context);
  }

  /**
   * patch the active flag of a risk assessment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchActive(params: PatchActive$Params, context?: HttpContext): Observable<RiskAssessmentDto> {
    return this.patchActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskAssessmentDto>): RiskAssessmentDto => r.body)
    );
  }

  /** Path part for operation `createRiskAssessmentsByTemplates()` */
  static readonly CreateRiskAssessmentsByTemplatesPath = '/customers/{customerId}/scopes-of-care/{scopeOfCareId}/risk-assessments-from-template';

  /**
   * Create risk assessments on the basis of standard risk assessment templates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRiskAssessmentsByTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRiskAssessmentsByTemplates$Response(params: CreateRiskAssessmentsByTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RiskAssessmentDto>>> {
    return createRiskAssessmentsByTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Create risk assessments on the basis of standard risk assessment templates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRiskAssessmentsByTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRiskAssessmentsByTemplates(params: CreateRiskAssessmentsByTemplates$Params, context?: HttpContext): Observable<Array<RiskAssessmentDto>> {
    return this.createRiskAssessmentsByTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RiskAssessmentDto>>): Array<RiskAssessmentDto> => r.body)
    );
  }

  /** Path part for operation `createRiskAssessment()` */
  static readonly CreateRiskAssessmentPath = '/customers/{customerId}/scope-of-care/{scopeOfCareId}/risk-assessments';

  /**
   * Create an individual risk assessment without a template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRiskAssessment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRiskAssessment$Response(params: CreateRiskAssessment$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
    return createRiskAssessment(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an individual risk assessment without a template
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRiskAssessment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRiskAssessment(params: CreateRiskAssessment$Params, context?: HttpContext): Observable<RiskAssessmentDto> {
    return this.createRiskAssessment$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskAssessmentDto>): RiskAssessmentDto => r.body)
    );
  }

  /** Path part for operation `createDocument()` */
  static readonly CreateDocumentPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/document-save';

  /**
   * Returns the metadata of document for the given risk assessment, the document is persisted in the customers document store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDocument$Response(params: CreateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return createDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the metadata of document for the given risk assessment, the document is persisted in the customers document store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDocument(params: CreateDocument$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.createDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `uploadChecklist()` */
  static readonly UploadChecklistPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/checklist';

  /**
   * Upload a checklist document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadChecklist()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklist$Response(params: UploadChecklist$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
    return uploadChecklist(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a checklist document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadChecklist$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklist(params: UploadChecklist$Params, context?: HttpContext): Observable<RiskAssessmentDto> {
    return this.uploadChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskAssessmentDto>): RiskAssessmentDto => r.body)
    );
  }

  /** Path part for operation `patchMeasuresResponsiblePerson()` */
  static readonly PatchMeasuresResponsiblePersonPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/responsible-person';

  /**
   * patch the name of the responsible person in each and every measure of this
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMeasuresResponsiblePerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchMeasuresResponsiblePerson$Response(params: PatchMeasuresResponsiblePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
    return patchMeasuresResponsiblePerson(this.http, this.rootUrl, params, context);
  }

  /**
   * patch the name of the responsible person in each and every measure of this
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMeasuresResponsiblePerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchMeasuresResponsiblePerson(params: PatchMeasuresResponsiblePerson$Params, context?: HttpContext): Observable<RiskAssessmentDto> {
    return this.patchMeasuresResponsiblePerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskAssessmentDto>): RiskAssessmentDto => r.body)
    );
  }

  /** Path part for operation `getAssessmentsForScopeOfCare()` */
  static readonly GetAssessmentsForScopeOfCarePath = '/customers/{customerId}/scopes-of-care/{scopeOfCareId}/risk-assessments';

  /**
   * Get all assessments for a given scope of care and customerId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssessmentsForScopeOfCare()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssessmentsForScopeOfCare$Response(params: GetAssessmentsForScopeOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RiskAssessmentDto>>> {
    return getAssessmentsForScopeOfCare(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all assessments for a given scope of care and customerId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAssessmentsForScopeOfCare$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssessmentsForScopeOfCare(params: GetAssessmentsForScopeOfCare$Params, context?: HttpContext): Observable<Array<RiskAssessmentDto>> {
    return this.getAssessmentsForScopeOfCare$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RiskAssessmentDto>>): Array<RiskAssessmentDto> => r.body)
    );
  }

  /** Path part for operation `createHazardsExcelDocument()` */
  static readonly CreateHazardsExcelDocumentPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/hazards-excel-document';

  /**
   * Returns metadata of excel document for given risk assessment, the document is not persisted in customers document store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createHazardsExcelDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  createHazardsExcelDocument$Response(params: CreateHazardsExcelDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return createHazardsExcelDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns metadata of excel document for given risk assessment, the document is not persisted in customers document store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createHazardsExcelDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createHazardsExcelDocument(params: CreateHazardsExcelDocument$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.createHazardsExcelDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getDocumentPreview()` */
  static readonly GetDocumentPreviewPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/document-preview';

  /**
   * Returns the metadata of a preview document for the given risk assessment, the document is not persisted in the customers document store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentPreview$Response(params: GetDocumentPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getDocumentPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the metadata of a preview document for the given risk assessment, the document is not persisted in the customers document store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentPreview(params: GetDocumentPreview$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getDocumentPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getCategorizedHazardFactors()` */
  static readonly GetCategorizedHazardFactorsPath = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/categorized-hazard-factors';

  /**
   * Return the hazard factors in a each category (this representation is also known as risk assessment matrix)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategorizedHazardFactors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategorizedHazardFactors$Response(params: GetCategorizedHazardFactors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HazardCategoryDto>>> {
    return getCategorizedHazardFactors(this.http, this.rootUrl, params, context);
  }

  /**
   * Return the hazard factors in a each category (this representation is also known as risk assessment matrix)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategorizedHazardFactors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategorizedHazardFactors(params: GetCategorizedHazardFactors$Params, context?: HttpContext): Observable<Array<HazardCategoryDto>> {
    return this.getCategorizedHazardFactors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HazardCategoryDto>>): Array<HazardCategoryDto> => r.body)
    );
  }

  /** Path part for operation `getLatestRiskAssessments()` */
  static readonly GetLatestRiskAssessmentsPath = '/customers/{customerId}/risk-assessment/latest';

  /**
   * Return the latest of the customers risk assessment date value properties or 404 NOT FOUND if none are found.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestRiskAssessments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestRiskAssessments$Response(params: GetLatestRiskAssessments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RiskAssessmentDto>>> {
    return getLatestRiskAssessments(this.http, this.rootUrl, params, context);
  }

  /**
   * Return the latest of the customers risk assessment date value properties or 404 NOT FOUND if none are found.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestRiskAssessments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestRiskAssessments(params: GetLatestRiskAssessments$Params, context?: HttpContext): Observable<Array<RiskAssessmentDto>> {
    return this.getLatestRiskAssessments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RiskAssessmentDto>>): Array<RiskAssessmentDto> => r.body)
    );
  }

  /** Path part for operation `createTotalDocument()` */
  static readonly CreateTotalDocumentPath = '/customers/{customerId}/risk-assessment/all/document-save';

  /**
   * Returns the metadata of a document including all risk assessment, the document is persisted in the customers document store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTotalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTotalDocument$Response(params: CreateTotalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<CentStream>> {
    return createTotalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the metadata of a document including all risk assessment, the document is persisted in the customers document store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTotalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTotalDocument(params: CreateTotalDocument$Params, context?: HttpContext): Observable<CentStream> {
    return this.createTotalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<CentStream>): CentStream => r.body)
    );
  }

  /** Path part for operation `deleteRiskAssessment()` */
  static readonly DeleteRiskAssessmentPath = '/customers/{customerId}/scope-of-care/{scopeOfCareId}/risk-assessments/{riskAssessmentId}';

  /**
   * Delete a risk assessment, which is in PROCESSABLE state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRiskAssessment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiskAssessment$Response(params: DeleteRiskAssessment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRiskAssessment(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a risk assessment, which is in PROCESSABLE state
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRiskAssessment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiskAssessment(params: DeleteRiskAssessment$Params, context?: HttpContext): Observable<void> {
    return this.deleteRiskAssessment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
