/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { applyOverdraftForSvg } from '../fn/working-time-controller/apply-overdraft-for-svg';
import { ApplyOverdraftForSvg$Params } from '../fn/working-time-controller/apply-overdraft-for-svg';
import { createWorkingTime } from '../fn/working-time-controller/create-working-time';
import { CreateWorkingTime$Params } from '../fn/working-time-controller/create-working-time';
import { deleteWorkingTime } from '../fn/working-time-controller/delete-working-time';
import { DeleteWorkingTime$Params } from '../fn/working-time-controller/delete-working-time';
import { getWorkingTimes } from '../fn/working-time-controller/get-working-times';
import { GetWorkingTimes$Params } from '../fn/working-time-controller/get-working-times';
import { getWorkingTimeTypes } from '../fn/working-time-controller/get-working-time-types';
import { GetWorkingTimeTypes$Params } from '../fn/working-time-controller/get-working-time-types';
import { updateWorkingTime } from '../fn/working-time-controller/update-working-time';
import { UpdateWorkingTime$Params } from '../fn/working-time-controller/update-working-time';
import { WorkingTimeDto } from '../models/working-time-dto';
import { WorkingTimeTypeDto } from '../models/working-time-type-dto';


/**
 * manage the working times of a contract
 */
@Injectable({ providedIn: 'root' })
export class WorkingTimeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateWorkingTime()` */
  static readonly UpdateWorkingTimePath = '/customers/{customerId}/contracts/{contractId}/working-times/{workingTimeId}';

  /**
   * update a workingTime.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkingTime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingTime$Response(params: UpdateWorkingTime$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkingTimeDto>> {
    return updateWorkingTime(this.http, this.rootUrl, params, context);
  }

  /**
   * update a workingTime.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkingTime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingTime(params: UpdateWorkingTime$Params, context?: HttpContext): Observable<WorkingTimeDto> {
    return this.updateWorkingTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkingTimeDto>): WorkingTimeDto => r.body)
    );
  }

  /** Path part for operation `applyOverdraftForSvg()` */
  static readonly ApplyOverdraftForSvgPath = '/working-times/apply-overdrafts/';

  /**
   * Applies default overdrafts to working times for a given SVG ID. Returns the number of affected rows.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyOverdraftForSvg()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyOverdraftForSvg$Response(params: ApplyOverdraftForSvg$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return applyOverdraftForSvg(this.http, this.rootUrl, params, context);
  }

  /**
   * Applies default overdrafts to working times for a given SVG ID. Returns the number of affected rows.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applyOverdraftForSvg$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyOverdraftForSvg(params: ApplyOverdraftForSvg$Params, context?: HttpContext): Observable<number> {
    return this.applyOverdraftForSvg$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getWorkingTimes()` */
  static readonly GetWorkingTimesPath = '/customers/{customerId}/contracts/{contractId}/working-times';

  /**
   * Get all working times of a customers contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkingTimes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkingTimes$Response(params: GetWorkingTimes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkingTimeDto>>> {
    return getWorkingTimes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all working times of a customers contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkingTimes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkingTimes(params: GetWorkingTimes$Params, context?: HttpContext): Observable<Array<WorkingTimeDto>> {
    return this.getWorkingTimes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkingTimeDto>>): Array<WorkingTimeDto> => r.body)
    );
  }

  /** Path part for operation `createWorkingTime()` */
  static readonly CreateWorkingTimePath = '/customers/{customerId}/contracts/{contractId}/working-times';

  /**
   * Create a working time of a contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWorkingTime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorkingTime$Response(params: CreateWorkingTime$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkingTimeDto>> {
    return createWorkingTime(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a working time of a contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWorkingTime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorkingTime(params: CreateWorkingTime$Params, context?: HttpContext): Observable<WorkingTimeDto> {
    return this.createWorkingTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkingTimeDto>): WorkingTimeDto => r.body)
    );
  }

  /** Path part for operation `getWorkingTimeTypes()` */
  static readonly GetWorkingTimeTypesPath = '/working-times/types';

  /**
   * Get working time types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkingTimeTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkingTimeTypes$Response(params?: GetWorkingTimeTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkingTimeTypeDto>>> {
    return getWorkingTimeTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get working time types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkingTimeTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkingTimeTypes(params?: GetWorkingTimeTypes$Params, context?: HttpContext): Observable<Array<WorkingTimeTypeDto>> {
    return this.getWorkingTimeTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkingTimeTypeDto>>): Array<WorkingTimeTypeDto> => r.body)
    );
  }

  /** Path part for operation `deleteWorkingTime()` */
  static readonly DeleteWorkingTimePath = '/customers/{customerId}/contracts/working-times/{workingTimeId}';

  /**
   * delete a working time.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWorkingTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWorkingTime$Response(params: DeleteWorkingTime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteWorkingTime(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a working time.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWorkingTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWorkingTime(params: DeleteWorkingTime$Params, context?: HttpContext): Observable<void> {
    return this.deleteWorkingTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
