/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPriceCondition } from '../fn/price-condition-controller/create-price-condition';
import { CreatePriceCondition$Params } from '../fn/price-condition-controller/create-price-condition';
import { deletePriceCondition } from '../fn/price-condition-controller/delete-price-condition';
import { DeletePriceCondition$Params } from '../fn/price-condition-controller/delete-price-condition';
import { getPriceConditions } from '../fn/price-condition-controller/get-price-conditions';
import { GetPriceConditions$Params } from '../fn/price-condition-controller/get-price-conditions';
import { getPriceTypes } from '../fn/price-condition-controller/get-price-types';
import { GetPriceTypes$Params } from '../fn/price-condition-controller/get-price-types';
import { PriceConditionDto } from '../models/price-condition-dto';
import { PriceTypeDto } from '../models/price-type-dto';
import { updatePriceCondition } from '../fn/price-condition-controller/update-price-condition';
import { UpdatePriceCondition$Params } from '../fn/price-condition-controller/update-price-condition';


/**
 * manage the price conditions
 */
@Injectable({ providedIn: 'root' })
export class PriceConditionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updatePriceCondition()` */
  static readonly UpdatePriceConditionPath = '/customers/{customerId}/contracts/{contractId}/price-conditions/{priceConditionId}';

  /**
   * update a priceCondition.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePriceCondition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePriceCondition$Response(params: UpdatePriceCondition$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceConditionDto>> {
    return updatePriceCondition(this.http, this.rootUrl, params, context);
  }

  /**
   * update a priceCondition.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePriceCondition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePriceCondition(params: UpdatePriceCondition$Params, context?: HttpContext): Observable<PriceConditionDto> {
    return this.updatePriceCondition$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceConditionDto>): PriceConditionDto => r.body)
    );
  }

  /** Path part for operation `getPriceConditions()` */
  static readonly GetPriceConditionsPath = '/customers/{customerId}/contracts/{contractId}/price-conditions';

  /**
   * Get all price conditions of a customers contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPriceConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceConditions$Response(params: GetPriceConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PriceConditionDto>>> {
    return getPriceConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all price conditions of a customers contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPriceConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceConditions(params: GetPriceConditions$Params, context?: HttpContext): Observable<Array<PriceConditionDto>> {
    return this.getPriceConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PriceConditionDto>>): Array<PriceConditionDto> => r.body)
    );
  }

  /** Path part for operation `createPriceCondition()` */
  static readonly CreatePriceConditionPath = '/customers/{customerId}/contracts/{contractId}/price-conditions';

  /**
   * Create a price condition.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPriceCondition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceCondition$Response(params: CreatePriceCondition$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceConditionDto>> {
    return createPriceCondition(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a price condition.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPriceCondition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceCondition(params: CreatePriceCondition$Params, context?: HttpContext): Observable<PriceConditionDto> {
    return this.createPriceCondition$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceConditionDto>): PriceConditionDto => r.body)
    );
  }

  /** Path part for operation `getPriceTypes()` */
  static readonly GetPriceTypesPath = '/price-conditions/types';

  /**
   * Get price condition types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPriceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceTypes$Response(params: GetPriceTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PriceTypeDto>>> {
    return getPriceTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get price condition types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPriceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceTypes(params: GetPriceTypes$Params, context?: HttpContext): Observable<Array<PriceTypeDto>> {
    return this.getPriceTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PriceTypeDto>>): Array<PriceTypeDto> => r.body)
    );
  }

  /** Path part for operation `deletePriceCondition()` */
  static readonly DeletePriceConditionPath = '/customers/{customerId}/price-conditions/{priceConditionId}';

  /**
   * delete a price condition.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePriceCondition()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePriceCondition$Response(params: DeletePriceCondition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePriceCondition(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a price condition.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePriceCondition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePriceCondition(params: DeletePriceCondition$Params, context?: HttpContext): Observable<void> {
    return this.deletePriceCondition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
