/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceDto } from '../models/billable-service-dto';
import { createBillableServices } from '../fn/standard-training-controller/create-billable-services';
import { CreateBillableServices$Params } from '../fn/standard-training-controller/create-billable-services';
import { createStandardTraining } from '../fn/standard-training-controller/create-standard-training';
import { CreateStandardTraining$Params } from '../fn/standard-training-controller/create-standard-training';
import { createStandardTrainingForCustomer } from '../fn/standard-training-controller/create-standard-training-for-customer';
import { CreateStandardTrainingForCustomer$Params } from '../fn/standard-training-controller/create-standard-training-for-customer';
import { deleteStandardTraining } from '../fn/standard-training-controller/delete-standard-training';
import { DeleteStandardTraining$Params } from '../fn/standard-training-controller/delete-standard-training';
import { getAllStandardTrainings } from '../fn/standard-training-controller/get-all-standard-trainings';
import { GetAllStandardTrainings$Params } from '../fn/standard-training-controller/get-all-standard-trainings';
import { getOneStandardTrainingById } from '../fn/standard-training-controller/get-one-standard-training-by-id';
import { GetOneStandardTrainingById$Params } from '../fn/standard-training-controller/get-one-standard-training-by-id';
import { ResponseStandardTrainingDto } from '../models/response-standard-training-dto';
import { StandardTrainingDto } from '../models/standard-training-dto';
import { updateStandardTraining } from '../fn/standard-training-controller/update-standard-training';
import { UpdateStandardTraining$Params } from '../fn/standard-training-controller/update-standard-training';

@Injectable({ providedIn: 'root' })
export class StandardTrainingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOneStandardTrainingById()` */
  static readonly GetOneStandardTrainingByIdPath = '/standard-training/{id}';

  /**
   * get a standard trainings by his id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneStandardTrainingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStandardTrainingById$Response(params: GetOneStandardTrainingById$Params, context?: HttpContext): Observable<StrictHttpResponse<StandardTrainingDto>> {
    return getOneStandardTrainingById(this.http, this.rootUrl, params, context);
  }

  /**
   * get a standard trainings by his id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneStandardTrainingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStandardTrainingById(params: GetOneStandardTrainingById$Params, context?: HttpContext): Observable<StandardTrainingDto> {
    return this.getOneStandardTrainingById$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandardTrainingDto>): StandardTrainingDto => r.body)
    );
  }

  /** Path part for operation `updateStandardTraining()` */
  static readonly UpdateStandardTrainingPath = '/standard-training/{id}';

  /**
   * update an existing standard training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStandardTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStandardTraining$Response(params: UpdateStandardTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<StandardTrainingDto>> {
    return updateStandardTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * update an existing standard training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStandardTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStandardTraining(params: UpdateStandardTraining$Params, context?: HttpContext): Observable<StandardTrainingDto> {
    return this.updateStandardTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandardTrainingDto>): StandardTrainingDto => r.body)
    );
  }

  /** Path part for operation `deleteStandardTraining()` */
  static readonly DeleteStandardTrainingPath = '/standard-training/{id}';

  /**
   * delete an existing standard training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteStandardTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStandardTraining$Response(params: DeleteStandardTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteStandardTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * delete an existing standard training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteStandardTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteStandardTraining(params: DeleteStandardTraining$Params, context?: HttpContext): Observable<void> {
    return this.deleteStandardTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllStandardTrainings()` */
  static readonly GetAllStandardTrainingsPath = '/standard-training';

  /**
   * Get all standard trainings which have not yet been billed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStandardTrainings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStandardTrainings$Response(params: GetAllStandardTrainings$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseStandardTrainingDto>> {
    return getAllStandardTrainings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all standard trainings which have not yet been billed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStandardTrainings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStandardTrainings(params: GetAllStandardTrainings$Params, context?: HttpContext): Observable<ResponseStandardTrainingDto> {
    return this.getAllStandardTrainings$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseStandardTrainingDto>): ResponseStandardTrainingDto => r.body)
    );
  }

  /** Path part for operation `createStandardTraining()` */
  static readonly CreateStandardTrainingPath = '/standard-training';

  /**
   * create a new standard training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStandardTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStandardTraining$Response(params: CreateStandardTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<StandardTrainingDto>> {
    return createStandardTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new standard training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createStandardTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStandardTraining(params: CreateStandardTraining$Params, context?: HttpContext): Observable<StandardTrainingDto> {
    return this.createStandardTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandardTrainingDto>): StandardTrainingDto => r.body)
    );
  }

  /** Path part for operation `createBillableServices()` */
  static readonly CreateBillableServicesPath = '/standard-training/{id}/billable-services';

  /**
   * create all billable services for this training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBillableServices$Response(params: CreateBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceDto>>> {
    return createBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * create all billable services for this training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBillableServices(params: CreateBillableServices$Params, context?: HttpContext): Observable<Array<BillableServiceDto>> {
    return this.createBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceDto>>): Array<BillableServiceDto> => r.body)
    );
  }

  /** Path part for operation `createStandardTrainingForCustomer()` */
  static readonly CreateStandardTrainingForCustomerPath = '/standard-training/staff';

  /**
   * create a new standard training in staff context
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStandardTrainingForCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStandardTrainingForCustomer$Response(params: CreateStandardTrainingForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<StandardTrainingDto>> {
    return createStandardTrainingForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new standard training in staff context
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createStandardTrainingForCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createStandardTrainingForCustomer(params: CreateStandardTrainingForCustomer$Params, context?: HttpContext): Observable<StandardTrainingDto> {
    return this.createStandardTrainingForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<StandardTrainingDto>): StandardTrainingDto => r.body)
    );
  }

}
