import { Pipe, PipeTransform } from '@angular/core';

import { AsiContext } from '../models/asi-context.model';

const DEFAULT_TITLE = 'myASI';

@Pipe({ name: 'pageTitleFromAsiContext' })
export class PageTitleFromAsiContextPipe implements PipeTransform {
	transform(asiContext: AsiContext): string {
		if (asiContext?.isStaffState()) {
			return asiContext.svgInfo.name;
		} else if (asiContext?.isCustomerState()) {
			return asiContext.customerInfo.asiSvgInfoDto.name;
		} else {
			return DEFAULT_TITLE;
		}
	}
}
