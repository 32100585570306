/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TrainingContext } from '../../models/training-context';
import { TrainingCustomerDto } from '../../models/training-customer-dto';
import { TrainingCustomerForm } from '../../models/training-customer-form';

export interface UpdateTrainingCustomer$Params {

/**
 * customer id
 */
  customerId: number;

/**
 * training id
 */
  trainingId: number;

/**
 * type of training (standard, fire safety, SHT)
 */
  type: TrainingContext;
      body: TrainingCustomerForm
}

export function updateTrainingCustomer(http: HttpClient, rootUrl: string, params: UpdateTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TrainingCustomerDto>> {
  const rb = new RequestBuilder(rootUrl, updateTrainingCustomer.PATH, 'put');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('trainingId', params.trainingId, {});
    rb.path('type', params.type, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TrainingCustomerDto>;
    })
  );
}

updateTrainingCustomer.PATH = '/training/{type}/{trainingId}/customer/{customerId}';
