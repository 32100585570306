/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RiskAssessmentDto } from '../../models/risk-assessment-dto';

export interface PatchMeasuresResponsiblePerson$Params {
  customerId: number;
  riskAssessmentId: number;

/**
 * The name of the responsible person to be assigned to each measure of the risk assessment. This can be empty or omitted and will be set to an empty string in that cases.
 */
  name?: string;
}

export function patchMeasuresResponsiblePerson(http: HttpClient, rootUrl: string, params: PatchMeasuresResponsiblePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskAssessmentDto>> {
  const rb = new RequestBuilder(rootUrl, patchMeasuresResponsiblePerson.PATH, 'patch');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('riskAssessmentId', params.riskAssessmentId, {});
    rb.query('name', params.name, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RiskAssessmentDto>;
    })
  );
}

patchMeasuresResponsiblePerson.PATH = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/responsible-person';
