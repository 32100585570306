import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../../utils/general.utils';

@Pipe({ name: 'durationInHours' })
export class DurationInHoursPipe implements PipeTransform {
	private static leadingZero(value: number): string {
		return value < 10 ? '0' : '';
	}

	transform(value: number | undefined | null): string {
		if (isDefined(value) && value !== 0) {
			return this.safeTransform(value);
		} else {
			return '00:00 h';
		}
	}

	/**
	 * [NOTE] extract hours and minutes separately and add them up to a string
	 */
	safeTransform(hours: number): string {
		const sign = hours < 0 ? '-' : '';
		const h = Math.floor(Math.abs(hours));
		const min = Math.floor((Math.abs(hours) * 60) % 60);
		const hString = DurationInHoursPipe.leadingZero(h) + h;
		const minString = DurationInHoursPipe.leadingZero(min) + min;

		return `${sign}${hString}:${minString} h`;
	}
}
