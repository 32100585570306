import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ENVIRONMENT } from './environments/environment';
import { StagesEnum } from '@svg/environment';

if (ENVIRONMENT.name === StagesEnum.STAGING || ENVIRONMENT.name === StagesEnum.PRODUCTION) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
