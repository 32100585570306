/* tslint:disable */
/* eslint-disable */

/**
 * business sectors from SVG, added MYSVG as virtual business sector indicating that the customer is also a mySvg portal user.
 */
export enum BusinessSector {
  AM = 'AM',
  ASI = 'ASI',
  BS = 'BS',
  ESSODE = 'ESSODE',
  DKV = 'DKV',
  DMAUT = 'DMAUT',
  PRIVACY = 'PRIVACY',
  ESSO = 'ESSO',
  EMAUT = 'EMAUT',
  ASFINAGMAUT = 'ASFINAGMAUT',
  BELGIENMAUT = 'BELGIENMAUT',
  EVIGNETTE = 'EVIGNETTE',
  EUROSHELL = 'EUROSHELL',
  FLEXBOX = 'FLEXBOX',
  GG = 'GG',
  SVGBOX = 'SVGBOX',
  TOTAL = 'TOTAL',
  TISPL = 'TISPL',
  US = 'US',
  VIALTIS = 'VIALTIS',
  MYSVG = 'MYSVG'
}
