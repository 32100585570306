/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ShtTrainingPlaceDto } from '../../models/sht-training-place-dto';

export interface GetAllPlacesOfShtTraining$Params {

/**
 * the planned type (id) for the SHT
 */
  typeId?: number;
}

export function getAllPlacesOfShtTraining(http: HttpClient, rootUrl: string, params?: GetAllPlacesOfShtTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShtTrainingPlaceDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllPlacesOfShtTraining.PATH, 'get');
  if (params) {
    rb.query('typeId', params.typeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ShtTrainingPlaceDto>>;
    })
  );
}

getAllPlacesOfShtTraining.PATH = '/sht-training/places';
