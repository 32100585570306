/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ParticipantDto } from '../../models/participant-dto';
import { ParticipantFormDto } from '../../models/participant-form-dto';
import { TrainingContext } from '../../models/training-context';

export interface CreateParticipant$Params {

/**
 * sht or fire-safety training
 */
  type: TrainingContext;

/**
 * training id
 */
  trainingId: number;
      body: ParticipantFormDto
}

export function createParticipant(http: HttpClient, rootUrl: string, params: CreateParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
  const rb = new RequestBuilder(rootUrl, createParticipant.PATH, 'post');
  if (params) {
    rb.path('type', params.type, {});
    rb.path('trainingId', params.trainingId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ParticipantDto>;
    })
  );
}

createParticipant.PATH = '/training/{type}/{trainingId}/participant/';
