import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgLoggerModule } from '@nsalaun/ng-logger';
import * as Sentry from '@sentry/angular-ivy';
import { OAuthModule } from 'angular-oauth2-oidc';

import { DialogModule, MAC_MAT_DIALOG_CONFIG } from '@svg-frontends/dialog';
import { GlobalErrorHandlerService } from '@svg-frontends/error';
import {
	AsiAuthenticationService,
	AuthenticationInterceptorService,
	AuthenticationService,
	DISCOVERY_DOCUMENT,
	ErrorResponseInterceptorService,
} from '@svg-frontends/session';

import { API_BASE_URLS_TOKEN, APPLICATION_NAME, APP_VERSION_TOKEN, STAGE_TOKEN, StagesEnum } from '@svg/environment';

import packageJson from '../../../../package.json';
import { API_BASE_URLS } from '../environments/api-base-urls';
import { ENVIRONMENT } from '../environments/environment';
import { ApiConfigurationModule } from './api-configuration.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorMessageModule } from './error-message.module';

// the second parameter 'de' is optional
registerLocaleData(localeDe, 'de');

if (ENVIRONMENT.sentry.url !== null) {
	const sentryConfig: Sentry.BrowserOptions = {
		dsn: ENVIRONMENT.sentry.url,
		environment: ENVIRONMENT.name,
		release: packageJson.version,
		integrations: [
			new Sentry.BrowserTracing({
				// [CAUTION] this option results in cors errors
				// tracingOrigins: ['localhost', 'staging.myasi.de', 'api.staging.myasi.de', 'myasi.de', 'api.myasi.de'],
				routingInstrumentation: Sentry.instrumentAngularRouting,
			}),
		],
		tracesSampleRate: 1.0,
		// Only track errors from our domains (ignore browser errors etc.)
		allowUrls: [/https?:\/\/.*\.?myasi\.de/],
		denyUrls: [
			// Do not track Chrome extension errors
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
		],
	};

	if (ENVIRONMENT.name === StagesEnum.PRODUCTION) {
		// on prod do dynamic sampling depending on pages
		sentryConfig.tracesSampler = (samplingContext) => {
			if (samplingContext.location.pathname.includes('/care/risk-assessment/scope')) {
				// reduce sampling rate for very busy navigation transaction in these subpages
				return ENVIRONMENT.sentry.tracesSampleRate / 2;
			}

			switch (samplingContext.location.pathname) {
				case '/':
					// send less transaction for busy pages (half of half so 0.25)
					return ENVIRONMENT.sentry.tracesSampleRate / 2;
				case '/dashboard':
					return ENVIRONMENT.sentry.tracesSampleRate / 2;
				default:
					// default traces sample rate for prod is half (0.5) for pages not filtered more precisely
					return ENVIRONMENT.sentry.tracesSampleRate;
			}
		};
	} else {
		// all other stages use sample rate defined in environment
		sentryConfig.tracesSampleRate = ENVIRONMENT.sentry.tracesSampleRate;
	}

	Sentry.init(sentryConfig);
}

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		ApiConfigurationModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		OAuthModule.forRoot(),
		ErrorMessageModule,
		DialogModule,
		NgLoggerModule.forRoot(ENVIRONMENT.logLevel),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: ENVIRONMENT.enabledUpdateServiceWorker,
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		// General Injection Tokens
		{ provide: LOCALE_ID, useValue: 'de' },
		// Version from single source of truth package.json
		{ provide: APP_VERSION_TOKEN, useValue: packageJson.version },
		// ENVIRONMENT Injection Tokens
		{ provide: APPLICATION_NAME, useValue: ENVIRONMENT.applicationName },
		{ provide: STAGE_TOKEN, useValue: ENVIRONMENT.name },
		{ provide: API_BASE_URLS_TOKEN, useValue: API_BASE_URLS },
		// AUTH Injection Tokens
		{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptorService, multi: true },
		// strictDiscoveryDocumentValidation must be disabled for azure ad compatibility: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/126
		{
			provide: DISCOVERY_DOCUMENT,
			useValue: {
				clientId: ENVIRONMENT.clientId,
				issuer: ENVIRONMENT.tokenIssuer,
				scope: ENVIRONMENT.scope,
			},
		},
		{ provide: AuthenticationService, useExisting: AsiAuthenticationService },
		// Sentry Injection Tokens
		{ provide: Sentry.TraceService, deps: [Router] },
		{
			provide: APP_INITIALIZER,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		// OTHER Injection Tokens
		{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorResponseInterceptorService, multi: true },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAC_MAT_DIALOG_CONFIG },
	],
})
export class AppModule {
	// initialize sentry tracing
	// eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
	constructor(_trace: Sentry.TraceService) {}
}
