import { Pipe, PipeTransform } from '@angular/core';

import { SecurityExpertDto } from '@myasi/api/customers/security_experts';

import { isDefined } from '../../utils/general.utils';

@Pipe({
	name: 'securityExpertName',
})
export class SecurityExpertNamePipe implements PipeTransform {
	transform(user: SecurityExpertDto): string {
		if (!isDefined(user)) {
			return '-';
		} else if (isDefined(user.firstname) && isDefined(user.lastname)) {
			return `${user.firstname} ${user.lastname}`;
		} else if (user.username) {
			return user.username;
		} else {
			return 'User (ohne Namen)';
		}
	}
}
