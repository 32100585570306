/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createReportTemplate } from '../fn/report-template-controller/create-report-template';
import { CreateReportTemplate$Params } from '../fn/report-template-controller/create-report-template';
import { deleteReportTemplate } from '../fn/report-template-controller/delete-report-template';
import { DeleteReportTemplate$Params } from '../fn/report-template-controller/delete-report-template';
import { DocumentLinkDto } from '../models/document-link-dto';
import { getReportTemplateLink } from '../fn/report-template-controller/get-report-template-link';
import { GetReportTemplateLink$Params } from '../fn/report-template-controller/get-report-template-link';
import { getReportTemplateList } from '../fn/report-template-controller/get-report-template-list';
import { GetReportTemplateList$Params } from '../fn/report-template-controller/get-report-template-list';
import { PageReportTemplateDto } from '../models/page-report-template-dto';
import { replaceReportTemplate } from '../fn/report-template-controller/replace-report-template';
import { ReplaceReportTemplate$Params } from '../fn/report-template-controller/replace-report-template';

@Injectable({ providedIn: 'root' })
export class ReportTemplateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `replaceReportTemplate()` */
  static readonly ReplaceReportTemplatePath = '/documents/reports/replace';

  /**
   * this will be used to replace template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceReportTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replaceReportTemplate$Response(params: ReplaceReportTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return replaceReportTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * this will be used to replace template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceReportTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replaceReportTemplate(params: ReplaceReportTemplate$Params, context?: HttpContext): Observable<void> {
    return this.replaceReportTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createReportTemplate()` */
  static readonly CreateReportTemplatePath = '/documents/reports/create';

  /**
   * this will be used to upload template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createReportTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createReportTemplate$Response(params: CreateReportTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createReportTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * this will be used to upload template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createReportTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createReportTemplate(params: CreateReportTemplate$Params, context?: HttpContext): Observable<void> {
    return this.createReportTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getReportTemplateList()` */
  static readonly GetReportTemplateListPath = '/documents/reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportTemplateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportTemplateList$Response(params?: GetReportTemplateList$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReportTemplateDto>> {
    return getReportTemplateList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportTemplateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportTemplateList(params?: GetReportTemplateList$Params, context?: HttpContext): Observable<PageReportTemplateDto> {
    return this.getReportTemplateList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReportTemplateDto>): PageReportTemplateDto => r.body)
    );
  }

  /** Path part for operation `getReportTemplateLink()` */
  static readonly GetReportTemplateLinkPath = '/documents/reports/download/{id}';

  /**
   * Download point for Excel Template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportTemplateLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportTemplateLink$Response(params: GetReportTemplateLink$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getReportTemplateLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Download point for Excel Template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportTemplateLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportTemplateLink(params: GetReportTemplateLink$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getReportTemplateLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `deleteReportTemplate()` */
  static readonly DeleteReportTemplatePath = '/documents/reports/{id}';

  /**
   * Remove document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReportTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportTemplate$Response(params: DeleteReportTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteReportTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteReportTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportTemplate(params: DeleteReportTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteReportTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
