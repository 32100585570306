/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AsdQuarterInvoiceFormDto } from '../../models/asd-quarter-invoice-form-dto';
import { DocumentLinkDto } from '../../models/document-link-dto';

export interface CreateLastAsdQuarterBill$Params {
  svgId: number;
      body: AsdQuarterInvoiceFormDto
}

export function createLastAsdQuarterBill(http: HttpClient, rootUrl: string, params: CreateLastAsdQuarterBill$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
  const rb = new RequestBuilder(rootUrl, createLastAsdQuarterBill.PATH, 'post');
  if (params) {
    rb.path('svgId', params.svgId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentLinkDto>;
    })
  );
}

createLastAsdQuarterBill.PATH = '/billable-services-quarter/{svgId}/asd-quarter-bill/last';
