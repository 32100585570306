/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCustomersWithBillableServicesBeforeDateByBillableServiceCategory } from '../fn/customer-billable-services-long-ago-controller/get-customers-with-billable-services-before-date-by-billable-service-category';
import { GetCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Params } from '../fn/customer-billable-services-long-ago-controller/get-customers-with-billable-services-before-date-by-billable-service-category';
import { getCustomersWithBillableServicesBeforeDateByBillableServiceType } from '../fn/customer-billable-services-long-ago-controller/get-customers-with-billable-services-before-date-by-billable-service-type';
import { GetCustomersWithBillableServicesBeforeDateByBillableServiceType$Params } from '../fn/customer-billable-services-long-ago-controller/get-customers-with-billable-services-before-date-by-billable-service-type';
import { getPredefinedBillableServiceTypeIds } from '../fn/customer-billable-services-long-ago-controller/get-predefined-billable-service-type-ids';
import { GetPredefinedBillableServiceTypeIds$Params } from '../fn/customer-billable-services-long-ago-controller/get-predefined-billable-service-type-ids';
import { PredefinedBillableServiceTypeDto } from '../models/predefined-billable-service-type-dto';
import { ResponseCustomerOverviewDto } from '../models/response-customer-overview-dto';


/**
 * Billable services long time ago
 */
@Injectable({ providedIn: 'root' })
export class CustomerBillableServicesLongAgoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPredefinedBillableServiceTypeIds()` */
  static readonly GetPredefinedBillableServiceTypeIdsPath = '/customers/billable-services-long-ago/types';

  /**
   * Returns list of predefined filters with billable service type ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPredefinedBillableServiceTypeIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPredefinedBillableServiceTypeIds$Response(params?: GetPredefinedBillableServiceTypeIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PredefinedBillableServiceTypeDto>>> {
    return getPredefinedBillableServiceTypeIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns list of predefined filters with billable service type ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPredefinedBillableServiceTypeIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPredefinedBillableServiceTypeIds(params?: GetPredefinedBillableServiceTypeIds$Params, context?: HttpContext): Observable<Array<PredefinedBillableServiceTypeDto>> {
    return this.getPredefinedBillableServiceTypeIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PredefinedBillableServiceTypeDto>>): Array<PredefinedBillableServiceTypeDto> => r.body)
    );
  }

  /** Path part for operation `getCustomersWithBillableServicesBeforeDateByBillableServiceCategory()` */
  static readonly GetCustomersWithBillableServicesBeforeDateByBillableServiceCategoryPath = '/customers/billable-services-long-ago/by-predefined-billable-service-filter';

  /**
   * Returns a list of all active customers for which the requested billable services have not been billed since the given date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersWithBillableServicesBeforeDateByBillableServiceCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Response(params: GetCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerOverviewDto>> {
    return getCustomersWithBillableServicesBeforeDateByBillableServiceCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all active customers for which the requested billable services have not been billed since the given date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithBillableServicesBeforeDateByBillableServiceCategory(params: GetCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Params, context?: HttpContext): Observable<ResponseCustomerOverviewDto> {
    return this.getCustomersWithBillableServicesBeforeDateByBillableServiceCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerOverviewDto>): ResponseCustomerOverviewDto => r.body)
    );
  }

  /** Path part for operation `getCustomersWithBillableServicesBeforeDateByBillableServiceType()` */
  static readonly GetCustomersWithBillableServicesBeforeDateByBillableServiceTypePath = '/customers/billable-services-long-ago/by-billable-service-type';

  /**
   * Returns a list of all active customers for which the requested billable services have not been billed since the given date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersWithBillableServicesBeforeDateByBillableServiceType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithBillableServicesBeforeDateByBillableServiceType$Response(params: GetCustomersWithBillableServicesBeforeDateByBillableServiceType$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerOverviewDto>> {
    return getCustomersWithBillableServicesBeforeDateByBillableServiceType(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all active customers for which the requested billable services have not been billed since the given date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersWithBillableServicesBeforeDateByBillableServiceType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithBillableServicesBeforeDateByBillableServiceType(params: GetCustomersWithBillableServicesBeforeDateByBillableServiceType$Params, context?: HttpContext): Observable<ResponseCustomerOverviewDto> {
    return this.getCustomersWithBillableServicesBeforeDateByBillableServiceType$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerOverviewDto>): ResponseCustomerOverviewDto => r.body)
    );
  }

}
