/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RiskPriorityDetailsDto } from '../../models/risk-priority-details-dto';

export interface DeleteRiskPriority$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * the risk priority details id
 */
  riskPriorityDetailsId: number;
}

export function deleteRiskPriority(http: HttpClient, rootUrl: string, params: DeleteRiskPriority$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskPriorityDetailsDto>> {
  const rb = new RequestBuilder(rootUrl, deleteRiskPriority.PATH, 'delete');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('riskPriorityDetailsId', params.riskPriorityDetailsId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RiskPriorityDetailsDto>;
    })
  );
}

deleteRiskPriority.PATH = '/customers/{customerId}/risk-priority-details/{riskPriorityDetailsId}';
