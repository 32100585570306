/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { approveBillableServices } from '../fn/billable-service-controller/approve-billable-services';
import { ApproveBillableServices$Params } from '../fn/billable-service-controller/approve-billable-services';
import { BillableServiceCategoryDto } from '../models/billable-service-category-dto';
import { BillableServiceCategoryOverviewDto } from '../models/billable-service-category-overview-dto';
import { BillableServiceDto } from '../models/billable-service-dto';
import { BillableServiceTypeDto } from '../models/billable-service-type-dto';
import { createBillableService } from '../fn/billable-service-controller/create-billable-service';
import { CreateBillableService$Params } from '../fn/billable-service-controller/create-billable-service';
import { createOverdraftBillableService } from '../fn/billable-service-controller/create-overdraft-billable-service';
import { CreateOverdraftBillableService$Params } from '../fn/billable-service-controller/create-overdraft-billable-service';
import { deleteBillableService } from '../fn/billable-service-controller/delete-billable-service';
import { DeleteBillableService$Params } from '../fn/billable-service-controller/delete-billable-service';
import { DocumentLinkDto } from '../models/document-link-dto';
import { getAllBillableServiceCategories } from '../fn/billable-service-controller/get-all-billable-service-categories';
import { GetAllBillableServiceCategories$Params } from '../fn/billable-service-controller/get-all-billable-service-categories';
import { getAllBillableServiceTypes } from '../fn/billable-service-controller/get-all-billable-service-types';
import { GetAllBillableServiceTypes$Params } from '../fn/billable-service-controller/get-all-billable-service-types';
import { getAsdApprovedBillableServices } from '../fn/billable-service-controller/get-asd-approved-billable-services';
import { GetAsdApprovedBillableServices$Params } from '../fn/billable-service-controller/get-asd-approved-billable-services';
import { getAsdDeclinedBillableServices } from '../fn/billable-service-controller/get-asd-declined-billable-services';
import { GetAsdDeclinedBillableServices$Params } from '../fn/billable-service-controller/get-asd-declined-billable-services';
import { getBillableService } from '../fn/billable-service-controller/get-billable-service';
import { GetBillableService$Params } from '../fn/billable-service-controller/get-billable-service';
import { getBillableServiceCategories } from '../fn/billable-service-controller/get-billable-service-categories';
import { GetBillableServiceCategories$Params } from '../fn/billable-service-controller/get-billable-service-categories';
import { getBillableServicesDocumentProof } from '../fn/billable-service-controller/get-billable-services-document-proof';
import { GetBillableServicesDocumentProof$Params } from '../fn/billable-service-controller/get-billable-services-document-proof';
import { getSubmittedToKoodiBillableServices } from '../fn/billable-service-controller/get-submitted-to-koodi-billable-services';
import { GetSubmittedToKoodiBillableServices$Params } from '../fn/billable-service-controller/get-submitted-to-koodi-billable-services';
import { getUnbilledBillableService } from '../fn/billable-service-controller/get-unbilled-billable-service';
import { GetUnbilledBillableService$Params } from '../fn/billable-service-controller/get-unbilled-billable-service';
import { ResponseAsdDeclinedBillableServiceDto } from '../models/response-asd-declined-billable-service-dto';
import { ResponseBillableServiceDto } from '../models/response-billable-service-dto';
import { ResponseSubmittedToKoodiBillableServiceDto } from '../models/response-submitted-to-koodi-billable-service-dto';
import { updateBillableService } from '../fn/billable-service-controller/update-billable-service';
import { UpdateBillableService$Params } from '../fn/billable-service-controller/update-billable-service';
import { updateOverdraftBillableService } from '../fn/billable-service-controller/update-overdraft-billable-service';
import { UpdateOverdraftBillableService$Params } from '../fn/billable-service-controller/update-overdraft-billable-service';
import { validateServiceTime } from '../fn/billable-service-controller/validate-service-time';
import { ValidateServiceTime$Params } from '../fn/billable-service-controller/validate-service-time';


/**
 * Rest operations on billable services.
 */
@Injectable({ providedIn: 'root' })
export class BillableServiceControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateOverdraftBillableService()` */
  static readonly UpdateOverdraftBillableServicePath = '/customers/{customerId}/overdraft-billable-services/{billableServiceId}';

  /**
   * Update an overdraft billable service.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOverdraftBillableService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOverdraftBillableService$Response(params: UpdateOverdraftBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<BillableServiceDto>> {
    return updateOverdraftBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an overdraft billable service.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOverdraftBillableService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOverdraftBillableService(params: UpdateOverdraftBillableService$Params, context?: HttpContext): Observable<BillableServiceDto> {
    return this.updateOverdraftBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillableServiceDto>): BillableServiceDto => r.body)
    );
  }

  /** Path part for operation `updateBillableService()` */
  static readonly UpdateBillableServicePath = '/customers/{customerId}/billable-services/{billableServiceId}';

  /**
   * Update a billable service.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBillableService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBillableService$Response(params: UpdateBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<BillableServiceDto>> {
    return updateBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a billable service.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBillableService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBillableService(params: UpdateBillableService$Params, context?: HttpContext): Observable<BillableServiceDto> {
    return this.updateBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillableServiceDto>): BillableServiceDto => r.body)
    );
  }

  /** Path part for operation `deleteBillableService()` */
  static readonly DeleteBillableServicePath = '/customers/{customerId}/billable-services/{billableServiceId}';

  /**
   * Delete a billable service.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBillableService()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBillableService$Response(params: DeleteBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a billable service.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBillableService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBillableService(params: DeleteBillableService$Params, context?: HttpContext): Observable<void> {
    return this.deleteBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createOverdraftBillableService()` */
  static readonly CreateOverdraftBillableServicePath = '/customers/{customerId}/overdraft-billable-services';

  /**
   * Creates an overdraft billable service.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOverdraftBillableService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOverdraftBillableService$Response(params: CreateOverdraftBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<BillableServiceDto>> {
    return createOverdraftBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates an overdraft billable service.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOverdraftBillableService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOverdraftBillableService(params: CreateOverdraftBillableService$Params, context?: HttpContext): Observable<BillableServiceDto> {
    return this.createOverdraftBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillableServiceDto>): BillableServiceDto => r.body)
    );
  }

  /** Path part for operation `getBillableService()` */
  static readonly GetBillableServicePath = '/customers/{customerId}/billable-services';

  /**
   * Get billable services for customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBillableService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableService$Response(params: GetBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBillableServiceDto>> {
    return getBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billable services for customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBillableService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableService(params: GetBillableService$Params, context?: HttpContext): Observable<ResponseBillableServiceDto> {
    return this.getBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBillableServiceDto>): ResponseBillableServiceDto => r.body)
    );
  }

  /** Path part for operation `createBillableService()` */
  static readonly CreateBillableServicePath = '/customers/{customerId}/billable-services';

  /**
   * Creates a billable service.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBillableService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBillableService$Response(params: CreateBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<BillableServiceDto>> {
    return createBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a billable service.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBillableService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBillableService(params: CreateBillableService$Params, context?: HttpContext): Observable<BillableServiceDto> {
    return this.createBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillableServiceDto>): BillableServiceDto => r.body)
    );
  }

  /** Path part for operation `approveBillableServices()` */
  static readonly ApproveBillableServicesPath = '/billable-services/approve';

  /**
   * Approve multiple billable services.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveBillableServices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveBillableServices$Response(params: ApproveBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceDto>>> {
    return approveBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Approve multiple billable services.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveBillableServices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveBillableServices(params: ApproveBillableServices$Params, context?: HttpContext): Observable<Array<BillableServiceDto>> {
    return this.approveBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceDto>>): Array<BillableServiceDto> => r.body)
    );
  }

  /** Path part for operation `validateServiceTime()` */
  static readonly ValidateServiceTimePath = '/validate-service-time';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateServiceTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateServiceTime$Response(params: ValidateServiceTime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateServiceTime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateServiceTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateServiceTime(params: ValidateServiceTime$Params, context?: HttpContext): Observable<void> {
    return this.validateServiceTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSubmittedToKoodiBillableServices()` */
  static readonly GetSubmittedToKoodiBillableServicesPath = '/submitted-to-koodi-billable-services';

  /**
   * Get all billable services which are submitted to Koodi (but not yet accepted).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubmittedToKoodiBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubmittedToKoodiBillableServices$Response(params?: GetSubmittedToKoodiBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSubmittedToKoodiBillableServiceDto>> {
    return getSubmittedToKoodiBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all billable services which are submitted to Koodi (but not yet accepted).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubmittedToKoodiBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubmittedToKoodiBillableServices(params?: GetSubmittedToKoodiBillableServices$Params, context?: HttpContext): Observable<ResponseSubmittedToKoodiBillableServiceDto> {
    return this.getSubmittedToKoodiBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSubmittedToKoodiBillableServiceDto>): ResponseSubmittedToKoodiBillableServiceDto => r.body)
    );
  }

  /** Path part for operation `getAsdDeclinedBillableServices()` */
  static readonly GetAsdDeclinedBillableServicesPath = '/declined-billable-services';

  /**
   * Get all billable services which were declined by ASD.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsdDeclinedBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdDeclinedBillableServices$Response(params?: GetAsdDeclinedBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseAsdDeclinedBillableServiceDto>> {
    return getAsdDeclinedBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all billable services which were declined by ASD.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsdDeclinedBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdDeclinedBillableServices(params?: GetAsdDeclinedBillableServices$Params, context?: HttpContext): Observable<ResponseAsdDeclinedBillableServiceDto> {
    return this.getAsdDeclinedBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseAsdDeclinedBillableServiceDto>): ResponseAsdDeclinedBillableServiceDto => r.body)
    );
  }

  /** Path part for operation `getBillableServicesDocumentProof()` */
  static readonly GetBillableServicesDocumentProofPath = '/customers/{customerId}/billable-services/document/proof';

  /**
   * Get billable services proof document for customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBillableServicesDocumentProof()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableServicesDocumentProof$Response(params: GetBillableServicesDocumentProof$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getBillableServicesDocumentProof(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billable services proof document for customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBillableServicesDocumentProof$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableServicesDocumentProof(params: GetBillableServicesDocumentProof$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getBillableServicesDocumentProof$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `getBillableServiceCategories()` */
  static readonly GetBillableServiceCategoriesPath = '/customers/{customerId}/billable-services/categories';

  /**
   * Get billable service categories with their service types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBillableServiceCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableServiceCategories$Response(params: GetBillableServiceCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>> {
    return getBillableServiceCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get billable service categories with their service types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBillableServiceCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillableServiceCategories(params: GetBillableServiceCategories$Params, context?: HttpContext): Observable<Array<BillableServiceCategoryOverviewDto>> {
    return this.getBillableServiceCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>): Array<BillableServiceCategoryOverviewDto> => r.body)
    );
  }

  /** Path part for operation `getUnbilledBillableService()` */
  static readonly GetUnbilledBillableServicePath = '/billable-services';

  /**
   * Get unbilled billable services customer independent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnbilledBillableService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnbilledBillableService$Response(params?: GetUnbilledBillableService$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBillableServiceDto>> {
    return getUnbilledBillableService(this.http, this.rootUrl, params, context);
  }

  /**
   * Get unbilled billable services customer independent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnbilledBillableService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnbilledBillableService(params?: GetUnbilledBillableService$Params, context?: HttpContext): Observable<ResponseBillableServiceDto> {
    return this.getUnbilledBillableService$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBillableServiceDto>): ResponseBillableServiceDto => r.body)
    );
  }

  /** Path part for operation `getAllBillableServiceTypes()` */
  static readonly GetAllBillableServiceTypesPath = '/billable-services/types';

  /**
   * Get all billable service types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBillableServiceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillableServiceTypes$Response(params?: GetAllBillableServiceTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceTypeDto>>> {
    return getAllBillableServiceTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all billable service types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBillableServiceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillableServiceTypes(params?: GetAllBillableServiceTypes$Params, context?: HttpContext): Observable<Array<BillableServiceTypeDto>> {
    return this.getAllBillableServiceTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceTypeDto>>): Array<BillableServiceTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllBillableServiceCategories()` */
  static readonly GetAllBillableServiceCategoriesPath = '/billable-services/categories';

  /**
   * Get all billable service categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBillableServiceCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillableServiceCategories$Response(params?: GetAllBillableServiceCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceCategoryDto>>> {
    return getAllBillableServiceCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all billable service categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBillableServiceCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBillableServiceCategories(params?: GetAllBillableServiceCategories$Params, context?: HttpContext): Observable<Array<BillableServiceCategoryDto>> {
    return this.getAllBillableServiceCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceCategoryDto>>): Array<BillableServiceCategoryDto> => r.body)
    );
  }

  /** Path part for operation `getAsdApprovedBillableServices()` */
  static readonly GetAsdApprovedBillableServicesPath = '/asd-approved-billable-services';

  /**
   * Get asd approved billable services customer independent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsdApprovedBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdApprovedBillableServices$Response(params?: GetAsdApprovedBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBillableServiceDto>> {
    return getAsdApprovedBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get asd approved billable services customer independent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsdApprovedBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdApprovedBillableServices(params?: GetAsdApprovedBillableServices$Params, context?: HttpContext): Observable<ResponseBillableServiceDto> {
    return this.getAsdApprovedBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBillableServiceDto>): ResponseBillableServiceDto => r.body)
    );
  }

}
