/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ContractDto } from '../../models/contract-dto';

export interface PatchDocument$Params {

/**
 * customerId
 */
  customerId: number;

/**
 * contractId
 */
  contractId: number;

/**
 * id of document to be attached to contract
 */
  documentId: number;
}

export function patchDocument(http: HttpClient, rootUrl: string, params: PatchDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
  const rb = new RequestBuilder(rootUrl, patchDocument.PATH, 'patch');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('contractId', params.contractId, {});
    rb.path('documentId', params.documentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ContractDto>;
    })
  );
}

patchDocument.PATH = '/customers/{customerId}/contracts/{contractId}/document/{documentId}';
