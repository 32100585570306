/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DefaultWorkingTimeOverdraftControllerService } from './services/default-working-time-overdraft-controller.service';
import { CustomerContractsControllerService } from './services/customer-contracts-controller.service';
import { WorkingTimeControllerService } from './services/working-time-controller.service';
import { PriceConditionControllerService } from './services/price-condition-controller.service';
import { OccupationalSafetyAndHealthcareControllerService } from './services/occupational-safety-and-healthcare-controller.service';
import { TransportCarrierControllerService } from './services/transport-carrier-controller.service';
import { AccountabilityControllerService } from './services/accountability-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DefaultWorkingTimeOverdraftControllerService,
    CustomerContractsControllerService,
    WorkingTimeControllerService,
    PriceConditionControllerService,
    OccupationalSafetyAndHealthcareControllerService,
    TransportCarrierControllerService,
    AccountabilityControllerService,
    ApiConfiguration
  ],
})
export class CustomersContractsModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersContractsModule> {
    return {
      ngModule: CustomersContractsModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersContractsModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersContractsModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
