/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddressDto } from '../../models/address-dto';

export interface UpdateAddress$Params {

/**
 * The customer technical id
 */
  customerId: number;

/**
 * AdressArten: 40=Stammadresse, 45=Postadresse, 63=GfB-Adresse, 130=Durchgangsarzt, 131=Giftnotruf(freier Kunde) oder Krankenhaus(ASD Kunde)
 */
  addressType: number;

/**
 * AdressArten: 40=Stammadresse, 45=Postadresse, 63=GfB-Adresse, 130=Durchgangsarzt, 131=Giftnotruf(freier Kunde) oder Krankenhaus(ASD Kunde)
 */
  initialAddressType: number;

/**
 * list of name fields
 */
  nameFields?: Array<string>;

/**
 * Street name followed by housenumber
 */
  street?: string;

/**
 * postcode
 */
  postcode?: string;

/**
 * place
 */
  place?: string;

/**
 * district
 */
  district?: string;

/**
 * land
 */
  countryId?: number;
}

export function updateAddress(http: HttpClient, rootUrl: string, params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
  const rb = new RequestBuilder(rootUrl, updateAddress.PATH, 'patch');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.query('addressType', params.addressType, {});
    rb.query('initialAddressType', params.initialAddressType, {});
    rb.query('nameFields', params.nameFields, {});
    rb.query('street', params.street, {});
    rb.query('postcode', params.postcode, {});
    rb.query('place', params.place, {});
    rb.query('district', params.district, {});
    rb.query('countryId', params.countryId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AddressDto>;
    })
  );
}

updateAddress.PATH = '/customers/{customerId}/addresses';
