/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllTransportCarrierTypes } from '../fn/transport-carrier-controller/get-all-transport-carrier-types';
import { GetAllTransportCarrierTypes$Params } from '../fn/transport-carrier-controller/get-all-transport-carrier-types';
import { getTransportCarriersOfCustomer } from '../fn/transport-carrier-controller/get-transport-carriers-of-customer';
import { GetTransportCarriersOfCustomer$Params } from '../fn/transport-carrier-controller/get-transport-carriers-of-customer';
import { TransportCarrierDto } from '../models/transport-carrier-dto';
import { TransportCarrierTypeDto } from '../models/transport-carrier-type-dto';
import { updateTransportCarrierOfCustomer } from '../fn/transport-carrier-controller/update-transport-carrier-of-customer';
import { UpdateTransportCarrierOfCustomer$Params } from '../fn/transport-carrier-controller/update-transport-carrier-of-customer';


/**
 * Manage customers transport carrier which belongs to the dangerous goods contract.
 */
@Injectable({ providedIn: 'root' })
export class TransportCarrierControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTransportCarriersOfCustomer()` */
  static readonly GetTransportCarriersOfCustomerPath = '/customers/{customerId}/contracts/dangerous-goods/transport-carriers';

  /**
   * Get all transport carrier types of customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransportCarriersOfCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransportCarriersOfCustomer$Response(params: GetTransportCarriersOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransportCarrierDto>>> {
    return getTransportCarriersOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all transport carrier types of customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransportCarriersOfCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransportCarriersOfCustomer(params: GetTransportCarriersOfCustomer$Params, context?: HttpContext): Observable<Array<TransportCarrierDto>> {
    return this.getTransportCarriersOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransportCarrierDto>>): Array<TransportCarrierDto> => r.body)
    );
  }

  /** Path part for operation `updateTransportCarrierOfCustomer()` */
  static readonly UpdateTransportCarrierOfCustomerPath = '/customers/{customerId}/contracts/dangerous-goods/transport-carriers';

  /**
   * Replace the list of customers transport carriers. Always deletes all relations to transport carriers and sets new ones based on list param
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTransportCarrierOfCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransportCarrierOfCustomer$Response(params: UpdateTransportCarrierOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransportCarrierDto>>> {
    return updateTransportCarrierOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Replace the list of customers transport carriers. Always deletes all relations to transport carriers and sets new ones based on list param
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTransportCarrierOfCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransportCarrierOfCustomer(params: UpdateTransportCarrierOfCustomer$Params, context?: HttpContext): Observable<Array<TransportCarrierDto>> {
    return this.updateTransportCarrierOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransportCarrierDto>>): Array<TransportCarrierDto> => r.body)
    );
  }

  /** Path part for operation `getAllTransportCarrierTypes()` */
  static readonly GetAllTransportCarrierTypesPath = '/transport-carrier-types';

  /**
   * Get all transport carrier types available.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTransportCarrierTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTransportCarrierTypes$Response(params?: GetAllTransportCarrierTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransportCarrierTypeDto>>> {
    return getAllTransportCarrierTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all transport carrier types available.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTransportCarrierTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTransportCarrierTypes(params?: GetAllTransportCarrierTypes$Params, context?: HttpContext): Observable<Array<TransportCarrierTypeDto>> {
    return this.getAllTransportCarrierTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransportCarrierTypeDto>>): Array<TransportCarrierTypeDto> => r.body)
    );
  }

}
