/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactPersonDto } from '../models/contact-person-dto';
import { createAdditionalContactPerson } from '../fn/contact-person-controller/create-additional-contact-person';
import { CreateAdditionalContactPerson$Params } from '../fn/contact-person-controller/create-additional-contact-person';
import { createContactPerson } from '../fn/contact-person-controller/create-contact-person';
import { CreateContactPerson$Params } from '../fn/contact-person-controller/create-contact-person';
import { deleteContactPerson } from '../fn/contact-person-controller/delete-contact-person';
import { DeleteContactPerson$Params } from '../fn/contact-person-controller/delete-contact-person';
import { getAllContactPersons } from '../fn/contact-person-controller/get-all-contact-persons';
import { GetAllContactPersons$Params } from '../fn/contact-person-controller/get-all-contact-persons';
import { getContactPersonById } from '../fn/contact-person-controller/get-contact-person-by-id';
import { GetContactPersonById$Params } from '../fn/contact-person-controller/get-contact-person-by-id';
import { ResponseContactPersonDto } from '../models/response-contact-person-dto';
import { updateAdditionalContactPerson } from '../fn/contact-person-controller/update-additional-contact-person';
import { UpdateAdditionalContactPerson$Params } from '../fn/contact-person-controller/update-additional-contact-person';
import { updateContactPerson } from '../fn/contact-person-controller/update-contact-person';
import { UpdateContactPerson$Params } from '../fn/contact-person-controller/update-contact-person';

@Injectable({ providedIn: 'root' })
export class ContactPersonControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateContactPerson()` */
  static readonly UpdateContactPersonPath = '/customers/{customerId}/contact-persons/{contactPersonId}';

  /**
   * Updates a contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContactPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContactPerson$Response(params: UpdateContactPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
    return updateContactPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContactPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContactPerson(params: UpdateContactPerson$Params, context?: HttpContext): Observable<ContactPersonDto> {
    return this.updateContactPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactPersonDto>): ContactPersonDto => r.body)
    );
  }

  /** Path part for operation `updateAdditionalContactPerson()` */
  static readonly UpdateAdditionalContactPersonPath = '/customers/{customerId}/additional-contact-persons/{id}';

  /**
   * Updates a additional contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdditionalContactPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdditionalContactPerson$Response(params: UpdateAdditionalContactPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
    return updateAdditionalContactPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a additional contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdditionalContactPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdditionalContactPerson(params: UpdateAdditionalContactPerson$Params, context?: HttpContext): Observable<ContactPersonDto> {
    return this.updateAdditionalContactPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactPersonDto>): ContactPersonDto => r.body)
    );
  }

  /** Path part for operation `createContactPerson()` */
  static readonly CreateContactPersonPath = '/customers/{customerId}/contact-person';

  /**
   * Create a contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContactPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContactPerson$Response(params: CreateContactPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
    return createContactPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContactPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContactPerson(params: CreateContactPerson$Params, context?: HttpContext): Observable<ContactPersonDto> {
    return this.createContactPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactPersonDto>): ContactPersonDto => r.body)
    );
  }

  /** Path part for operation `createAdditionalContactPerson()` */
  static readonly CreateAdditionalContactPersonPath = '/customers/{customerId}/additional-contact-person';

  /**
   * Create a additional contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAdditionalContactPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdditionalContactPerson$Response(params: CreateAdditionalContactPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
    return createAdditionalContactPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a additional contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAdditionalContactPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdditionalContactPerson(params: CreateAdditionalContactPerson$Params, context?: HttpContext): Observable<ContactPersonDto> {
    return this.createAdditionalContactPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactPersonDto>): ContactPersonDto => r.body)
    );
  }

  /** Path part for operation `getAllContactPersons()` */
  static readonly GetAllContactPersonsPath = '/customers/{customerId}/contact-persons';

  /**
   * Get all contact person information of a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllContactPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllContactPersons$Response(params: GetAllContactPersons$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseContactPersonDto>> {
    return getAllContactPersons(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contact person information of a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllContactPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllContactPersons(params: GetAllContactPersons$Params, context?: HttpContext): Observable<ResponseContactPersonDto> {
    return this.getAllContactPersons$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseContactPersonDto>): ResponseContactPersonDto => r.body)
    );
  }

  /** Path part for operation `getContactPersonById()` */
  static readonly GetContactPersonByIdPath = '/contact-persons/{id}';

  /**
   * Get a contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContactPersonById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactPersonById$Response(params: GetContactPersonById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
    return getContactPersonById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContactPersonById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactPersonById(params: GetContactPersonById$Params, context?: HttpContext): Observable<ContactPersonDto> {
    return this.getContactPersonById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactPersonDto>): ContactPersonDto => r.body)
    );
  }

  /** Path part for operation `deleteContactPerson()` */
  static readonly DeleteContactPersonPath = '/contact-persons/{id}';

  /**
   * delete a contact person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContactPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContactPerson$Response(params: DeleteContactPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteContactPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a contact person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContactPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContactPerson(params: DeleteContactPerson$Params, context?: HttpContext): Observable<void> {
    return this.deleteContactPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
