/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsiCustomerTypeDto } from '../models/asi-customer-type-dto';
import { getAllCustomerTypes } from '../fn/customer-type-controller/get-all-customer-types';
import { GetAllCustomerTypes$Params } from '../fn/customer-type-controller/get-all-customer-types';


/**
 * Manages customer types
 */
@Injectable({ providedIn: 'root' })
export class CustomerTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllCustomerTypes()` */
  static readonly GetAllCustomerTypesPath = '/customer-types';

  /**
   * Get all customer types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomerTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerTypes$Response(params?: GetAllCustomerTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AsiCustomerTypeDto>>> {
    return getAllCustomerTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all customer types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCustomerTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerTypes(params?: GetAllCustomerTypes$Params, context?: HttpContext): Observable<Array<AsiCustomerTypeDto>> {
    return this.getAllCustomerTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AsiCustomerTypeDto>>): Array<AsiCustomerTypeDto> => r.body)
    );
  }

}
