/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ValidateRegulationsCanBeAdded$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * id of hazard factor, which groups regulations (Gefaehrdungsfaktor)
 */
  hazardFactorId: number;

/**
 * list of regulation ids to check
 */
  regulationIds: Array<number>;
}

export function validateRegulationsCanBeAdded(http: HttpClient, rootUrl: string, params: ValidateRegulationsCanBeAdded$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, validateRegulationsCanBeAdded.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('hazardFactorId', params.hazardFactorId, {});
    rb.query('regulationIds', params.regulationIds, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

validateRegulationsCanBeAdded.PATH = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations/validate';
