/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyDoctorDto } from '../models/company-doctor-dto';
import { createCompanyDoctorByCustomerId } from '../fn/company-doctor-controller/create-company-doctor-by-customer-id';
import { CreateCompanyDoctorByCustomerId$Params } from '../fn/company-doctor-controller/create-company-doctor-by-customer-id';
import { deleteCompanyDoctorByCustomerId } from '../fn/company-doctor-controller/delete-company-doctor-by-customer-id';
import { DeleteCompanyDoctorByCustomerId$Params } from '../fn/company-doctor-controller/delete-company-doctor-by-customer-id';
import { getCompanyDoctorByCustomerId } from '../fn/company-doctor-controller/get-company-doctor-by-customer-id';
import { GetCompanyDoctorByCustomerId$Params } from '../fn/company-doctor-controller/get-company-doctor-by-customer-id';
import { updateCompanyDoctorByCustomerId } from '../fn/company-doctor-controller/update-company-doctor-by-customer-id';
import { UpdateCompanyDoctorByCustomerId$Params } from '../fn/company-doctor-controller/update-company-doctor-by-customer-id';


/**
 * Manages the customer company doctor address
 */
@Injectable({ providedIn: 'root' })
export class CompanyDoctorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyDoctorByCustomerId()` */
  static readonly GetCompanyDoctorByCustomerIdPath = '/customers/{customerId}/company-doctor';

  /**
   * Get company doctor of a customer by customer id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyDoctorByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDoctorByCustomerId$Response(params: GetCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDoctorDto>> {
    return getCompanyDoctorByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get company doctor of a customer by customer id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyDoctorByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDoctorByCustomerId(params: GetCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<CompanyDoctorDto> {
    return this.getCompanyDoctorByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDoctorDto>): CompanyDoctorDto => r.body)
    );
  }

  /** Path part for operation `updateCompanyDoctorByCustomerId()` */
  static readonly UpdateCompanyDoctorByCustomerIdPath = '/customers/{customerId}/company-doctor';

  /**
   * Update company doctor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyDoctorByCustomerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyDoctorByCustomerId$Response(params: UpdateCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDoctorDto>> {
    return updateCompanyDoctorByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Update company doctor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyDoctorByCustomerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyDoctorByCustomerId(params: UpdateCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<CompanyDoctorDto> {
    return this.updateCompanyDoctorByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDoctorDto>): CompanyDoctorDto => r.body)
    );
  }

  /** Path part for operation `createCompanyDoctorByCustomerId()` */
  static readonly CreateCompanyDoctorByCustomerIdPath = '/customers/{customerId}/company-doctor';

  /**
   * Create company doctor to a customer.
   *
   * returns an CompanyDoctorDto with the newly created company doctor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyDoctorByCustomerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyDoctorByCustomerId$Response(params: CreateCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDoctorDto>> {
    return createCompanyDoctorByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Create company doctor to a customer.
   *
   * returns an CompanyDoctorDto with the newly created company doctor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyDoctorByCustomerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyDoctorByCustomerId(params: CreateCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<CompanyDoctorDto> {
    return this.createCompanyDoctorByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDoctorDto>): CompanyDoctorDto => r.body)
    );
  }

  /** Path part for operation `deleteCompanyDoctorByCustomerId()` */
  static readonly DeleteCompanyDoctorByCustomerIdPath = '/customers/{customerId}/company-doctor';

  /**
   * Delete company doctor of a customer by customerId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyDoctorByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDoctorByCustomerId$Response(params: DeleteCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyDoctorByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete company doctor of a customer by customerId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyDoctorByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDoctorByCustomerId(params: DeleteCompanyDoctorByCustomerId$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyDoctorByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
