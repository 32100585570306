import { AsiApiBaseUrls } from '@mysvg/utils';

import { ENVIRONMENT } from './environment';
import { LOCAL_SERVICES } from './local-services/local-services';

// TODO - add run configuration for all myasi microservices - just like in mysvg and then add ports specified in those run configs
export const API_BASE_URLS: AsiApiBaseUrls = {
	auth: LOCAL_SERVICES.auth ? 'http://localhost:13001' : `https://${ENVIRONMENT.apiInfix}/auth/1.0`,
	billableService: LOCAL_SERVICES.billableService ? 'http://localhost:13003' : `https://${ENVIRONMENT.apiInfix}/billable-service/1.0`,
	centrifugo: LOCAL_SERVICES.centrifugo ? 'http://localhost:14006' : `https://${ENVIRONMENT.apiInfix}/centrifugo/1.0/connection/sockjs`,
	customerSvg: LOCAL_SERVICES.customerSvg ? 'http://localhost:13009' : `https://${ENVIRONMENT.apiInfix}/customer-svg/1.0`,
	customers: LOCAL_SERVICES.customer ? 'http://localhost:13010' : `https://${ENVIRONMENT.apiInfix}/customers/1.0`,
	document: LOCAL_SERVICES.document ? 'http://localhost:13011' : `https://${ENVIRONMENT.apiInfix}/document/1.0`,
	excel: LOCAL_SERVICES.excel ? 'http://localhost:8888' : `https://${ENVIRONMENT.apiInfix}/excel/1.0`,
	releaseNotes: LOCAL_SERVICES.releaseNotes ? 'http://localhost:13778' : `https://${ENVIRONMENT.apiInfix}/release-notes/1.0`,
};
