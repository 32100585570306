/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentLinkDto } from '../models/document-link-dto';
import { exportCustomers } from '../fn/customer-overview-export-controller/export-customers';
import { ExportCustomers$Params } from '../fn/customer-overview-export-controller/export-customers';


/**
 * Customer Overview Export
 */
@Injectable({ providedIn: 'root' })
export class CustomerOverviewExportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportCustomers()` */
  static readonly ExportCustomersPath = '/customers/export';

  /**
   * Create a CSV file with the customer list export
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCustomers$Response(params: ExportCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return exportCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a CSV file with the customer list export
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCustomers(params: ExportCustomers$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.exportCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

}
