/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressDto } from '../models/address-dto';
import { AddressHistoryDto } from '../models/address-history-dto';
import { createAddress } from '../fn/address-controller/create-address';
import { CreateAddress$Params } from '../fn/address-controller/create-address';
import { deleteAddressesByCustomerId } from '../fn/address-controller/delete-addresses-by-customer-id';
import { DeleteAddressesByCustomerId$Params } from '../fn/address-controller/delete-addresses-by-customer-id';
import { getAllAddressesByCustomerId } from '../fn/address-controller/get-all-addresses-by-customer-id';
import { GetAllAddressesByCustomerId$Params } from '../fn/address-controller/get-all-addresses-by-customer-id';
import { getDefaultAddressByCustomerId } from '../fn/address-controller/get-default-address-by-customer-id';
import { GetDefaultAddressByCustomerId$Params } from '../fn/address-controller/get-default-address-by-customer-id';
import { getHistoryOfAllAddressesByCustomerId } from '../fn/address-controller/get-history-of-all-addresses-by-customer-id';
import { GetHistoryOfAllAddressesByCustomerId$Params } from '../fn/address-controller/get-history-of-all-addresses-by-customer-id';
import { getHospitalOrPoisonAddressByCustomerId } from '../fn/address-controller/get-hospital-or-poison-address-by-customer-id';
import { GetHospitalOrPoisonAddressByCustomerId$Params } from '../fn/address-controller/get-hospital-or-poison-address-by-customer-id';
import { getTransitDoctorAddressByCustomerId } from '../fn/address-controller/get-transit-doctor-address-by-customer-id';
import { GetTransitDoctorAddressByCustomerId$Params } from '../fn/address-controller/get-transit-doctor-address-by-customer-id';
import { updateAddress } from '../fn/address-controller/update-address';
import { UpdateAddress$Params } from '../fn/address-controller/update-address';


/**
 * Manages the customer addresses and address types
 */
@Injectable({ providedIn: 'root' })
export class AddressControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAddressesByCustomerId()` */
  static readonly GetAllAddressesByCustomerIdPath = '/customers/{customerId}/addresses';

  /**
   * Get all addresses of a customer by customer id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAddressesByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddressesByCustomerId$Response(params: GetAllAddressesByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressDto>>> {
    return getAllAddressesByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all addresses of a customer by customer id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAddressesByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddressesByCustomerId(params: GetAllAddressesByCustomerId$Params, context?: HttpContext): Observable<Array<AddressDto>> {
    return this.getAllAddressesByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>): Array<AddressDto> => r.body)
    );
  }

  /** Path part for operation `createAddress()` */
  static readonly CreateAddressPath = '/customers/{customerId}/addresses';

  /**
   * Create an address assigned to a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress$Response(params: CreateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return createAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an address assigned to a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress(params: CreateAddress$Params, context?: HttpContext): Observable<AddressDto> {
    return this.createAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `deleteAddressesByCustomerId()` */
  static readonly DeleteAddressesByCustomerIdPath = '/customers/{customerId}/addresses';

  /**
   * Delete addresses of a customer by customerId and addressType
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAddressesByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddressesByCustomerId$Response(params: DeleteAddressesByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAddressesByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete addresses of a customer by customerId and addressType
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAddressesByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddressesByCustomerId(params: DeleteAddressesByCustomerId$Params, context?: HttpContext): Observable<void> {
    return this.deleteAddressesByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAddress()` */
  static readonly UpdateAddressPath = '/customers/{customerId}/addresses';

  /**
   * update customer address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAddress$Response(params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return updateAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * update customer address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAddress(params: UpdateAddress$Params, context?: HttpContext): Observable<AddressDto> {
    return this.updateAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `getTransitDoctorAddressByCustomerId()` */
  static readonly GetTransitDoctorAddressByCustomerIdPath = '/customers/{customerId}/transit-doctor';

  /**
   * AdressArt 130=Durchgangsarzt will be returned if available
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransitDoctorAddressByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransitDoctorAddressByCustomerId$Response(params: GetTransitDoctorAddressByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return getTransitDoctorAddressByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * AdressArt 130=Durchgangsarzt will be returned if available
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransitDoctorAddressByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransitDoctorAddressByCustomerId(params: GetTransitDoctorAddressByCustomerId$Params, context?: HttpContext): Observable<AddressDto> {
    return this.getTransitDoctorAddressByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `getDefaultAddressByCustomerId()` */
  static readonly GetDefaultAddressByCustomerIdPath = '/customers/{customerId}/main-address';

  /**
   * AdressArt 40=Stammadresse will be returned if available
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultAddressByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultAddressByCustomerId$Response(params: GetDefaultAddressByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return getDefaultAddressByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * AdressArt 40=Stammadresse will be returned if available
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultAddressByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultAddressByCustomerId(params: GetDefaultAddressByCustomerId$Params, context?: HttpContext): Observable<AddressDto> {
    return this.getDefaultAddressByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `getHospitalOrPoisonAddressByCustomerId()` */
  static readonly GetHospitalOrPoisonAddressByCustomerIdPath = '/customers/{customerId}/hospital-poison';

  /**
   * AdressArt 131=Krankenhaus fÃ¼r ASD Kunden, 131=Giftnotruf fÃ¼r IV/freie Kunden will be returned if available
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHospitalOrPoisonAddressByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHospitalOrPoisonAddressByCustomerId$Response(params: GetHospitalOrPoisonAddressByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return getHospitalOrPoisonAddressByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * AdressArt 131=Krankenhaus fÃ¼r ASD Kunden, 131=Giftnotruf fÃ¼r IV/freie Kunden will be returned if available
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHospitalOrPoisonAddressByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHospitalOrPoisonAddressByCustomerId(params: GetHospitalOrPoisonAddressByCustomerId$Params, context?: HttpContext): Observable<AddressDto> {
    return this.getHospitalOrPoisonAddressByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `getHistoryOfAllAddressesByCustomerId()` */
  static readonly GetHistoryOfAllAddressesByCustomerIdPath = '/customers/{customerId}/addresses/history';

  /**
   * Get the history of all addresses of a customer by customer id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHistoryOfAllAddressesByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistoryOfAllAddressesByCustomerId$Response(params: GetHistoryOfAllAddressesByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressHistoryDto>>> {
    return getHistoryOfAllAddressesByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the history of all addresses of a customer by customer id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHistoryOfAllAddressesByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistoryOfAllAddressesByCustomerId(params: GetHistoryOfAllAddressesByCustomerId$Params, context?: HttpContext): Observable<Array<AddressHistoryDto>> {
    return this.getHistoryOfAllAddressesByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressHistoryDto>>): Array<AddressHistoryDto> => r.body)
    );
  }

}
