import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../../utils/general.utils';

const UNNECESSARY_HTML_PATTERN_REPLACE: { searchValue: RegExp; replaceValue: string }[] = [
	{ searchValue: /(&nbsp;)+/g, replaceValue: ' ' }, // handle only regular whitespaces
	{ searchValue: /<span>\s*<\/span>/g, replaceValue: '' }, // remove empty spans or only containing whitespaces
	{ searchValue: /<p( class="MsoNormal")*>\s*<\/p>/, replaceValue: '' }, // remove empty p's (there might be some after dropping empty spans
];

/**
 * [NOTE] legacy integration for html code in database, which should be shown in FE
 */
@Pipe({
	name: 'htmlStringNormalization',
})
export class HtmlStringNormalizationPipe implements PipeTransform {
	transform(value: string): string {
		if (isDefined(value)) {
			return this.removeUnnecessaryHtml(value).trim();
		} else {
			return '';
		}
	}

	private removeUnnecessaryHtml(value: string): string {
		let updatedValue = value;
		UNNECESSARY_HTML_PATTERN_REPLACE.forEach(
			(matchAndReplace: { searchValue: RegExp; replaceValue: string }) =>
				(updatedValue = updatedValue.replace(matchAndReplace.searchValue, matchAndReplace.replaceValue)),
		);
		return updatedValue;
	}
}
