/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseMediumCustomerDto } from '../../models/response-medium-customer-dto';

export interface GetCustomersWhereInitialLetterIsNeeded$Params {

/**
 * filter for the customers Number
 */
  customerNumber?: string;

/**
 * search for customers name
 */
  displayName?: string;

/**
 * filter for the customers postcode
 */
  postcode?: string;

/**
 * filter for the customers city
 */
  city?: string;

/**
 * range filter for the asd hour budget [from/to]
 */
  asdHourBudget?: string;

/**
 * filter for certain safety engineers(SiFa). parameter needs a list of employee ids.
 */
  safetyEngineerIds?: Array<number>;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getCustomersWhereInitialLetterIsNeeded(http: HttpClient, rootUrl: string, params?: GetCustomersWhereInitialLetterIsNeeded$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMediumCustomerDto>> {
  const rb = new RequestBuilder(rootUrl, getCustomersWhereInitialLetterIsNeeded.PATH, 'get');
  if (params) {
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('displayName', params.displayName, {});
    rb.query('postcode', params.postcode, {});
    rb.query('city', params.city, {});
    rb.query('asdHourBudget', params.asdHourBudget, {});
    rb.query('safetyEngineerIds', params.safetyEngineerIds, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseMediumCustomerDto>;
    })
  );
}

getCustomersWhereInitialLetterIsNeeded.PATH = '/customers/initial-letter';
