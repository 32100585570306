/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HazardCategoryDto } from '../../models/hazard-category-dto';

export interface GetCategorizedHazardFactors$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * The risk assessment id
 */
  riskAssessmentId: number;
}

export function getCategorizedHazardFactors(http: HttpClient, rootUrl: string, params: GetCategorizedHazardFactors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HazardCategoryDto>>> {
  const rb = new RequestBuilder(rootUrl, getCategorizedHazardFactors.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('riskAssessmentId', params.riskAssessmentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<HazardCategoryDto>>;
    })
  );
}

getCategorizedHazardFactors.PATH = '/customers/{customerId}/risk-assessment/{riskAssessmentId}/categorized-hazard-factors';
