/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllTradeAuthorityOffices } from '../fn/trade-authority-office-controller/get-all-trade-authority-offices';
import { GetAllTradeAuthorityOffices$Params } from '../fn/trade-authority-office-controller/get-all-trade-authority-offices';
import { getTradeAuthorityOffice } from '../fn/trade-authority-office-controller/get-trade-authority-office';
import { GetTradeAuthorityOffice$Params } from '../fn/trade-authority-office-controller/get-trade-authority-office';
import { TradeAuthorityOfficeDto } from '../models/trade-authority-office-dto';
import { updateTradeAuthorityOffice } from '../fn/trade-authority-office-controller/update-trade-authority-office';
import { UpdateTradeAuthorityOffice$Params } from '../fn/trade-authority-office-controller/update-trade-authority-office';


/**
 * Trade Authority Offices
 */
@Injectable({ providedIn: 'root' })
export class TradeAuthorityOfficeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTradeAuthorityOffice()` */
  static readonly GetTradeAuthorityOfficePath = '/customers/{customerId}/trade-authority-office';

  /**
   * Get the trade authority office for a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTradeAuthorityOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradeAuthorityOffice$Response(params: GetTradeAuthorityOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<TradeAuthorityOfficeDto>> {
    return getTradeAuthorityOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the trade authority office for a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTradeAuthorityOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTradeAuthorityOffice(params: GetTradeAuthorityOffice$Params, context?: HttpContext): Observable<TradeAuthorityOfficeDto> {
    return this.getTradeAuthorityOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<TradeAuthorityOfficeDto>): TradeAuthorityOfficeDto => r.body)
    );
  }

  /** Path part for operation `updateTradeAuthorityOffice()` */
  static readonly UpdateTradeAuthorityOfficePath = '/customers/{customerId}/trade-authority-office';

  /**
   * Get the trade authority office for a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTradeAuthorityOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTradeAuthorityOffice$Response(params: UpdateTradeAuthorityOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<TradeAuthorityOfficeDto>> {
    return updateTradeAuthorityOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the trade authority office for a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTradeAuthorityOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTradeAuthorityOffice(params: UpdateTradeAuthorityOffice$Params, context?: HttpContext): Observable<TradeAuthorityOfficeDto> {
    return this.updateTradeAuthorityOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<TradeAuthorityOfficeDto>): TradeAuthorityOfficeDto => r.body)
    );
  }

  /** Path part for operation `getAllTradeAuthorityOffices()` */
  static readonly GetAllTradeAuthorityOfficesPath = '/trade-authority-offices';

  /**
   * Get all available trade authority offices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTradeAuthorityOffices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTradeAuthorityOffices$Response(params?: GetAllTradeAuthorityOffices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TradeAuthorityOfficeDto>>> {
    return getAllTradeAuthorityOffices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all available trade authority offices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTradeAuthorityOffices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTradeAuthorityOffices(params?: GetAllTradeAuthorityOffices$Params, context?: HttpContext): Observable<Array<TradeAuthorityOfficeDto>> {
    return this.getAllTradeAuthorityOffices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TradeAuthorityOfficeDto>>): Array<TradeAuthorityOfficeDto> => r.body)
    );
  }

}
