/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createAsdCustomersEconomicBranch } from '../fn/economic-branch-controller/create-asd-customers-economic-branch';
import { CreateAsdCustomersEconomicBranch$Params } from '../fn/economic-branch-controller/create-asd-customers-economic-branch';
import { EconomicBranchDto } from '../models/economic-branch-dto';
import { getAllEconomicBranches } from '../fn/economic-branch-controller/get-all-economic-branches';
import { GetAllEconomicBranches$Params } from '../fn/economic-branch-controller/get-all-economic-branches';
import { getAllEconomicBranchesOfOccupationalInsuranceAssociation } from '../fn/economic-branch-controller/get-all-economic-branches-of-occupational-insurance-association';
import { GetAllEconomicBranchesOfOccupationalInsuranceAssociation$Params } from '../fn/economic-branch-controller/get-all-economic-branches-of-occupational-insurance-association';
import { getCustomersEconomicBranch } from '../fn/economic-branch-controller/get-customers-economic-branch';
import { GetCustomersEconomicBranch$Params } from '../fn/economic-branch-controller/get-customers-economic-branch';


/**
 * Economic Branches
 */
@Injectable({ providedIn: 'root' })
export class EconomicBranchControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomersEconomicBranch()` */
  static readonly GetCustomersEconomicBranchPath = '/customers/{customerId}/economicBranch';

  /**
   * Get customers economic branch.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersEconomicBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersEconomicBranch$Response(params: GetCustomersEconomicBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<EconomicBranchDto>> {
    return getCustomersEconomicBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customers economic branch.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersEconomicBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersEconomicBranch(params: GetCustomersEconomicBranch$Params, context?: HttpContext): Observable<EconomicBranchDto> {
    return this.getCustomersEconomicBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<EconomicBranchDto>): EconomicBranchDto => r.body)
    );
  }

  /** Path part for operation `createAsdCustomersEconomicBranch()` */
  static readonly CreateAsdCustomersEconomicBranchPath = '/customers/{customerId}/economicBranch';

  /**
   * Create asd customers economic branch.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAsdCustomersEconomicBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAsdCustomersEconomicBranch$Response(params: CreateAsdCustomersEconomicBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<EconomicBranchDto>> {
    return createAsdCustomersEconomicBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Create asd customers economic branch.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAsdCustomersEconomicBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAsdCustomersEconomicBranch(params: CreateAsdCustomersEconomicBranch$Params, context?: HttpContext): Observable<EconomicBranchDto> {
    return this.createAsdCustomersEconomicBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<EconomicBranchDto>): EconomicBranchDto => r.body)
    );
  }

  /** Path part for operation `getAllEconomicBranchesOfOccupationalInsuranceAssociation()` */
  static readonly GetAllEconomicBranchesOfOccupationalInsuranceAssociationPath = '/occupational-insurance-associations/{id}/economicBranches';

  /**
   * Get all economic branches of an occupational insurance association
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEconomicBranchesOfOccupationalInsuranceAssociation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEconomicBranchesOfOccupationalInsuranceAssociation$Response(params: GetAllEconomicBranchesOfOccupationalInsuranceAssociation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EconomicBranchDto>>> {
    return getAllEconomicBranchesOfOccupationalInsuranceAssociation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all economic branches of an occupational insurance association
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllEconomicBranchesOfOccupationalInsuranceAssociation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEconomicBranchesOfOccupationalInsuranceAssociation(params: GetAllEconomicBranchesOfOccupationalInsuranceAssociation$Params, context?: HttpContext): Observable<Array<EconomicBranchDto>> {
    return this.getAllEconomicBranchesOfOccupationalInsuranceAssociation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EconomicBranchDto>>): Array<EconomicBranchDto> => r.body)
    );
  }

  /** Path part for operation `getAllEconomicBranches()` */
  static readonly GetAllEconomicBranchesPath = '/economicBranches';

  /**
   * Get all economic branches
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEconomicBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEconomicBranches$Response(params?: GetAllEconomicBranches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EconomicBranchDto>>> {
    return getAllEconomicBranches(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all economic branches
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllEconomicBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEconomicBranches(params?: GetAllEconomicBranches$Params, context?: HttpContext): Observable<Array<EconomicBranchDto>> {
    return this.getAllEconomicBranches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EconomicBranchDto>>): Array<EconomicBranchDto> => r.body)
    );
  }

}
