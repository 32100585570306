/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllTrainingSubjects } from '../fn/training-subject-controller/get-all-training-subjects';
import { GetAllTrainingSubjects$Params } from '../fn/training-subject-controller/get-all-training-subjects';
import { TrainingSubjectDto } from '../models/training-subject-dto';

@Injectable({ providedIn: 'root' })
export class TrainingSubjectControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTrainingSubjects()` */
  static readonly GetAllTrainingSubjectsPath = '/training-subjects';

  /**
   * get all training subjects of a standard training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTrainingSubjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTrainingSubjects$Response(params?: GetAllTrainingSubjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TrainingSubjectDto>>> {
    return getAllTrainingSubjects(this.http, this.rootUrl, params, context);
  }

  /**
   * get all training subjects of a standard training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTrainingSubjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTrainingSubjects(params?: GetAllTrainingSubjects$Params, context?: HttpContext): Observable<Array<TrainingSubjectDto>> {
    return this.getAllTrainingSubjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingSubjectDto>>): Array<TrainingSubjectDto> => r.body)
    );
  }

}
