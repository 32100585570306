/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createEmergencyAlertPlanDocx } from '../fn/emergency-alert-plan-controller/create-emergency-alert-plan-docx';
import { CreateEmergencyAlertPlanDocx$Params } from '../fn/emergency-alert-plan-controller/create-emergency-alert-plan-docx';
import { createEmergencyAlertPlanPdf } from '../fn/emergency-alert-plan-controller/create-emergency-alert-plan-pdf';
import { CreateEmergencyAlertPlanPdf$Params } from '../fn/emergency-alert-plan-controller/create-emergency-alert-plan-pdf';
import { deleteEmergencyAlertPlanData } from '../fn/emergency-alert-plan-controller/delete-emergency-alert-plan-data';
import { DeleteEmergencyAlertPlanData$Params } from '../fn/emergency-alert-plan-controller/delete-emergency-alert-plan-data';
import { DocumentLinkDto } from '../models/document-link-dto';
import { EmergencyAlertPlanDto } from '../models/emergency-alert-plan-dto';
import { getEmergencyAlertPlanData } from '../fn/emergency-alert-plan-controller/get-emergency-alert-plan-data';
import { GetEmergencyAlertPlanData$Params } from '../fn/emergency-alert-plan-controller/get-emergency-alert-plan-data';
import { updateEmergencyAlertPlanData } from '../fn/emergency-alert-plan-controller/update-emergency-alert-plan-data';
import { UpdateEmergencyAlertPlanData$Params } from '../fn/emergency-alert-plan-controller/update-emergency-alert-plan-data';


/**
 * Manages the emergency and alarm plan document and data
 */
@Injectable({ providedIn: 'root' })
export class EmergencyAlertPlanControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getEmergencyAlertPlanData()` */
  static readonly GetEmergencyAlertPlanDataPath = '/customers/{customerId}/emergency-alarm-plan';

  /**
   * Returns the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmergencyAlertPlanData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmergencyAlertPlanData$Response(params: GetEmergencyAlertPlanData$Params, context?: HttpContext): Observable<StrictHttpResponse<EmergencyAlertPlanDto>> {
    return getEmergencyAlertPlanData(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmergencyAlertPlanData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmergencyAlertPlanData(params: GetEmergencyAlertPlanData$Params, context?: HttpContext): Observable<EmergencyAlertPlanDto> {
    return this.getEmergencyAlertPlanData$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmergencyAlertPlanDto>): EmergencyAlertPlanDto => r.body)
    );
  }

  /** Path part for operation `updateEmergencyAlertPlanData()` */
  static readonly UpdateEmergencyAlertPlanDataPath = '/customers/{customerId}/emergency-alarm-plan';

  /**
   * Updates the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmergencyAlertPlanData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmergencyAlertPlanData$Response(params: UpdateEmergencyAlertPlanData$Params, context?: HttpContext): Observable<StrictHttpResponse<EmergencyAlertPlanDto>> {
    return updateEmergencyAlertPlanData(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEmergencyAlertPlanData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmergencyAlertPlanData(params: UpdateEmergencyAlertPlanData$Params, context?: HttpContext): Observable<EmergencyAlertPlanDto> {
    return this.updateEmergencyAlertPlanData$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmergencyAlertPlanDto>): EmergencyAlertPlanDto => r.body)
    );
  }

  /** Path part for operation `deleteEmergencyAlertPlanData()` */
  static readonly DeleteEmergencyAlertPlanDataPath = '/customers/{customerId}/emergency-alarm-plan';

  /**
   * Deletes the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmergencyAlertPlanData()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmergencyAlertPlanData$Response(params: DeleteEmergencyAlertPlanData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEmergencyAlertPlanData(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes the emergency and alarm plan data. This has nothing to do with the automatically drawn data of the document generation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEmergencyAlertPlanData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmergencyAlertPlanData(params: DeleteEmergencyAlertPlanData$Params, context?: HttpContext): Observable<void> {
    return this.deleteEmergencyAlertPlanData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createEmergencyAlertPlanPdf()` */
  static readonly CreateEmergencyAlertPlanPdfPath = '/customers/{customerId}/emergency-alarm-plan/pdf';

  /**
   * Creates the emergency and alarm plan document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmergencyAlertPlanPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  createEmergencyAlertPlanPdf$Response(params: CreateEmergencyAlertPlanPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return createEmergencyAlertPlanPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates the emergency and alarm plan document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEmergencyAlertPlanPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createEmergencyAlertPlanPdf(params: CreateEmergencyAlertPlanPdf$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.createEmergencyAlertPlanPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `createEmergencyAlertPlanDocx()` */
  static readonly CreateEmergencyAlertPlanDocxPath = '/customers/{customerId}/emergency-alarm-plan/docx';

  /**
   * Creates the emergency and alarm plan document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmergencyAlertPlanDocx()` instead.
   *
   * This method doesn't expect any request body.
   */
  createEmergencyAlertPlanDocx$Response(params: CreateEmergencyAlertPlanDocx$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return createEmergencyAlertPlanDocx(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates the emergency and alarm plan document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEmergencyAlertPlanDocx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createEmergencyAlertPlanDocx(params: CreateEmergencyAlertPlanDocx$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.createEmergencyAlertPlanDocx$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

}
