/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityDto } from '../models/activity-dto';
import { assignActivityToLevels } from '../fn/activities-controller/assign-activity-to-levels';
import { AssignActivityToLevels$Params } from '../fn/activities-controller/assign-activity-to-levels';
import { deleteActivityFromLevels } from '../fn/activities-controller/delete-activity-from-levels';
import { DeleteActivityFromLevels$Params } from '../fn/activities-controller/delete-activity-from-levels';
import { getLevel } from '../fn/activities-controller/get-level';
import { GetLevel$Params } from '../fn/activities-controller/get-level';
import { getLevels } from '../fn/activities-controller/get-levels';
import { GetLevels$Params } from '../fn/activities-controller/get-levels';
import { LevelDto } from '../models/level-dto';
import { LevelsDto } from '../models/levels-dto';
import { listActivities } from '../fn/activities-controller/list-activities';
import { ListActivities$Params } from '../fn/activities-controller/list-activities';
import { listActivitiesOfLevel } from '../fn/activities-controller/list-activities-of-level';
import { ListActivitiesOfLevel$Params } from '../fn/activities-controller/list-activities-of-level';

@Injectable({ providedIn: 'root' })
export class ActivitiesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assignActivityToLevels()` */
  static readonly AssignActivityToLevelsPath = '/activities/assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignActivityToLevels()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignActivityToLevels$Response(params: AssignActivityToLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<LevelsDto>> {
    return assignActivityToLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignActivityToLevels$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignActivityToLevels(params: AssignActivityToLevels$Params, context?: HttpContext): Observable<LevelsDto> {
    return this.assignActivityToLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<LevelsDto>): LevelsDto => r.body)
    );
  }

  /** Path part for operation `listActivities()` */
  static readonly ListActivitiesPath = '/activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  listActivities$Response(params?: ListActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityDto>>> {
    return listActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listActivities(params?: ListActivities$Params, context?: HttpContext): Observable<Array<ActivityDto>> {
    return this.listActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityDto>>): Array<ActivityDto> => r.body)
    );
  }

  /** Path part for operation `deleteActivityFromLevels()` */
  static readonly DeleteActivityFromLevelsPath = '/activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteActivityFromLevels()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteActivityFromLevels$Response(params: DeleteActivityFromLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<LevelsDto>> {
    return deleteActivityFromLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteActivityFromLevels$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteActivityFromLevels(params: DeleteActivityFromLevels$Params, context?: HttpContext): Observable<LevelsDto> {
    return this.deleteActivityFromLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<LevelsDto>): LevelsDto => r.body)
    );
  }

  /** Path part for operation `listActivitiesOfLevel()` */
  static readonly ListActivitiesOfLevelPath = '/activities/{level}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listActivitiesOfLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  listActivitiesOfLevel$Response(params: ListActivitiesOfLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityDto>>> {
    return listActivitiesOfLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listActivitiesOfLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listActivitiesOfLevel(params: ListActivitiesOfLevel$Params, context?: HttpContext): Observable<Array<ActivityDto>> {
    return this.listActivitiesOfLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityDto>>): Array<ActivityDto> => r.body)
    );
  }

  /** Path part for operation `getLevels()` */
  static readonly GetLevelsPath = '/activities/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLevels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLevels$Response(params?: GetLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<LevelsDto>> {
    return getLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLevels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLevels(params?: GetLevels$Params, context?: HttpContext): Observable<LevelsDto> {
    return this.getLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<LevelsDto>): LevelsDto => r.body)
    );
  }

  /** Path part for operation `getLevel()` */
  static readonly GetLevelPath = '/activities/levels/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLevel$Response(params: GetLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<LevelDto>> {
    return getLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLevel(params: GetLevel$Params, context?: HttpContext): Observable<LevelDto> {
    return this.getLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<LevelDto>): LevelDto => r.body)
    );
  }

}
