/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceDto } from '../models/billable-service-dto';
import { createFireSafetyBillableServices } from '../fn/fire-safety-training-controller/create-fire-safety-billable-services';
import { CreateFireSafetyBillableServices$Params } from '../fn/fire-safety-training-controller/create-fire-safety-billable-services';
import { createFireSafetyTraining } from '../fn/fire-safety-training-controller/create-fire-safety-training';
import { CreateFireSafetyTraining$Params } from '../fn/fire-safety-training-controller/create-fire-safety-training';
import { deleteFireSafetyTraining } from '../fn/fire-safety-training-controller/delete-fire-safety-training';
import { DeleteFireSafetyTraining$Params } from '../fn/fire-safety-training-controller/delete-fire-safety-training';
import { DocumentLinkDto } from '../models/document-link-dto';
import { FireSafetyTrainingDto } from '../models/fire-safety-training-dto';
import { getAllFireSafetyTrainings } from '../fn/fire-safety-training-controller/get-all-fire-safety-trainings';
import { GetAllFireSafetyTrainings$Params } from '../fn/fire-safety-training-controller/get-all-fire-safety-trainings';
import { getFireSafetyAttendanceCertificate } from '../fn/fire-safety-training-controller/get-fire-safety-attendance-certificate';
import { GetFireSafetyAttendanceCertificate$Params } from '../fn/fire-safety-training-controller/get-fire-safety-attendance-certificate';
import { getFireSafetyAttendanceCertificates } from '../fn/fire-safety-training-controller/get-fire-safety-attendance-certificates';
import { GetFireSafetyAttendanceCertificates$Params } from '../fn/fire-safety-training-controller/get-fire-safety-attendance-certificates';
import { getOneFireSafetyTrainingById } from '../fn/fire-safety-training-controller/get-one-fire-safety-training-by-id';
import { GetOneFireSafetyTrainingById$Params } from '../fn/fire-safety-training-controller/get-one-fire-safety-training-by-id';
import { ResponseFireSafetyTrainingDto } from '../models/response-fire-safety-training-dto';
import { updateFireSafetyTraining } from '../fn/fire-safety-training-controller/update-fire-safety-training';
import { UpdateFireSafetyTraining$Params } from '../fn/fire-safety-training-controller/update-fire-safety-training';

@Injectable({ providedIn: 'root' })
export class FireSafetyTrainingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOneFireSafetyTrainingById()` */
  static readonly GetOneFireSafetyTrainingByIdPath = '/fire-safety-training/{id}';

  /**
   * get a fire safety training by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneFireSafetyTrainingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFireSafetyTrainingById$Response(params: GetOneFireSafetyTrainingById$Params, context?: HttpContext): Observable<StrictHttpResponse<FireSafetyTrainingDto>> {
    return getOneFireSafetyTrainingById(this.http, this.rootUrl, params, context);
  }

  /**
   * get a fire safety training by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneFireSafetyTrainingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFireSafetyTrainingById(params: GetOneFireSafetyTrainingById$Params, context?: HttpContext): Observable<FireSafetyTrainingDto> {
    return this.getOneFireSafetyTrainingById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FireSafetyTrainingDto>): FireSafetyTrainingDto => r.body)
    );
  }

  /** Path part for operation `updateFireSafetyTraining()` */
  static readonly UpdateFireSafetyTrainingPath = '/fire-safety-training/{id}';

  /**
   * update an existing fire safety training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFireSafetyTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFireSafetyTraining$Response(params: UpdateFireSafetyTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<FireSafetyTrainingDto>> {
    return updateFireSafetyTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * update an existing fire safety training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFireSafetyTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFireSafetyTraining(params: UpdateFireSafetyTraining$Params, context?: HttpContext): Observable<FireSafetyTrainingDto> {
    return this.updateFireSafetyTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<FireSafetyTrainingDto>): FireSafetyTrainingDto => r.body)
    );
  }

  /** Path part for operation `deleteFireSafetyTraining()` */
  static readonly DeleteFireSafetyTrainingPath = '/fire-safety-training/{id}';

  /**
   * delete an existing fire safety training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFireSafetyTraining()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFireSafetyTraining$Response(params: DeleteFireSafetyTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteFireSafetyTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * delete an existing fire safety training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFireSafetyTraining$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFireSafetyTraining(params: DeleteFireSafetyTraining$Params, context?: HttpContext): Observable<void> {
    return this.deleteFireSafetyTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllFireSafetyTrainings()` */
  static readonly GetAllFireSafetyTrainingsPath = '/fire-safety-training';

  /**
   * get all fire safety trainings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFireSafetyTrainings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFireSafetyTrainings$Response(params: GetAllFireSafetyTrainings$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseFireSafetyTrainingDto>> {
    return getAllFireSafetyTrainings(this.http, this.rootUrl, params, context);
  }

  /**
   * get all fire safety trainings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllFireSafetyTrainings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFireSafetyTrainings(params: GetAllFireSafetyTrainings$Params, context?: HttpContext): Observable<ResponseFireSafetyTrainingDto> {
    return this.getAllFireSafetyTrainings$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseFireSafetyTrainingDto>): ResponseFireSafetyTrainingDto => r.body)
    );
  }

  /** Path part for operation `createFireSafetyTraining()` */
  static readonly CreateFireSafetyTrainingPath = '/fire-safety-training';

  /**
   * create a new fire safety training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFireSafetyTraining()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFireSafetyTraining$Response(params: CreateFireSafetyTraining$Params, context?: HttpContext): Observable<StrictHttpResponse<FireSafetyTrainingDto>> {
    return createFireSafetyTraining(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new fire safety training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFireSafetyTraining$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFireSafetyTraining(params: CreateFireSafetyTraining$Params, context?: HttpContext): Observable<FireSafetyTrainingDto> {
    return this.createFireSafetyTraining$Response(params, context).pipe(
      map((r: StrictHttpResponse<FireSafetyTrainingDto>): FireSafetyTrainingDto => r.body)
    );
  }

  /** Path part for operation `createFireSafetyBillableServices()` */
  static readonly CreateFireSafetyBillableServicesPath = '/fire-safety-training/{id}/billable-services';

  /**
   * create all billable services for this training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFireSafetyBillableServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  createFireSafetyBillableServices$Response(params: CreateFireSafetyBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceDto>>> {
    return createFireSafetyBillableServices(this.http, this.rootUrl, params, context);
  }

  /**
   * create all billable services for this training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFireSafetyBillableServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createFireSafetyBillableServices(params: CreateFireSafetyBillableServices$Params, context?: HttpContext): Observable<Array<BillableServiceDto>> {
    return this.createFireSafetyBillableServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceDto>>): Array<BillableServiceDto> => r.body)
    );
  }

  /** Path part for operation `getFireSafetyAttendanceCertificates()` */
  static readonly GetFireSafetyAttendanceCertificatesPath = '/fire-safety-training/{id}/attendance-certificates';

  /**
   * get pdf doc with all participants attendance certificates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFireSafetyAttendanceCertificates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFireSafetyAttendanceCertificates$Response(params: GetFireSafetyAttendanceCertificates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentLinkDto>>> {
    return getFireSafetyAttendanceCertificates(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with all participants attendance certificates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFireSafetyAttendanceCertificates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFireSafetyAttendanceCertificates(params: GetFireSafetyAttendanceCertificates$Params, context?: HttpContext): Observable<Array<DocumentLinkDto>> {
    return this.getFireSafetyAttendanceCertificates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentLinkDto>>): Array<DocumentLinkDto> => r.body)
    );
  }

  /** Path part for operation `getFireSafetyAttendanceCertificate()` */
  static readonly GetFireSafetyAttendanceCertificatePath = '/fire-safety-training/{id}/attendance-certificate/{participantId}';

  /**
   * get pdf doc with one participant attendance certificates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFireSafetyAttendanceCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFireSafetyAttendanceCertificate$Response(params: GetFireSafetyAttendanceCertificate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getFireSafetyAttendanceCertificate(this.http, this.rootUrl, params, context);
  }

  /**
   * get pdf doc with one participant attendance certificates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFireSafetyAttendanceCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFireSafetyAttendanceCertificate(params: GetFireSafetyAttendanceCertificate$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getFireSafetyAttendanceCertificate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

}
