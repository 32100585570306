/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ContactPersonDto } from '../../models/contact-person-dto';

export interface GetContactPersonById$Params {

/**
 * contactPersonId
 */
  id: number;
}

export function getContactPersonById(http: HttpClient, rootUrl: string, params: GetContactPersonById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactPersonDto>> {
  const rb = new RequestBuilder(rootUrl, getContactPersonById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ContactPersonDto>;
    })
  );
}

getContactPersonById.PATH = '/contact-persons/{id}';
