/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllSvgInfoAdmin } from '../fn/svg-info-admin-controller/get-all-svg-info-admin';
import { GetAllSvgInfoAdmin$Params } from '../fn/svg-info-admin-controller/get-all-svg-info-admin';
import { getPaginatedSvgInfos } from '../fn/svg-info-admin-controller/get-paginated-svg-infos';
import { GetPaginatedSvgInfos$Params } from '../fn/svg-info-admin-controller/get-paginated-svg-infos';
import { getSvgInfoAdmin } from '../fn/svg-info-admin-controller/get-svg-info-admin';
import { GetSvgInfoAdmin$Params } from '../fn/svg-info-admin-controller/get-svg-info-admin';
import { ResponseSvgInfoAdminDto } from '../models/response-svg-info-admin-dto';
import { SvgInfoAdminDto } from '../models/svg-info-admin-dto';
import { updateSvgInfoAdmin } from '../fn/svg-info-admin-controller/update-svg-info-admin';
import { UpdateSvgInfoAdmin$Params } from '../fn/svg-info-admin-controller/update-svg-info-admin';


/**
 * Manages SVG infos (master-data of service providers)
 */
@Injectable({ providedIn: 'root' })
export class SvgInfoAdminControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSvgInfoAdmin()` */
  static readonly GetSvgInfoAdminPath = '/svg-info-admin/{id}';

  /**
   * Get svg info.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgInfoAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgInfoAdmin$Response(params: GetSvgInfoAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgInfoAdminDto>> {
    return getSvgInfoAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get svg info.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgInfoAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgInfoAdmin(params: GetSvgInfoAdmin$Params, context?: HttpContext): Observable<SvgInfoAdminDto> {
    return this.getSvgInfoAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgInfoAdminDto>): SvgInfoAdminDto => r.body)
    );
  }

  /** Path part for operation `updateSvgInfoAdmin()` */
  static readonly UpdateSvgInfoAdminPath = '/svg-info-admin/{id}';

  /**
   * Update svg info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSvgInfoAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSvgInfoAdmin$Response(params: UpdateSvgInfoAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgInfoAdminDto>> {
    return updateSvgInfoAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * Update svg info
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSvgInfoAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSvgInfoAdmin(params: UpdateSvgInfoAdmin$Params, context?: HttpContext): Observable<SvgInfoAdminDto> {
    return this.updateSvgInfoAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgInfoAdminDto>): SvgInfoAdminDto => r.body)
    );
  }

  /** Path part for operation `getAllSvgInfoAdmin()` */
  static readonly GetAllSvgInfoAdminPath = '/svg-info-admin';

  /**
   * Get all svg infos.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSvgInfoAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgInfoAdmin$Response(params?: GetAllSvgInfoAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SvgInfoAdminDto>>> {
    return getAllSvgInfoAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all svg infos.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSvgInfoAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgInfoAdmin(params?: GetAllSvgInfoAdmin$Params, context?: HttpContext): Observable<Array<SvgInfoAdminDto>> {
    return this.getAllSvgInfoAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SvgInfoAdminDto>>): Array<SvgInfoAdminDto> => r.body)
    );
  }

  /** Path part for operation `getPaginatedSvgInfos()` */
  static readonly GetPaginatedSvgInfosPath = '/svg-info-admin/search';

  /**
   * returns paginated svg infos.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaginatedSvgInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedSvgInfos$Response(params?: GetPaginatedSvgInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgInfoAdminDto>> {
    return getPaginatedSvgInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * returns paginated svg infos.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaginatedSvgInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaginatedSvgInfos(params?: GetPaginatedSvgInfos$Params, context?: HttpContext): Observable<ResponseSvgInfoAdminDto> {
    return this.getPaginatedSvgInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgInfoAdminDto>): ResponseSvgInfoAdminDto => r.body)
    );
  }

}
