/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMeasure } from '../fn/measures-controller/create-measure';
import { CreateMeasure$Params } from '../fn/measures-controller/create-measure';
import { deleteMeasure } from '../fn/measures-controller/delete-measure';
import { DeleteMeasure$Params } from '../fn/measures-controller/delete-measure';
import { getMeasures } from '../fn/measures-controller/get-measures';
import { GetMeasures$Params } from '../fn/measures-controller/get-measures';
import { MeasureDto } from '../models/measure-dto';
import { updateMeasure } from '../fn/measures-controller/update-measure';
import { UpdateMeasure$Params } from '../fn/measures-controller/update-measure';


/**
 * This controller handles all operations on measures. Multiple measures can be assigned to a hazard.
 */
@Injectable({ providedIn: 'root' })
export class MeasuresControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateMeasure()` */
  static readonly UpdateMeasurePath = '/customers/{customerId}/measures/{measureId}';

  /**
   * Update  measure for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMeasure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMeasure$Response(params: UpdateMeasure$Params, context?: HttpContext): Observable<StrictHttpResponse<MeasureDto>> {
    return updateMeasure(this.http, this.rootUrl, params, context);
  }

  /**
   * Update  measure for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMeasure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMeasure(params: UpdateMeasure$Params, context?: HttpContext): Observable<MeasureDto> {
    return this.updateMeasure$Response(params, context).pipe(
      map((r: StrictHttpResponse<MeasureDto>): MeasureDto => r.body)
    );
  }

  /** Path part for operation `deleteMeasure()` */
  static readonly DeleteMeasurePath = '/customers/{customerId}/measures/{measureId}';

  /**
   * Delete  measure for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMeasure()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMeasure$Response(params: DeleteMeasure$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMeasure(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete  measure for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMeasure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMeasure(params: DeleteMeasure$Params, context?: HttpContext): Observable<void> {
    return this.deleteMeasure$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMeasures()` */
  static readonly GetMeasuresPath = '/customers/{customerId}/measures';

  /**
   * Get all measures of a parent hazard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeasures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeasures$Response(params: GetMeasures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MeasureDto>>> {
    return getMeasures(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all measures of a parent hazard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeasures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeasures(params: GetMeasures$Params, context?: HttpContext): Observable<Array<MeasureDto>> {
    return this.getMeasures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MeasureDto>>): Array<MeasureDto> => r.body)
    );
  }

  /** Path part for operation `createMeasure()` */
  static readonly CreateMeasurePath = '/customers/{customerId}/measures';

  /**
   * Create new measure.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMeasure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMeasure$Response(params: CreateMeasure$Params, context?: HttpContext): Observable<StrictHttpResponse<MeasureDto>> {
    return createMeasure(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new measure.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMeasure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMeasure(params: CreateMeasure$Params, context?: HttpContext): Observable<MeasureDto> {
    return this.createMeasure$Response(params, context).pipe(
      map((r: StrictHttpResponse<MeasureDto>): MeasureDto => r.body)
    );
  }

}
