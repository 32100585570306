/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createHealthSafetyOrganization } from '../fn/health-safety-organization-controller/create-health-safety-organization';
import { CreateHealthSafetyOrganization$Params } from '../fn/health-safety-organization-controller/create-health-safety-organization';
import { deleteHealthSafetyOrganization } from '../fn/health-safety-organization-controller/delete-health-safety-organization';
import { DeleteHealthSafetyOrganization$Params } from '../fn/health-safety-organization-controller/delete-health-safety-organization';
import { getHealthSafetyOrganization } from '../fn/health-safety-organization-controller/get-health-safety-organization';
import { GetHealthSafetyOrganization$Params } from '../fn/health-safety-organization-controller/get-health-safety-organization';
import { HealthSafetyQuestionsAndCategoriesDto } from '../models/health-safety-questions-and-categories-dto';
import { updateHealthSafetyOrganization } from '../fn/health-safety-organization-controller/update-health-safety-organization';
import { UpdateHealthSafetyOrganization$Params } from '../fn/health-safety-organization-controller/update-health-safety-organization';


/**
 * This controller handles all operations on the questions regarding the organization of occupational health and safety (OOHS).
 */
@Injectable({ providedIn: 'root' })
export class HealthSafetyOrganizationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHealthSafetyOrganization()` */
  static readonly GetHealthSafetyOrganizationPath = '/customers/{customerId}/health-safety-organization';

  /**
   * Get all answers and summary values for the OOHS for the given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealthSafetyOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthSafetyOrganization$Response(params: GetHealthSafetyOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>> {
    return getHealthSafetyOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all answers and summary values for the OOHS for the given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHealthSafetyOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthSafetyOrganization(params: GetHealthSafetyOrganization$Params, context?: HttpContext): Observable<HealthSafetyQuestionsAndCategoriesDto> {
    return this.getHealthSafetyOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>): HealthSafetyQuestionsAndCategoriesDto => r.body)
    );
  }

  /** Path part for operation `createHealthSafetyOrganization()` */
  static readonly CreateHealthSafetyOrganizationPath = '/customers/{customerId}/health-safety-organization';

  /**
   * Create all questions and answers for the OOHS for the given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createHealthSafetyOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  createHealthSafetyOrganization$Response(params: CreateHealthSafetyOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>> {
    return createHealthSafetyOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Create all questions and answers for the OOHS for the given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createHealthSafetyOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createHealthSafetyOrganization(params: CreateHealthSafetyOrganization$Params, context?: HttpContext): Observable<HealthSafetyQuestionsAndCategoriesDto> {
    return this.createHealthSafetyOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>): HealthSafetyQuestionsAndCategoriesDto => r.body)
    );
  }

  /** Path part for operation `deleteHealthSafetyOrganization()` */
  static readonly DeleteHealthSafetyOrganizationPath = '/customers/{customerId}/health-safety-organization';

  /**
   * Remove all questions and answers for the OOHS for the given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHealthSafetyOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHealthSafetyOrganization$Response(params: DeleteHealthSafetyOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteHealthSafetyOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove all questions and answers for the OOHS for the given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteHealthSafetyOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHealthSafetyOrganization(params: DeleteHealthSafetyOrganization$Params, context?: HttpContext): Observable<void> {
    return this.deleteHealthSafetyOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateHealthSafetyOrganization()` */
  static readonly UpdateHealthSafetyOrganizationPath = '/customers/{customerId}/health-safety-organization';

  /**
   * Update given questions and answers for the OOHS for the customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHealthSafetyOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHealthSafetyOrganization$Response(params: UpdateHealthSafetyOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>> {
    return updateHealthSafetyOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Update given questions and answers for the OOHS for the customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHealthSafetyOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHealthSafetyOrganization(params: UpdateHealthSafetyOrganization$Params, context?: HttpContext): Observable<HealthSafetyQuestionsAndCategoriesDto> {
    return this.updateHealthSafetyOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthSafetyQuestionsAndCategoriesDto>): HealthSafetyQuestionsAndCategoriesDto => r.body)
    );
  }

}
