/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addMemberToTeam } from '../fn/teams-controller/add-member-to-team';
import { AddMemberToTeam$Params } from '../fn/teams-controller/add-member-to-team';
import { createTeam } from '../fn/teams-controller/create-team';
import { CreateTeam$Params } from '../fn/teams-controller/create-team';
import { deleteTeam } from '../fn/teams-controller/delete-team';
import { DeleteTeam$Params } from '../fn/teams-controller/delete-team';
import { getTeam } from '../fn/teams-controller/get-team';
import { GetTeam$Params } from '../fn/teams-controller/get-team';
import { listTeam } from '../fn/teams-controller/list-team';
import { ListTeam$Params } from '../fn/teams-controller/list-team';
import { removeMemberFromTeam } from '../fn/teams-controller/remove-member-from-team';
import { RemoveMemberFromTeam$Params } from '../fn/teams-controller/remove-member-from-team';
import { TeamDto } from '../models/team-dto';

@Injectable({ providedIn: 'root' })
export class TeamsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listTeam()` */
  static readonly ListTeamPath = '/teams';

  /**
   * Get a list of teams
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTeam$Response(params?: ListTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TeamDto>>> {
    return listTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of teams
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTeam(params?: ListTeam$Params, context?: HttpContext): Observable<Array<TeamDto>> {
    return this.listTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TeamDto>>): Array<TeamDto> => r.body)
    );
  }

  /** Path part for operation `createTeam()` */
  static readonly CreateTeamPath = '/teams';

  /**
   * Create team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTeam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTeam$Response(params: CreateTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamDto>> {
    return createTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Create team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTeam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTeam(params: CreateTeam$Params, context?: HttpContext): Observable<TeamDto> {
    return this.createTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamDto>): TeamDto => r.body)
    );
  }

  /** Path part for operation `addMemberToTeam()` */
  static readonly AddMemberToTeamPath = '/teams/{id}/members';

  /**
   * Add member to team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMemberToTeam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMemberToTeam$Response(params: AddMemberToTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamDto>> {
    return addMemberToTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Add member to team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMemberToTeam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMemberToTeam(params: AddMemberToTeam$Params, context?: HttpContext): Observable<TeamDto> {
    return this.addMemberToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamDto>): TeamDto => r.body)
    );
  }

  /** Path part for operation `getTeam()` */
  static readonly GetTeamPath = '/teams/{id}';

  /**
   * Get one team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeam$Response(params: GetTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamDto>> {
    return getTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Get one team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeam(params: GetTeam$Params, context?: HttpContext): Observable<TeamDto> {
    return this.getTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamDto>): TeamDto => r.body)
    );
  }

  /** Path part for operation `deleteTeam()` */
  static readonly DeleteTeamPath = '/teams/{id}';

  /**
   * Delete team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeam$Response(params: DeleteTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeam(params: DeleteTeam$Params, context?: HttpContext): Observable<void> {
    return this.deleteTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeMemberFromTeam()` */
  static readonly RemoveMemberFromTeamPath = '/teams/{id}/members/{userId}';

  /**
   * remove member from team
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMemberFromTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMemberFromTeam$Response(params: RemoveMemberFromTeam$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamDto>> {
    return removeMemberFromTeam(this.http, this.rootUrl, params, context);
  }

  /**
   * remove member from team
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeMemberFromTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMemberFromTeam(params: RemoveMemberFromTeam$Params, context?: HttpContext): Observable<TeamDto> {
    return this.removeMemberFromTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamDto>): TeamDto => r.body)
    );
  }

}
