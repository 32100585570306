/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSecurityExpertsWorkingHoursForSpecificDay } from '../fn/working-hours-controller/get-security-experts-working-hours-for-specific-day';
import { GetSecurityExpertsWorkingHoursForSpecificDay$Params } from '../fn/working-hours-controller/get-security-experts-working-hours-for-specific-day';

@Injectable({ providedIn: 'root' })
export class WorkingHoursControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSecurityExpertsWorkingHoursForSpecificDay()` */
  static readonly GetSecurityExpertsWorkingHoursForSpecificDayPath = '/security-experts/{id}/hours';

  /**
   * Get working hours a security expert already booked for a specific day. This includes ASD services only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecurityExpertsWorkingHoursForSpecificDay()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpertsWorkingHoursForSpecificDay$Response(params: GetSecurityExpertsWorkingHoursForSpecificDay$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getSecurityExpertsWorkingHoursForSpecificDay(this.http, this.rootUrl, params, context);
  }

  /**
   * Get working hours a security expert already booked for a specific day. This includes ASD services only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecurityExpertsWorkingHoursForSpecificDay$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpertsWorkingHoursForSpecificDay(params: GetSecurityExpertsWorkingHoursForSpecificDay$Params, context?: HttpContext): Observable<number> {
    return this.getSecurityExpertsWorkingHoursForSpecificDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
