/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceTypeDto } from '../models/billable-service-type-dto';
import { createRepeatingAttendance } from '../fn/repeating-attendances-controller/create-repeating-attendance';
import { CreateRepeatingAttendance$Params } from '../fn/repeating-attendances-controller/create-repeating-attendance';
import { deleteRepeatingAttendance } from '../fn/repeating-attendances-controller/delete-repeating-attendance';
import { DeleteRepeatingAttendance$Params } from '../fn/repeating-attendances-controller/delete-repeating-attendance';
import { getAllRepeatingAttendancesByCustomerIdAndContractId } from '../fn/repeating-attendances-controller/get-all-repeating-attendances-by-customer-id-and-contract-id';
import { GetAllRepeatingAttendancesByCustomerIdAndContractId$Params } from '../fn/repeating-attendances-controller/get-all-repeating-attendances-by-customer-id-and-contract-id';
import { getAllRepeatingAttendanceTypes } from '../fn/repeating-attendances-controller/get-all-repeating-attendance-types';
import { GetAllRepeatingAttendanceTypes$Params } from '../fn/repeating-attendances-controller/get-all-repeating-attendance-types';
import { getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId } from '../fn/repeating-attendances-controller/get-available-repeating-attendance-types-by-customer-id-and-contract-type-id';
import { GetAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Params } from '../fn/repeating-attendances-controller/get-available-repeating-attendance-types-by-customer-id-and-contract-type-id';
import { RepeatingAttendanceDto } from '../models/repeating-attendance-dto';
import { updateRepeatingAttendance } from '../fn/repeating-attendances-controller/update-repeating-attendance';
import { UpdateRepeatingAttendance$Params } from '../fn/repeating-attendances-controller/update-repeating-attendance';


/**
 * manage the repeating attendances
 */
@Injectable({ providedIn: 'root' })
export class RepeatingAttendancesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateRepeatingAttendance()` */
  static readonly UpdateRepeatingAttendancePath = '/customers/{customerId}/contracts/{contractId}/repeatingAttendances/{repeatingAttendanceId}';

  /**
   * Update a repeating attendances of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRepeatingAttendance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRepeatingAttendance$Response(params: UpdateRepeatingAttendance$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatingAttendanceDto>> {
    return updateRepeatingAttendance(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a repeating attendances of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRepeatingAttendance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRepeatingAttendance(params: UpdateRepeatingAttendance$Params, context?: HttpContext): Observable<RepeatingAttendanceDto> {
    return this.updateRepeatingAttendance$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatingAttendanceDto>): RepeatingAttendanceDto => r.body)
    );
  }

  /** Path part for operation `getAllRepeatingAttendancesByCustomerIdAndContractId()` */
  static readonly GetAllRepeatingAttendancesByCustomerIdAndContractIdPath = '/customers/{customerId}/contracts/{contractId}/repeatingAttendances';

  /**
   * Get all repeating attendances of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRepeatingAttendancesByCustomerIdAndContractId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRepeatingAttendancesByCustomerIdAndContractId$Response(params: GetAllRepeatingAttendancesByCustomerIdAndContractId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RepeatingAttendanceDto>>> {
    return getAllRepeatingAttendancesByCustomerIdAndContractId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all repeating attendances of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllRepeatingAttendancesByCustomerIdAndContractId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRepeatingAttendancesByCustomerIdAndContractId(params: GetAllRepeatingAttendancesByCustomerIdAndContractId$Params, context?: HttpContext): Observable<Array<RepeatingAttendanceDto>> {
    return this.getAllRepeatingAttendancesByCustomerIdAndContractId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RepeatingAttendanceDto>>): Array<RepeatingAttendanceDto> => r.body)
    );
  }

  /** Path part for operation `createRepeatingAttendance()` */
  static readonly CreateRepeatingAttendancePath = '/customers/{customerId}/contracts/{contractId}/repeatingAttendances';

  /**
   * Create a repeating attendances of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRepeatingAttendance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRepeatingAttendance$Response(params: CreateRepeatingAttendance$Params, context?: HttpContext): Observable<StrictHttpResponse<RepeatingAttendanceDto>> {
    return createRepeatingAttendance(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a repeating attendances of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRepeatingAttendance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRepeatingAttendance(params: CreateRepeatingAttendance$Params, context?: HttpContext): Observable<RepeatingAttendanceDto> {
    return this.createRepeatingAttendance$Response(params, context).pipe(
      map((r: StrictHttpResponse<RepeatingAttendanceDto>): RepeatingAttendanceDto => r.body)
    );
  }

  /** Path part for operation `getAllRepeatingAttendanceTypes()` */
  static readonly GetAllRepeatingAttendanceTypesPath = '/repeatingAttendances/types';

  /**
   * Get all repeating attendance types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRepeatingAttendanceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRepeatingAttendanceTypes$Response(params?: GetAllRepeatingAttendanceTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceTypeDto>>> {
    return getAllRepeatingAttendanceTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all repeating attendance types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllRepeatingAttendanceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRepeatingAttendanceTypes(params?: GetAllRepeatingAttendanceTypes$Params, context?: HttpContext): Observable<Array<BillableServiceTypeDto>> {
    return this.getAllRepeatingAttendanceTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceTypeDto>>): Array<BillableServiceTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId()` */
  static readonly GetAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeIdPath = '/repeatingAttendances/available-types';

  /**
   * Get available repeating attendance types for the customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Response(params: GetAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceTypeDto>>> {
    return getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available repeating attendance types for the customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId(params: GetAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Params, context?: HttpContext): Observable<Array<BillableServiceTypeDto>> {
    return this.getAvailableRepeatingAttendanceTypesByCustomerIdAndContractTypeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceTypeDto>>): Array<BillableServiceTypeDto> => r.body)
    );
  }

  /** Path part for operation `deleteRepeatingAttendance()` */
  static readonly DeleteRepeatingAttendancePath = '/customers/{customerId}/repeatingAttendances/{repeatingAttendanceId}';

  /**
   * Delete a repeating attendances of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRepeatingAttendance()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRepeatingAttendance$Response(params: DeleteRepeatingAttendance$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRepeatingAttendance(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a repeating attendances of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRepeatingAttendance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRepeatingAttendance(params: DeleteRepeatingAttendance$Params, context?: HttpContext): Observable<void> {
    return this.deleteRepeatingAttendance$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
