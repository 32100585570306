/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createScopeOfCare } from '../fn/scope-of-care-controller/create-scope-of-care';
import { CreateScopeOfCare$Params } from '../fn/scope-of-care-controller/create-scope-of-care';
import { getCustomerScopesOfCare } from '../fn/scope-of-care-controller/get-customer-scopes-of-care';
import { GetCustomerScopesOfCare$Params } from '../fn/scope-of-care-controller/get-customer-scopes-of-care';
import { ScopeOfCareDto } from '../models/scope-of-care-dto';
import { updateScopeOfCare } from '../fn/scope-of-care-controller/update-scope-of-care';
import { UpdateScopeOfCare$Params } from '../fn/scope-of-care-controller/update-scope-of-care';


/**
 * Manages the scope of care assigned to a customer to handle risk assessment services
 */
@Injectable({ providedIn: 'root' })
export class ScopeOfCareControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateScopeOfCare()` */
  static readonly UpdateScopeOfCarePath = '/scopes-of-care/{id}';

  /**
   * Updates a scope of care entity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScopeOfCare()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScopeOfCare$Response(params: UpdateScopeOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<ScopeOfCareDto>> {
    return updateScopeOfCare(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a scope of care entity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateScopeOfCare$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScopeOfCare(params: UpdateScopeOfCare$Params, context?: HttpContext): Observable<ScopeOfCareDto> {
    return this.updateScopeOfCare$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScopeOfCareDto>): ScopeOfCareDto => r.body)
    );
  }

  /** Path part for operation `createScopeOfCare()` */
  static readonly CreateScopeOfCarePath = '/scopes-of-care';

  /**
   * Creates a scope of care entity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createScopeOfCare()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScopeOfCare$Response(params: CreateScopeOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<ScopeOfCareDto>> {
    return createScopeOfCare(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a scope of care entity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createScopeOfCare$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScopeOfCare(params: CreateScopeOfCare$Params, context?: HttpContext): Observable<ScopeOfCareDto> {
    return this.createScopeOfCare$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScopeOfCareDto>): ScopeOfCareDto => r.body)
    );
  }

  /** Path part for operation `getCustomerScopesOfCare()` */
  static readonly GetCustomerScopesOfCarePath = '/customers/{customerId}/scopes-of-care';

  /**
   * Get all scopes of care for given customerId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerScopesOfCare()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerScopesOfCare$Response(params: GetCustomerScopesOfCare$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ScopeOfCareDto>>> {
    return getCustomerScopesOfCare(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all scopes of care for given customerId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerScopesOfCare$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerScopesOfCare(params: GetCustomerScopesOfCare$Params, context?: HttpContext): Observable<Array<ScopeOfCareDto>> {
    return this.getCustomerScopesOfCare$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ScopeOfCareDto>>): Array<ScopeOfCareDto> => r.body)
    );
  }

}
