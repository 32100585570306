/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PriceConditionDto } from '../../models/price-condition-dto';

export interface GetPriceConditions$Params {

/**
 * customerId - authorization (tenant)
 */
  customerId: number;

/**
 * contractId - to check contract type with price condition type (there are some rules, that those must match)
 */
  contractId: number;
}

export function getPriceConditions(http: HttpClient, rootUrl: string, params: GetPriceConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PriceConditionDto>>> {
  const rb = new RequestBuilder(rootUrl, getPriceConditions.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('contractId', params.contractId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PriceConditionDto>>;
    })
  );
}

getPriceConditions.PATH = '/customers/{customerId}/contracts/{contractId}/price-conditions';
