import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { isDefined } from '../../utils/general.utils';

@Pipe({ name: 'dateRestriction' })
export class DateRestrictionPipe implements PipeTransform {
	transform(value: any | undefined | null): string {
		if (isDefined(value) && value !== '') {
			return this.getDateRestriction(value);
		} else {
			return 'danger';
		}
	}

	getDateRestriction(date: any): string {
		const created = dayjs(date);
		const todayThreeYearsAgo = dayjs().subtract(3, 'year').subtract(1, 'second');
		const todayTwoYearsAgo = dayjs().subtract(2, 'year');
		let dateIndicator: string;

		if (created.isBefore(todayThreeYearsAgo)) {
			dateIndicator = 'danger';
		} else if (created.isAfter(todayThreeYearsAgo) && created.isBefore(todayTwoYearsAgo)) {
			dateIndicator = 'warning';
		} else {
			dateIndicator = 'success';
		}
		return dateIndicator;
	}
}
