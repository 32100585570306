/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomerContingentDto } from '../../models/customer-contingent-dto';

export interface GetContingentByCustomerId$Params {

/**
 * the technical id of the customer
 */
  id: number;
}

export function getContingentByCustomerId(http: HttpClient, rootUrl: string, params: GetContingentByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerContingentDto>> {
  const rb = new RequestBuilder(rootUrl, getContingentByCustomerId.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CustomerContingentDto>;
    })
  );
}

getContingentByCustomerId.PATH = '/customers/{id}/contingent';
