/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createContractDetailsOccupationalHealthcare } from '../fn/occupational-safety-and-healthcare-controller/create-contract-details-occupational-healthcare';
import { CreateContractDetailsOccupationalHealthcare$Params } from '../fn/occupational-safety-and-healthcare-controller/create-contract-details-occupational-healthcare';
import { createContractDetailsOccupationalSafety } from '../fn/occupational-safety-and-healthcare-controller/create-contract-details-occupational-safety';
import { CreateContractDetailsOccupationalSafety$Params } from '../fn/occupational-safety-and-healthcare-controller/create-contract-details-occupational-safety';
import { getContractDetailsOccupationalSafetyAndHealthcare } from '../fn/occupational-safety-and-healthcare-controller/get-contract-details-occupational-safety-and-healthcare';
import { GetContractDetailsOccupationalSafetyAndHealthcare$Params } from '../fn/occupational-safety-and-healthcare-controller/get-contract-details-occupational-safety-and-healthcare';
import { HealthcareDto } from '../models/healthcare-dto';
import { SafetyAndHealthcareDto } from '../models/safety-and-healthcare-dto';
import { SafetyDto } from '../models/safety-dto';
import { updateContractDetailsOccupationalHealthcare } from '../fn/occupational-safety-and-healthcare-controller/update-contract-details-occupational-healthcare';
import { UpdateContractDetailsOccupationalHealthcare$Params } from '../fn/occupational-safety-and-healthcare-controller/update-contract-details-occupational-healthcare';
import { updateContractDetailsOccupationalSafety } from '../fn/occupational-safety-and-healthcare-controller/update-contract-details-occupational-safety';
import { UpdateContractDetailsOccupationalSafety$Params } from '../fn/occupational-safety-and-healthcare-controller/update-contract-details-occupational-safety';


/**
 * Manage customers contract details concerning occupational safety and healthcare.
 */
@Injectable({ providedIn: 'root' })
export class OccupationalSafetyAndHealthcareControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateContractDetailsOccupationalSafety()` */
  static readonly UpdateContractDetailsOccupationalSafetyPath = '/customers/{customerId}/contracts/{contractId}/occupational-safety';

  /**
   * Update contract details concerning occupational safety.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContractDetailsOccupationalSafety()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContractDetailsOccupationalSafety$Response(params: UpdateContractDetailsOccupationalSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return updateContractDetailsOccupationalSafety(this.http, this.rootUrl, params, context);
  }

  /**
   * Update contract details concerning occupational safety.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContractDetailsOccupationalSafety$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContractDetailsOccupationalSafety(params: UpdateContractDetailsOccupationalSafety$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.updateContractDetailsOccupationalSafety$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `createContractDetailsOccupationalSafety()` */
  static readonly CreateContractDetailsOccupationalSafetyPath = '/customers/{customerId}/contracts/{contractId}/occupational-safety';

  /**
   * Create contract details concerning occupational safety.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContractDetailsOccupationalSafety()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContractDetailsOccupationalSafety$Response(params: CreateContractDetailsOccupationalSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
    return createContractDetailsOccupationalSafety(this.http, this.rootUrl, params, context);
  }

  /**
   * Create contract details concerning occupational safety.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContractDetailsOccupationalSafety$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContractDetailsOccupationalSafety(params: CreateContractDetailsOccupationalSafety$Params, context?: HttpContext): Observable<SafetyDto> {
    return this.createContractDetailsOccupationalSafety$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyDto>): SafetyDto => r.body)
    );
  }

  /** Path part for operation `updateContractDetailsOccupationalHealthcare()` */
  static readonly UpdateContractDetailsOccupationalHealthcarePath = '/customers/{customerId}/contracts/{contractId}/occupational-healthcare';

  /**
   * Update contract details concerning occupational healthcare.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContractDetailsOccupationalHealthcare()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContractDetailsOccupationalHealthcare$Response(params: UpdateContractDetailsOccupationalHealthcare$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthcareDto>> {
    return updateContractDetailsOccupationalHealthcare(this.http, this.rootUrl, params, context);
  }

  /**
   * Update contract details concerning occupational healthcare.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContractDetailsOccupationalHealthcare$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContractDetailsOccupationalHealthcare(params: UpdateContractDetailsOccupationalHealthcare$Params, context?: HttpContext): Observable<HealthcareDto> {
    return this.updateContractDetailsOccupationalHealthcare$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthcareDto>): HealthcareDto => r.body)
    );
  }

  /** Path part for operation `createContractDetailsOccupationalHealthcare()` */
  static readonly CreateContractDetailsOccupationalHealthcarePath = '/customers/{customerId}/contracts/{contractId}/occupational-healthcare';

  /**
   * Create contract details concerning occupational healthcare.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContractDetailsOccupationalHealthcare()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContractDetailsOccupationalHealthcare$Response(params: CreateContractDetailsOccupationalHealthcare$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthcareDto>> {
    return createContractDetailsOccupationalHealthcare(this.http, this.rootUrl, params, context);
  }

  /**
   * Create contract details concerning occupational healthcare.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContractDetailsOccupationalHealthcare$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContractDetailsOccupationalHealthcare(params: CreateContractDetailsOccupationalHealthcare$Params, context?: HttpContext): Observable<HealthcareDto> {
    return this.createContractDetailsOccupationalHealthcare$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthcareDto>): HealthcareDto => r.body)
    );
  }

  /** Path part for operation `getContractDetailsOccupationalSafetyAndHealthcare()` */
  static readonly GetContractDetailsOccupationalSafetyAndHealthcarePath = '/customers/{customerId}/contracts/occupational-safety-and-healthcare';

  /**
   * Get all contract details concerning occupational safety and healthcare.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractDetailsOccupationalSafetyAndHealthcare()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractDetailsOccupationalSafetyAndHealthcare$Response(params: GetContractDetailsOccupationalSafetyAndHealthcare$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyAndHealthcareDto>> {
    return getContractDetailsOccupationalSafetyAndHealthcare(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contract details concerning occupational safety and healthcare.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractDetailsOccupationalSafetyAndHealthcare$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractDetailsOccupationalSafetyAndHealthcare(params: GetContractDetailsOccupationalSafetyAndHealthcare$Params, context?: HttpContext): Observable<SafetyAndHealthcareDto> {
    return this.getContractDetailsOccupationalSafetyAndHealthcare$Response(params, context).pipe(
      map((r: StrictHttpResponse<SafetyAndHealthcareDto>): SafetyAndHealthcareDto => r.body)
    );
  }

}
