import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AsiCustomerDto, AsiUserDto, AuthControllerService } from '@myasi/api/myasi-auth/permissions';

import { ErrorHandlingService, ErrorHandlingType, ErrorMessageKey } from '@svg-frontends/error';

@Injectable({ providedIn: 'root' })
export class AuthRepositoryService {
	constructor(private authControllerService: AuthControllerService, private errorHandlingService: ErrorHandlingService) {}

	getUser(): Observable<AsiUserDto> {
		return this.authControllerService.getUserInfo().pipe(
			catchError((httpErrorResponse: HttpErrorResponse) => {
				const errorKey = this.getCustomerInfoEndpointErrorKey(httpErrorResponse);
				this.errorHandlingService.setNextErrorByKey(errorKey, ErrorHandlingType.ROUTE_TO_ERROR_PAGE, false);
				return of(null);
			}),
		);
	}

	getCustomer(customerId: number): Observable<AsiCustomerDto> {
		return this.authControllerService.getCustomerInfo({ id: customerId }).pipe(
			catchError((httpErrorResponse: HttpErrorResponse) => {
				const errorKey = this.getCustomerInfoEndpointErrorKey(httpErrorResponse);
				this.errorHandlingService.setNextErrorByKey(errorKey, ErrorHandlingType.ROUTE_TO_ERROR_PAGE, false);
				return of(null);
			}),
		);
	}

	private getCustomerInfoEndpointErrorKey(httpErrorResponse: HttpErrorResponse): string {
		if (httpErrorResponse.status === 404) {
			return ErrorMessageKey.AUTH_CUSTOMER_NOT_FOUND;
		} else if (httpErrorResponse.status === 403) {
			return ErrorMessageKey.AUTH_CUSTOMER_FORBIDDEN;
		} else {
			return httpErrorResponse.status.toString();
		}
	}
}
