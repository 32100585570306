/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContractDto } from '../models/contract-dto';
import { ContractHoursDto } from '../models/contract-hours-dto';
import { ContractTypeDto } from '../models/contract-type-dto';
import { createContract } from '../fn/customer-contracts-controller/create-contract';
import { CreateContract$Params } from '../fn/customer-contracts-controller/create-contract';
import { deleteContract } from '../fn/customer-contracts-controller/delete-contract';
import { DeleteContract$Params } from '../fn/customer-contracts-controller/delete-contract';
import { getAllAvailableContractTypesForCustomer } from '../fn/customer-contracts-controller/get-all-available-contract-types-for-customer';
import { GetAllAvailableContractTypesForCustomer$Params } from '../fn/customer-contracts-controller/get-all-available-contract-types-for-customer';
import { getAllContractTypes } from '../fn/customer-contracts-controller/get-all-contract-types';
import { GetAllContractTypes$Params } from '../fn/customer-contracts-controller/get-all-contract-types';
import { getAllCustomerContracts } from '../fn/customer-contracts-controller/get-all-customer-contracts';
import { GetAllCustomerContracts$Params } from '../fn/customer-contracts-controller/get-all-customer-contracts';
import { getAllPaymentPeriods } from '../fn/customer-contracts-controller/get-all-payment-periods';
import { GetAllPaymentPeriods$Params } from '../fn/customer-contracts-controller/get-all-payment-periods';
import { getContractById } from '../fn/customer-contracts-controller/get-contract-by-id';
import { GetContractById$Params } from '../fn/customer-contracts-controller/get-contract-by-id';
import { getContractsWithHoursFor } from '../fn/customer-contracts-controller/get-contracts-with-hours-for';
import { GetContractsWithHoursFor$Params } from '../fn/customer-contracts-controller/get-contracts-with-hours-for';
import { patchDocument } from '../fn/customer-contracts-controller/patch-document';
import { PatchDocument$Params } from '../fn/customer-contracts-controller/patch-document';
import { PaymentPeriodDto } from '../models/payment-period-dto';
import { updateContract } from '../fn/customer-contracts-controller/update-contract';
import { UpdateContract$Params } from '../fn/customer-contracts-controller/update-contract';


/**
 * manage customer contracts
 */
@Injectable({ providedIn: 'root' })
export class CustomerContractsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getContractById()` */
  static readonly GetContractByIdPath = '/customers/{customerId}/contracts/{contractId}';

  /**
   * Get a contract by Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractById$Response(params: GetContractById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return getContractById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a contract by Id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractById(params: GetContractById$Params, context?: HttpContext): Observable<ContractDto> {
    return this.getContractById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

  /** Path part for operation `updateContract()` */
  static readonly UpdateContractPath = '/customers/{customerId}/contracts/{contractId}';

  /**
   * update a contract
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContract$Response(params: UpdateContract$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return updateContract(this.http, this.rootUrl, params, context);
  }

  /**
   * update a contract
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContract(params: UpdateContract$Params, context?: HttpContext): Observable<ContractDto> {
    return this.updateContract$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

  /** Path part for operation `deleteContract()` */
  static readonly DeleteContractPath = '/customers/{customerId}/contracts/{contractId}';

  /**
   * delete a contract
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContract()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContract$Response(params: DeleteContract$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteContract(this.http, this.rootUrl, params, context);
  }

  /**
   * delete a contract
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContract$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContract(params: DeleteContract$Params, context?: HttpContext): Observable<void> {
    return this.deleteContract$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllCustomerContracts()` */
  static readonly GetAllCustomerContractsPath = '/customers/{customerId}/contracts';

  /**
   * Get all contracts of one customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomerContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerContracts$Response(params: GetAllCustomerContracts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContractDto>>> {
    return getAllCustomerContracts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contracts of one customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCustomerContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerContracts(params: GetAllCustomerContracts$Params, context?: HttpContext): Observable<Array<ContractDto>> {
    return this.getAllCustomerContracts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContractDto>>): Array<ContractDto> => r.body)
    );
  }

  /** Path part for operation `createContract()` */
  static readonly CreateContractPath = '/customers/{customerId}/contracts';

  /**
   * create a contract
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContract$Response(params: CreateContract$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return createContract(this.http, this.rootUrl, params, context);
  }

  /**
   * create a contract
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContract(params: CreateContract$Params, context?: HttpContext): Observable<ContractDto> {
    return this.createContract$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

  /** Path part for operation `patchDocument()` */
  static readonly PatchDocumentPath = '/customers/{customerId}/contracts/{contractId}/document/{documentId}';

  /**
   * patch document of contract
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchDocument$Response(params: PatchDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return patchDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * patch document of contract
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchDocument(params: PatchDocument$Params, context?: HttpContext): Observable<ContractDto> {
    return this.patchDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

  /** Path part for operation `getAllAvailableContractTypesForCustomer()` */
  static readonly GetAllAvailableContractTypesForCustomerPath = '/customers/{customerId}/types';

  /**
   * Get available contract types to create for customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAvailableContractTypesForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableContractTypesForCustomer$Response(params: GetAllAvailableContractTypesForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContractTypeDto>>> {
    return getAllAvailableContractTypesForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available contract types to create for customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAvailableContractTypesForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableContractTypesForCustomer(params: GetAllAvailableContractTypesForCustomer$Params, context?: HttpContext): Observable<Array<ContractTypeDto>> {
    return this.getAllAvailableContractTypesForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContractTypeDto>>): Array<ContractTypeDto> => r.body)
    );
  }

  /** Path part for operation `getContractsWithHoursFor()` */
  static readonly GetContractsWithHoursForPath = '/customers/{customerId}/contracts/with-hours';

  /**
   * Get all contracts of one customer with pending hours and planned hours per year.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractsWithHoursFor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsWithHoursFor$Response(params: GetContractsWithHoursFor$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContractHoursDto>>> {
    return getContractsWithHoursFor(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contracts of one customer with pending hours and planned hours per year.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractsWithHoursFor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsWithHoursFor(params: GetContractsWithHoursFor$Params, context?: HttpContext): Observable<Array<ContractHoursDto>> {
    return this.getContractsWithHoursFor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContractHoursDto>>): Array<ContractHoursDto> => r.body)
    );
  }

  /** Path part for operation `getAllContractTypes()` */
  static readonly GetAllContractTypesPath = '/contracts/types';

  /**
   * Get all contract types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllContractTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllContractTypes$Response(params?: GetAllContractTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContractTypeDto>>> {
    return getAllContractTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contract types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllContractTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllContractTypes(params?: GetAllContractTypes$Params, context?: HttpContext): Observable<Array<ContractTypeDto>> {
    return this.getAllContractTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContractTypeDto>>): Array<ContractTypeDto> => r.body)
    );
  }

  /** Path part for operation `getAllPaymentPeriods()` */
  static readonly GetAllPaymentPeriodsPath = '/contracts/payment-periods';

  /**
   * Get all payment periods.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPaymentPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPaymentPeriods$Response(params?: GetAllPaymentPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentPeriodDto>>> {
    return getAllPaymentPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all payment periods.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPaymentPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPaymentPeriods(params?: GetAllPaymentPeriods$Params, context?: HttpContext): Observable<Array<PaymentPeriodDto>> {
    return this.getAllPaymentPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentPeriodDto>>): Array<PaymentPeriodDto> => r.body)
    );
  }

}
