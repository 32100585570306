/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerVehicleQuantity } from '../fn/customer-vehicle-quantity-controller/create-customer-vehicle-quantity';
import { CreateCustomerVehicleQuantity$Params } from '../fn/customer-vehicle-quantity-controller/create-customer-vehicle-quantity';
import { CustomerVehicleQuantityDto } from '../models/customer-vehicle-quantity-dto';
import { getCustomerVehicleQuantity } from '../fn/customer-vehicle-quantity-controller/get-customer-vehicle-quantity';
import { GetCustomerVehicleQuantity$Params } from '../fn/customer-vehicle-quantity-controller/get-customer-vehicle-quantity';
import { patchVehicleCount } from '../fn/customer-vehicle-quantity-controller/patch-vehicle-count';
import { PatchVehicleCount$Params } from '../fn/customer-vehicle-quantity-controller/patch-vehicle-count';


/**
 * get vehicle-quantity of a customer
 */
@Injectable({ providedIn: 'root' })
export class CustomerVehicleQuantityControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomerVehicleQuantity()` */
  static readonly GetCustomerVehicleQuantityPath = '/customers/{customerId}/vehicle-quantities';

  /**
   * Get vehicle-quantity for given customerId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerVehicleQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerVehicleQuantity$Response(params: GetCustomerVehicleQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerVehicleQuantityDto>>> {
    return getCustomerVehicleQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * Get vehicle-quantity for given customerId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerVehicleQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerVehicleQuantity(params: GetCustomerVehicleQuantity$Params, context?: HttpContext): Observable<Array<CustomerVehicleQuantityDto>> {
    return this.getCustomerVehicleQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerVehicleQuantityDto>>): Array<CustomerVehicleQuantityDto> => r.body)
    );
  }

  /** Path part for operation `createCustomerVehicleQuantity()` */
  static readonly CreateCustomerVehicleQuantityPath = '/customers/{customerId}/vehicle-quantities';

  /**
   * Creates a vehicle-quantity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerVehicleQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerVehicleQuantity$Response(params: CreateCustomerVehicleQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerVehicleQuantityDto>> {
    return createCustomerVehicleQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a vehicle-quantity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerVehicleQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerVehicleQuantity(params: CreateCustomerVehicleQuantity$Params, context?: HttpContext): Observable<CustomerVehicleQuantityDto> {
    return this.createCustomerVehicleQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerVehicleQuantityDto>): CustomerVehicleQuantityDto => r.body)
    );
  }

  /** Path part for operation `patchVehicleCount()` */
  static readonly PatchVehicleCountPath = '/customers/{customerId}/vehicle-quantities/{id}/{vehicleCount}';

  /**
   * Updates a vehicle-quantity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVehicleCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchVehicleCount$Response(params: PatchVehicleCount$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerVehicleQuantityDto>> {
    return patchVehicleCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a vehicle-quantity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchVehicleCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchVehicleCount(params: PatchVehicleCount$Params, context?: HttpContext): Observable<CustomerVehicleQuantityDto> {
    return this.patchVehicleCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerVehicleQuantityDto>): CustomerVehicleQuantityDto => r.body)
    );
  }

}
