/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddRegulationsFormDto } from '../../models/add-regulations-form-dto';
import { RegulationDto } from '../../models/regulation-dto';

export interface RemoveRegulation$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * id of hazard factor, which groups regulations
 */
  hazardFactorId: number;
      body: AddRegulationsFormDto
}

export function removeRegulation(http: HttpClient, rootUrl: string, params: RemoveRegulation$Params, context?: HttpContext): Observable<StrictHttpResponse<RegulationDto>> {
  const rb = new RequestBuilder(rootUrl, removeRegulation.PATH, 'delete');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('hazardFactorId', params.hazardFactorId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RegulationDto>;
    })
  );
}

removeRegulation.PATH = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations';
