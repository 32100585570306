/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BillableServiceCategoryOverviewDto } from '../../models/billable-service-category-overview-dto';

export interface GetAvailableBillableServiceCategoriesForSelectedCustomers$Params {

/**
 * filter for the customers id
 */
  id?: string;

/**
 * filter for the customers Number
 */
  customerNumber?: string;

/**
 * filter for the customers matchcode
 */
  matchCode?: string;

/**
 * filter customers Type. filter parameters needs a list of customerType IDs
 */
  customerTypeIds?: Array<number>;

/**
 * filter the start date of asd membership  from/to
 */
  startDateAsd?: string;

/**
 * filter for end date of asd membership from/to
 */
  endDateAsd?: string;

/**
 * to filter for new asd customers
 */
  newCustomer?: string;

/**
 * filter the customers active flag
 */
  active?: string;

/**
 * filter if customer is locked or not
 */
  locked?: string;

/**
 * filter for certain SVGs. parameters needs a list of SvgInfo numbers.
 */
  svgInfoNumbers?: Array<number>;

/**
 * filter for certain safety engineers(SiFa). parameter needs a list of employee ids.
 */
  safetyEngineerIds?: Array<number>;

/**
 * fetch customers without safety engineer only
 */
  withoutSafetyEngineer?: boolean;

/**
 * search for customers name
 */
  displayName?: string;

/**
 * filter for the customers street
 */
  street?: string;

/**
 * filter for the customers postcode
 */
  postcode?: string;

/**
 * filter for the customers city
 */
  city?: string;

/**
 * filter for the customers country
 */
  countryIds?: Array<number>;

/**
 * filtering the economic branch id
 */
  economicBranchIds?: Array<string>;

/**
 * filtering the businessGroup
 */
  businessGroup?: string;

/**
 * range filter for the number of employees [from/to]
 */
  numberEmployees?: string;

/**
 * filter for the contractTypes
 */
  contractTypeIds?: Array<string>;

/**
 * range filter for the asd hour budget [from/to]
 */
  asdHourBudget?: string;

/**
 * filter for the industry branch
 */
  industryBranchIds?: Array<string>;

/**
 * filter for the contractTypes
 */
  tagIds?: Array<string>;

/**
 * filter for debitor number
 */
  debitorNumber?: string;

/**
 * filter for svg customer id
 */
  svgCustomerId?: string;
}

export function getAvailableBillableServiceCategoriesForSelectedCustomers(http: HttpClient, rootUrl: string, params?: GetAvailableBillableServiceCategoriesForSelectedCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>> {
  const rb = new RequestBuilder(rootUrl, getAvailableBillableServiceCategoriesForSelectedCustomers.PATH, 'get');
  if (params) {
    rb.query('id', params.id, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('matchCode', params.matchCode, {});
    rb.query('customerTypeIds', params.customerTypeIds, {});
    rb.query('startDateAsd', params.startDateAsd, {});
    rb.query('endDateAsd', params.endDateAsd, {});
    rb.query('newCustomer', params.newCustomer, {});
    rb.query('active', params.active, {});
    rb.query('locked', params.locked, {});
    rb.query('svgInfoNumbers', params.svgInfoNumbers, {});
    rb.query('safetyEngineerIds', params.safetyEngineerIds, {});
    rb.query('withoutSafetyEngineer', params.withoutSafetyEngineer, {});
    rb.query('displayName', params.displayName, {});
    rb.query('street', params.street, {});
    rb.query('postcode', params.postcode, {});
    rb.query('city', params.city, {});
    rb.query('countryIds', params.countryIds, {});
    rb.query('economicBranchIds', params.economicBranchIds, {});
    rb.query('businessGroup', params.businessGroup, {});
    rb.query('numberEmployees', params.numberEmployees, {});
    rb.query('contractTypeIds', params.contractTypeIds, {});
    rb.query('asdHourBudget', params.asdHourBudget, {});
    rb.query('industryBranchIds', params.industryBranchIds, {});
    rb.query('tagIds', params.tagIds, {});
    rb.query('debitorNumber', params.debitorNumber, {});
    rb.query('svgCustomerId', params.svgCustomerId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>;
    })
  );
}

getAvailableBillableServiceCategoriesForSelectedCustomers.PATH = '/customers/available-billable-service-categories';
