/* tslint:disable */
/* eslint-disable */

/**
 * the selected answer to the question or null if not answered.
 */
export enum HealthSafetyAnswer {
  NO = 'NO',
  YES = 'YES',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}
