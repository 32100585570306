/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsiSvgInfoDto } from '../models/asi-svg-info-dto';
import { getAllSvgInfos } from '../fn/svg-info-controller/get-all-svg-infos';
import { GetAllSvgInfos$Params } from '../fn/svg-info-controller/get-all-svg-infos';


/**
 * Manages SVG infos
 */
@Injectable({ providedIn: 'root' })
export class SvgInfoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSvgInfos()` */
  static readonly GetAllSvgInfosPath = '/svg-infos';

  /**
   * Get all svg infos.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSvgInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgInfos$Response(params?: GetAllSvgInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AsiSvgInfoDto>>> {
    return getAllSvgInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all svg infos.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSvgInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSvgInfos(params?: GetAllSvgInfos$Params, context?: HttpContext): Observable<Array<AsiSvgInfoDto>> {
    return this.getAllSvgInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AsiSvgInfoDto>>): Array<AsiSvgInfoDto> => r.body)
    );
  }

}
