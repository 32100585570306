/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceDto } from '../models/billable-service-dto';
import { getBilledAsdHourRequestsForYearAnd } from '../fn/billable-service-keyfigure-controller/get-billed-asd-hour-requests-for-year-and';
import { GetBilledAsdHourRequestsForYearAnd$Params } from '../fn/billable-service-keyfigure-controller/get-billed-asd-hour-requests-for-year-and';
import { getBilledAsdHoursFor } from '../fn/billable-service-keyfigure-controller/get-billed-asd-hours-for';
import { GetBilledAsdHoursFor$Params } from '../fn/billable-service-keyfigure-controller/get-billed-asd-hours-for';
import { getCustomersWithNoNewBilledServicesFor } from '../fn/billable-service-keyfigure-controller/get-customers-with-no-new-billed-services-for';
import { GetCustomersWithNoNewBilledServicesFor$Params } from '../fn/billable-service-keyfigure-controller/get-customers-with-no-new-billed-services-for';
import { getLastBilledVisit } from '../fn/billable-service-keyfigure-controller/get-last-billed-visit';
import { GetLastBilledVisit$Params } from '../fn/billable-service-keyfigure-controller/get-last-billed-visit';
import { getNewCustomers } from '../fn/billable-service-keyfigure-controller/get-new-customers';
import { GetNewCustomers$Params } from '../fn/billable-service-keyfigure-controller/get-new-customers';
import { getNewCustomersWithoutInitialLetter } from '../fn/billable-service-keyfigure-controller/get-new-customers-without-initial-letter';
import { GetNewCustomersWithoutInitialLetter$Params } from '../fn/billable-service-keyfigure-controller/get-new-customers-without-initial-letter';
import { getNewCustomersWithoutSecurityExpert } from '../fn/billable-service-keyfigure-controller/get-new-customers-without-security-expert';
import { GetNewCustomersWithoutSecurityExpert$Params } from '../fn/billable-service-keyfigure-controller/get-new-customers-without-security-expert';
import { getOpenAsdHourRequestsForYearAnd } from '../fn/billable-service-keyfigure-controller/get-open-asd-hour-requests-for-year-and';
import { GetOpenAsdHourRequestsForYearAnd$Params } from '../fn/billable-service-keyfigure-controller/get-open-asd-hour-requests-for-year-and';
import { getOpenAsdHoursSvgForYearAnd } from '../fn/billable-service-keyfigure-controller/get-open-asd-hours-svg-for-year-and';
import { GetOpenAsdHoursSvgForYearAnd$Params } from '../fn/billable-service-keyfigure-controller/get-open-asd-hours-svg-for-year-and';
import { getOpenAsdHoursTeamForYearAnd } from '../fn/billable-service-keyfigure-controller/get-open-asd-hours-team-for-year-and';
import { GetOpenAsdHoursTeamForYearAnd$Params } from '../fn/billable-service-keyfigure-controller/get-open-asd-hours-team-for-year-and';


/**
 * All getters for key figures based on billable services.
 */
@Injectable({ providedIn: 'root' })
export class BillableServiceKeyfigureControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOpenAsdHourRequestsForYearAnd()` */
  static readonly GetOpenAsdHourRequestsForYearAndPath = '/billable-services/open-asd-hour-requests-for/{userId}';

  /**
   * Get amount of open services of a sifa with number 9200, which requests more hours for a customer from asd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenAsdHourRequestsForYearAnd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHourRequestsForYearAnd$Response(params: GetOpenAsdHourRequestsForYearAnd$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getOpenAsdHourRequestsForYearAnd(this.http, this.rootUrl, params, context);
  }

  /**
   * Get amount of open services of a sifa with number 9200, which requests more hours for a customer from asd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenAsdHourRequestsForYearAnd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHourRequestsForYearAnd(params: GetOpenAsdHourRequestsForYearAnd$Params, context?: HttpContext): Observable<number> {
    return this.getOpenAsdHourRequestsForYearAnd$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getCustomersWithNoNewBilledServicesFor()` */
  static readonly GetCustomersWithNoNewBilledServicesForPath = '/billable-services/keyfigures/customers-with-no-new-billed-services/{userId}';

  /**
   * Get customers of sifa which show no activity (no billed services for the last 3 years)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersWithNoNewBilledServicesFor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithNoNewBilledServicesFor$Response(params: GetCustomersWithNoNewBilledServicesFor$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getCustomersWithNoNewBilledServicesFor(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customers of sifa which show no activity (no billed services for the last 3 years)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersWithNoNewBilledServicesFor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWithNoNewBilledServicesFor(params: GetCustomersWithNoNewBilledServicesFor$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getCustomersWithNoNewBilledServicesFor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `getNewCustomers()` */
  static readonly GetNewCustomersPath = '/billable-services/keyfigures/customers-new/{userId}';

  /**
   * Get new customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomers$Response(params: GetNewCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getNewCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get new customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomers(params: GetNewCustomers$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getNewCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `getNewCustomersWithoutSecurityExpert()` */
  static readonly GetNewCustomersWithoutSecurityExpertPath = '/billable-services/keyfigures/customers-new-without-securityexpert/{userId}';

  /**
   * Get new customers with no security expert assignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCustomersWithoutSecurityExpert()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomersWithoutSecurityExpert$Response(params: GetNewCustomersWithoutSecurityExpert$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getNewCustomersWithoutSecurityExpert(this.http, this.rootUrl, params, context);
  }

  /**
   * Get new customers with no security expert assignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCustomersWithoutSecurityExpert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomersWithoutSecurityExpert(params: GetNewCustomersWithoutSecurityExpert$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getNewCustomersWithoutSecurityExpert$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `getNewCustomersWithoutInitialLetter()` */
  static readonly GetNewCustomersWithoutInitialLetterPath = '/billable-services/keyfigures/customers-new-without-initialletter/{userId}';

  /**
   * Get new customers that have no had the initial letter billed yet.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCustomersWithoutInitialLetter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomersWithoutInitialLetter$Response(params: GetNewCustomersWithoutInitialLetter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getNewCustomersWithoutInitialLetter(this.http, this.rootUrl, params, context);
  }

  /**
   * Get new customers that have no had the initial letter billed yet.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCustomersWithoutInitialLetter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomersWithoutInitialLetter(params: GetNewCustomersWithoutInitialLetter$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getNewCustomersWithoutInitialLetter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `getLastBilledVisit()` */
  static readonly GetLastBilledVisitPath = '/billable-services/keyfigures/customer/{customerId}/last-visit';

  /**
   * Get last created billable service with specific number see: MYASI-1632
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastBilledVisit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastBilledVisit$Response(params: GetLastBilledVisit$Params, context?: HttpContext): Observable<StrictHttpResponse<BillableServiceDto>> {
    return getLastBilledVisit(this.http, this.rootUrl, params, context);
  }

  /**
   * Get last created billable service with specific number see: MYASI-1632
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLastBilledVisit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastBilledVisit(params: GetLastBilledVisit$Params, context?: HttpContext): Observable<BillableServiceDto> {
    return this.getLastBilledVisit$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillableServiceDto>): BillableServiceDto => r.body)
    );
  }

  /** Path part for operation `getBilledAsdHoursFor()` */
  static readonly GetBilledAsdHoursForPath = '/billable-services/keyfigures/billed-hours-for/{userId}';

  /**
   * Get all hours based on billed services of one sifa for the current year.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBilledAsdHoursFor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBilledAsdHoursFor$Response(params: GetBilledAsdHoursFor$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getBilledAsdHoursFor(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all hours based on billed services of one sifa for the current year.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBilledAsdHoursFor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBilledAsdHoursFor(params: GetBilledAsdHoursFor$Params, context?: HttpContext): Observable<number> {
    return this.getBilledAsdHoursFor$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getOpenAsdHoursTeamForYearAnd()` */
  static readonly GetOpenAsdHoursTeamForYearAndPath = '/billable-services/billed-hours-team-for/{userId}';

  /**
   * Get all hours based on billed services of one team lead's team (all sifas billed hours of a team)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenAsdHoursTeamForYearAnd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHoursTeamForYearAnd$Response(params: GetOpenAsdHoursTeamForYearAnd$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getOpenAsdHoursTeamForYearAnd(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all hours based on billed services of one team lead's team (all sifas billed hours of a team)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenAsdHoursTeamForYearAnd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHoursTeamForYearAnd(params: GetOpenAsdHoursTeamForYearAnd$Params, context?: HttpContext): Observable<number> {
    return this.getOpenAsdHoursTeamForYearAnd$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getOpenAsdHoursSvgForYearAnd()` */
  static readonly GetOpenAsdHoursSvgForYearAndPath = '/billable-services/billed-hours-svg-for/{userId}';

  /**
   * Get all hours based on billed services of one koodis svg (all sifas billed hours of a svg)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenAsdHoursSvgForYearAnd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHoursSvgForYearAnd$Response(params: GetOpenAsdHoursSvgForYearAnd$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getOpenAsdHoursSvgForYearAnd(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all hours based on billed services of one koodis svg (all sifas billed hours of a svg)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenAsdHoursSvgForYearAnd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenAsdHoursSvgForYearAnd(params: GetOpenAsdHoursSvgForYearAnd$Params, context?: HttpContext): Observable<number> {
    return this.getOpenAsdHoursSvgForYearAnd$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getBilledAsdHourRequestsForYearAnd()` */
  static readonly GetBilledAsdHourRequestsForYearAndPath = '/billable-services/billed-asd-hour-requests-for/{userId}';

  /**
   * Get amount of billed services of a sifa with number 9200, which requests more hours for a customer from asd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBilledAsdHourRequestsForYearAnd()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBilledAsdHourRequestsForYearAnd$Response(params: GetBilledAsdHourRequestsForYearAnd$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getBilledAsdHourRequestsForYearAnd(this.http, this.rootUrl, params, context);
  }

  /**
   * Get amount of billed services of a sifa with number 9200, which requests more hours for a customer from asd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBilledAsdHourRequestsForYearAnd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBilledAsdHourRequestsForYearAnd(params: GetBilledAsdHourRequestsForYearAnd$Params, context?: HttpContext): Observable<number> {
    return this.getBilledAsdHourRequestsForYearAnd$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
