/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNewsletter } from '../fn/newsletter-controller/create-newsletter';
import { CreateNewsletter$Params } from '../fn/newsletter-controller/create-newsletter';
import { deleteNewsletter } from '../fn/newsletter-controller/delete-newsletter';
import { DeleteNewsletter$Params } from '../fn/newsletter-controller/delete-newsletter';
import { deleteNewsletterWithoutValidation } from '../fn/newsletter-controller/delete-newsletter-without-validation';
import { DeleteNewsletterWithoutValidation$Params } from '../fn/newsletter-controller/delete-newsletter-without-validation';
import { getNewsletter } from '../fn/newsletter-controller/get-newsletter';
import { GetNewsletter$Params } from '../fn/newsletter-controller/get-newsletter';
import { NewsletterDto } from '../models/newsletter-dto';

@Injectable({ providedIn: 'root' })
export class NewsletterControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNewsletter()` */
  static readonly GetNewsletterPath = '/newsletter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsletter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsletter$Response(params?: GetNewsletter$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsletterDto>> {
    return getNewsletter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsletter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsletter(params?: GetNewsletter$Params, context?: HttpContext): Observable<NewsletterDto> {
    return this.getNewsletter$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsletterDto>): NewsletterDto => r.body)
    );
  }

  /** Path part for operation `createNewsletter()` */
  static readonly CreateNewsletterPath = '/newsletter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewsletter()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createNewsletter$Response(params: CreateNewsletter$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsletterDto>> {
    return createNewsletter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewsletter$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createNewsletter(params: CreateNewsletter$Params, context?: HttpContext): Observable<NewsletterDto> {
    return this.createNewsletter$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsletterDto>): NewsletterDto => r.body)
    );
  }

  /** Path part for operation `deleteNewsletter()` */
  static readonly DeleteNewsletterPath = '/newsletter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNewsletter()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNewsletter$Response(params?: DeleteNewsletter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteNewsletter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNewsletter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNewsletter(params?: DeleteNewsletter$Params, context?: HttpContext): Observable<void> {
    return this.deleteNewsletter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteNewsletterWithoutValidation()` */
  static readonly DeleteNewsletterWithoutValidationPath = '/newsletter/without-validation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNewsletterWithoutValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNewsletterWithoutValidation$Response(params?: DeleteNewsletterWithoutValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteNewsletterWithoutValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNewsletterWithoutValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNewsletterWithoutValidation(params?: DeleteNewsletterWithoutValidation$Params, context?: HttpContext): Observable<void> {
    return this.deleteNewsletterWithoutValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
