// [NOTE] services which are set to true here, will be used with hard coded local url (port)

export const LOCAL_SERVICES = {
	auth: false,
	billableService: false,
	centrifugo: false,
	customer: false,
	customerSvg: false,
	document: false,
	excel: false,
	releaseNotes: false,
};
