/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerContingentDto } from '../models/customer-contingent-dto';
import { getContingentByCustomerId } from '../fn/customer-contingent-controller/get-contingent-by-customer-id';
import { GetContingentByCustomerId$Params } from '../fn/customer-contingent-controller/get-contingent-by-customer-id';


/**
 * Customer Contingents
 */
@Injectable({ providedIn: 'root' })
export class CustomerContingentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getContingentByCustomerId()` */
  static readonly GetContingentByCustomerIdPath = '/customers/{id}/contingent';

  /**
   * Returns the Contingent of a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContingentByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContingentByCustomerId$Response(params: GetContingentByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerContingentDto>> {
    return getContingentByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Contingent of a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContingentByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContingentByCustomerId(params: GetContingentByCustomerId$Params, context?: HttpContext): Observable<CustomerContingentDto> {
    return this.getContingentByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerContingentDto>): CustomerContingentDto => r.body)
    );
  }

}
