/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTrainingCustomer } from '../fn/training-customer-controller/create-training-customer';
import { CreateTrainingCustomer$Params } from '../fn/training-customer-controller/create-training-customer';
import { deleteTrainingCustomer } from '../fn/training-customer-controller/delete-training-customer';
import { DeleteTrainingCustomer$Params } from '../fn/training-customer-controller/delete-training-customer';
import { getTrainingCustomer } from '../fn/training-customer-controller/get-training-customer';
import { GetTrainingCustomer$Params } from '../fn/training-customer-controller/get-training-customer';
import { getTrainingCustomers } from '../fn/training-customer-controller/get-training-customers';
import { GetTrainingCustomers$Params } from '../fn/training-customer-controller/get-training-customers';
import { TrainingCustomerDto } from '../models/training-customer-dto';
import { updateTrainingCustomer } from '../fn/training-customer-controller/update-training-customer';
import { UpdateTrainingCustomer$Params } from '../fn/training-customer-controller/update-training-customer';

@Injectable({ providedIn: 'root' })
export class TrainingCustomerControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTrainingCustomer()` */
  static readonly UpdateTrainingCustomerPath = '/training/{type}/{trainingId}/customer/{customerId}';

  /**
   * update customer of training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTrainingCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTrainingCustomer$Response(params: UpdateTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TrainingCustomerDto>> {
    return updateTrainingCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * update customer of training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTrainingCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTrainingCustomer(params: UpdateTrainingCustomer$Params, context?: HttpContext): Observable<TrainingCustomerDto> {
    return this.updateTrainingCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingCustomerDto>): TrainingCustomerDto => r.body)
    );
  }

  /** Path part for operation `createTrainingCustomer()` */
  static readonly CreateTrainingCustomerPath = '/training/{type}/{trainingId}/customer/{customerId}';

  /**
   * add customer to training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTrainingCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTrainingCustomer$Response(params: CreateTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TrainingCustomerDto>> {
    return createTrainingCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * add customer to training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTrainingCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTrainingCustomer(params: CreateTrainingCustomer$Params, context?: HttpContext): Observable<TrainingCustomerDto> {
    return this.createTrainingCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingCustomerDto>): TrainingCustomerDto => r.body)
    );
  }

  /** Path part for operation `deleteTrainingCustomer()` */
  static readonly DeleteTrainingCustomerPath = '/training/{type}/{trainingId}/customer/{customerId}';

  /**
   * remove a customer from training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTrainingCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrainingCustomer$Response(params: DeleteTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTrainingCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a customer from training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTrainingCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrainingCustomer(params: DeleteTrainingCustomer$Params, context?: HttpContext): Observable<void> {
    return this.deleteTrainingCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTrainingCustomers()` */
  static readonly GetTrainingCustomersPath = '/training/{type}/{trainingId}/customers';

  /**
   * get customers of training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCustomers$Response(params: GetTrainingCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TrainingCustomerDto>>> {
    return getTrainingCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * get customers of training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCustomers(params: GetTrainingCustomers$Params, context?: HttpContext): Observable<Array<TrainingCustomerDto>> {
    return this.getTrainingCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TrainingCustomerDto>>): Array<TrainingCustomerDto> => r.body)
    );
  }

  /** Path part for operation `getTrainingCustomer()` */
  static readonly GetTrainingCustomerPath = '/training/{type}/{trainingId}/customers/{customerId}';

  /**
   * get customers of training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCustomer$Response(params: GetTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TrainingCustomerDto>> {
    return getTrainingCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * get customers of training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrainingCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingCustomer(params: GetTrainingCustomer$Params, context?: HttpContext): Observable<TrainingCustomerDto> {
    return this.getTrainingCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TrainingCustomerDto>): TrainingCustomerDto => r.body)
    );
  }

}
