import { Injectable } from '@angular/core';
import {
	administratorIcon,
	angleIcon,
	arrowIcon,
	assignUserIcon,
	banIcon,
	barChartIcon,
	calculatorIcon,
	chatBubbleIcon,
	checkboxListIcon,
	clockIcon,
	coinBagIcon,
	containerIcon,
	copyToClipboardIcon,
	dashboardIcon,
	detailsIcon,
	downloadIcon,
	envelopeIcon,
	exportIcon,
	fileGroupIcon,
	fileIcon,
	fileSettingsIcon,
	formIcon,
	helpIcon,
	helpInfoIcon,
	homeIcon,
	hourglassIcon,
	imageIcon,
	infoStandardIcon,
	keyIcon,
	lightbulbIcon,
	lineChartIcon,
	linkIcon,
	logoutIcon,
	noteIcon,
	pencilIcon,
	pluginIcon,
	powerIcon,
	rackServerIcon,
	redoIcon,
	stopIcon,
	successStandardIcon,
	tableIcon,
	timesCircleIcon,
	uploadCloudIcon,
	userIcon,
	usersIcon,
} from '@cds/core/icon';
import { IconShapeTuple } from '@cds/core/icon/interfaces/icon.interfaces';

import { ClarityInitializationService } from '@svg-frontends/theming';

@Injectable({ providedIn: 'root' })
export class ClarityInitService extends ClarityInitializationService {
	protected getIcons(): IconShapeTuple[] {
		const preset = super.getIcons();
		return [
			...preset,

			administratorIcon,
			angleIcon,
			assignUserIcon,
			arrowIcon,
			banIcon,
			barChartIcon,
			calculatorIcon,
			chatBubbleIcon,
			checkboxListIcon,
			clockIcon,
			coinBagIcon,
			containerIcon,
			copyToClipboardIcon,
			dashboardIcon,
			detailsIcon,
			downloadIcon,
			envelopeIcon,
			exportIcon,
			fileGroupIcon,
			fileIcon,
			fileSettingsIcon,
			formIcon,
			formIcon,
			helpIcon,
			helpInfoIcon,
			homeIcon,
			hourglassIcon,
			imageIcon,
			infoStandardIcon,
			keyIcon,
			lightbulbIcon,
			lineChartIcon,
			linkIcon,
			logoutIcon,
			noteIcon,
			pencilIcon,
			pluginIcon,
			powerIcon,
			rackServerIcon,
			redoIcon,
			stopIcon,
			successStandardIcon,
			tableIcon,
			timesCircleIcon,
			uploadCloudIcon,
			userIcon,
			usersIcon,
		];
	}
}
