/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountabilityDto } from '../models/accountability-dto';
import { AccountabilityTypeDto } from '../models/accountability-type-dto';
import { getAccountabilityOfCustomer } from '../fn/accountability-controller/get-accountability-of-customer';
import { GetAccountabilityOfCustomer$Params } from '../fn/accountability-controller/get-accountability-of-customer';
import { getAllAccountabilityTypes } from '../fn/accountability-controller/get-all-accountability-types';
import { GetAllAccountabilityTypes$Params } from '../fn/accountability-controller/get-all-accountability-types';
import { updateAccountabilityOfCustomer } from '../fn/accountability-controller/update-accountability-of-customer';
import { UpdateAccountabilityOfCustomer$Params } from '../fn/accountability-controller/update-accountability-of-customer';


/**
 * Manage customers accountability which belongs to the dangerous goods contract.
 */
@Injectable({ providedIn: 'root' })
export class AccountabilityControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAccountabilityOfCustomer()` */
  static readonly GetAccountabilityOfCustomerPath = '/customers/{customerId}/contracts/dangerous-goods/accountability-entries';

  /**
   * Get the customers accountability.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountabilityOfCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountabilityOfCustomer$Response(params: GetAccountabilityOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountabilityDto>>> {
    return getAccountabilityOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the customers accountability.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountabilityOfCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountabilityOfCustomer(params: GetAccountabilityOfCustomer$Params, context?: HttpContext): Observable<Array<AccountabilityDto>> {
    return this.getAccountabilityOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountabilityDto>>): Array<AccountabilityDto> => r.body)
    );
  }

  /** Path part for operation `updateAccountabilityOfCustomer()` */
  static readonly UpdateAccountabilityOfCustomerPath = '/customers/{customerId}/contracts/dangerous-goods/accountability-entries';

  /**
   * update the customers accountability.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccountabilityOfCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccountabilityOfCustomer$Response(params: UpdateAccountabilityOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountabilityDto>>> {
    return updateAccountabilityOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * update the customers accountability.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccountabilityOfCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccountabilityOfCustomer(params: UpdateAccountabilityOfCustomer$Params, context?: HttpContext): Observable<Array<AccountabilityDto>> {
    return this.updateAccountabilityOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountabilityDto>>): Array<AccountabilityDto> => r.body)
    );
  }

  /** Path part for operation `getAllAccountabilityTypes()` */
  static readonly GetAllAccountabilityTypesPath = '/accountability-types';

  /**
   * Get all accountability types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccountabilityTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountabilityTypes$Response(params?: GetAllAccountabilityTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountabilityTypeDto>>> {
    return getAllAccountabilityTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all accountability types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAccountabilityTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccountabilityTypes(params?: GetAllAccountabilityTypes$Params, context?: HttpContext): Observable<Array<AccountabilityTypeDto>> {
    return this.getAllAccountabilityTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountabilityTypeDto>>): Array<AccountabilityTypeDto> => r.body)
    );
  }

}
