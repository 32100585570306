/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SafetyDto } from '../../models/safety-dto';
import { SafetyForm } from '../../models/safety-form';

export interface UpdateContractDetailsOccupationalSafety$Params {

/**
 * customerId
 */
  customerId: number;

/**
 * id
 */
  contractId: number;
      body: SafetyForm
}

export function updateContractDetailsOccupationalSafety(http: HttpClient, rootUrl: string, params: UpdateContractDetailsOccupationalSafety$Params, context?: HttpContext): Observable<StrictHttpResponse<SafetyDto>> {
  const rb = new RequestBuilder(rootUrl, updateContractDetailsOccupationalSafety.PATH, 'put');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('contractId', params.contractId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SafetyDto>;
    })
  );
}

updateContractDetailsOccupationalSafety.PATH = '/customers/{customerId}/contracts/{contractId}/occupational-safety';
