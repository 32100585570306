/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createInitialLetterForCustomer } from '../fn/initial-letter-controller/create-initial-letter-for-customer';
import { CreateInitialLetterForCustomer$Params } from '../fn/initial-letter-controller/create-initial-letter-for-customer';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { getCustomersWhereInitialLetterIsNeeded } from '../fn/initial-letter-controller/get-customers-where-initial-letter-is-needed';
import { GetCustomersWhereInitialLetterIsNeeded$Params } from '../fn/initial-letter-controller/get-customers-where-initial-letter-is-needed';
import { ResponseMediumCustomerDto } from '../models/response-medium-customer-dto';


/**
 * Erstanschreiben
 */
@Injectable({ providedIn: 'root' })
export class InitialLetterControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createInitialLetterForCustomer()` */
  static readonly CreateInitialLetterForCustomerPath = '/customers/initial-letter/{customerId}';

  /**
   * Creates an initial letter document for a customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInitialLetterForCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInitialLetterForCustomer$Response(params: CreateInitialLetterForCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return createInitialLetterForCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates an initial letter document for a customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInitialLetterForCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInitialLetterForCustomer(params: CreateInitialLetterForCustomer$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.createInitialLetterForCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `getCustomersWhereInitialLetterIsNeeded()` */
  static readonly GetCustomersWhereInitialLetterIsNeededPath = '/customers/initial-letter';

  /**
   * Returns a list of all ASD customers which have not yet had an initial letter
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersWhereInitialLetterIsNeeded()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWhereInitialLetterIsNeeded$Response(params?: GetCustomersWhereInitialLetterIsNeeded$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMediumCustomerDto>> {
    return getCustomersWhereInitialLetterIsNeeded(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all ASD customers which have not yet had an initial letter
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersWhereInitialLetterIsNeeded$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersWhereInitialLetterIsNeeded(params?: GetCustomersWhereInitialLetterIsNeeded$Params, context?: HttpContext): Observable<ResponseMediumCustomerDto> {
    return this.getCustomersWhereInitialLetterIsNeeded$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMediumCustomerDto>): ResponseMediumCustomerDto => r.body)
    );
  }

}
