/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TrainingContext } from '../../models/training-context';

export interface DeleteParticipant$Params {

/**
 * participant id
 */
  id: number;

/**
 * sht or fire-safety training
 */
  type: TrainingContext;
}

export function deleteParticipant(http: HttpClient, rootUrl: string, params: DeleteParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, deleteParticipant.PATH, 'delete');
  if (params) {
    rb.path('id', params.id, {});
    rb.path('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

deleteParticipant.PATH = '/training/{type}/participant/{id}';
