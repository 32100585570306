/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MeasureDto } from '../../models/measure-dto';

export interface CreateMeasure$Params {

/**
 * The customer id
 */
  customerId: number;

/**
 * The hazard id which contains the measures
 */
  hazardId: number;
      body: MeasureDto
}

export function createMeasure(http: HttpClient, rootUrl: string, params: CreateMeasure$Params, context?: HttpContext): Observable<StrictHttpResponse<MeasureDto>> {
  const rb = new RequestBuilder(rootUrl, createMeasure.PATH, 'post');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.query('hazardId', params.hazardId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MeasureDto>;
    })
  );
}

createMeasure.PATH = '/customers/{customerId}/measures';
