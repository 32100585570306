/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TrainingContext } from '../../models/training-context';
import { TrainingCustomerDto } from '../../models/training-customer-dto';

export interface GetTrainingCustomer$Params {

/**
 * customer id
 */
  customerId: number;

/**
 * training id
 */
  trainingId: number;

/**
 * type of training (standard, fire safety, SHT)
 */
  type: TrainingContext;
}

export function getTrainingCustomer(http: HttpClient, rootUrl: string, params: GetTrainingCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TrainingCustomerDto>> {
  const rb = new RequestBuilder(rootUrl, getTrainingCustomer.PATH, 'get');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.path('trainingId', params.trainingId, {});
    rb.path('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TrainingCustomerDto>;
    })
  );
}

getTrainingCustomer.PATH = '/training/{type}/{trainingId}/customers/{customerId}';
