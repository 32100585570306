/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerBranchOffice } from '../fn/customer-branch-office-controller/create-customer-branch-office';
import { CreateCustomerBranchOffice$Params } from '../fn/customer-branch-office-controller/create-customer-branch-office';
import { CustomerBranchOfficeDto } from '../models/customer-branch-office-dto';
import { deleteCustomerBranchOffice } from '../fn/customer-branch-office-controller/delete-customer-branch-office';
import { DeleteCustomerBranchOffice$Params } from '../fn/customer-branch-office-controller/delete-customer-branch-office';
import { getCustomerBranchOffices } from '../fn/customer-branch-office-controller/get-customer-branch-offices';
import { GetCustomerBranchOffices$Params } from '../fn/customer-branch-office-controller/get-customer-branch-offices';
import { updateCustomerBranchOffice } from '../fn/customer-branch-office-controller/update-customer-branch-office';
import { UpdateCustomerBranchOffice$Params } from '../fn/customer-branch-office-controller/update-customer-branch-office';


/**
 * Manage and validate customers branch offices
 */
@Injectable({ providedIn: 'root' })
export class CustomerBranchOfficeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCustomerBranchOffice()` */
  static readonly UpdateCustomerBranchOfficePath = '/customers/{customerId}/branch-offices/{branchOfficeId}';

  /**
   * update customers branch office
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerBranchOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerBranchOffice$Response(params: UpdateCustomerBranchOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBranchOfficeDto>> {
    return updateCustomerBranchOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * update customers branch office
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerBranchOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerBranchOffice(params: UpdateCustomerBranchOffice$Params, context?: HttpContext): Observable<CustomerBranchOfficeDto> {
    return this.updateCustomerBranchOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBranchOfficeDto>): CustomerBranchOfficeDto => r.body)
    );
  }

  /** Path part for operation `deleteCustomerBranchOffice()` */
  static readonly DeleteCustomerBranchOfficePath = '/customers/{customerId}/branch-offices/{branchOfficeId}';

  /**
   * delete customers branch office
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerBranchOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBranchOffice$Response(params: DeleteCustomerBranchOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCustomerBranchOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * delete customers branch office
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerBranchOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBranchOffice(params: DeleteCustomerBranchOffice$Params, context?: HttpContext): Observable<void> {
    return this.deleteCustomerBranchOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCustomerBranchOffices()` */
  static readonly GetCustomerBranchOfficesPath = '/customers/{customerId}/branch-offices';

  /**
   * Get customers branch offices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBranchOffices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBranchOffices$Response(params: GetCustomerBranchOffices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerBranchOfficeDto>>> {
    return getCustomerBranchOffices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customers branch offices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBranchOffices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBranchOffices(params: GetCustomerBranchOffices$Params, context?: HttpContext): Observable<Array<CustomerBranchOfficeDto>> {
    return this.getCustomerBranchOffices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerBranchOfficeDto>>): Array<CustomerBranchOfficeDto> => r.body)
    );
  }

  /** Path part for operation `createCustomerBranchOffice()` */
  static readonly CreateCustomerBranchOfficePath = '/customers/{customerId}/branch-offices';

  /**
   * create customers branch office
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBranchOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerBranchOffice$Response(params: CreateCustomerBranchOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBranchOfficeDto>> {
    return createCustomerBranchOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * create customers branch office
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBranchOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerBranchOffice(params: CreateCustomerBranchOffice$Params, context?: HttpContext): Observable<CustomerBranchOfficeDto> {
    return this.createCustomerBranchOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBranchOfficeDto>): CustomerBranchOfficeDto => r.body)
    );
  }

}
