import { NgModule } from '@angular/core';

import { BillableServiceAsdlockModule } from '@myasi/api/billable-service/asdlock';
import { BillableServiceBudgetModule } from '@myasi/api/billable-service/budget';
import { BillableServiceCoreModule } from '@myasi/api/billable-service/core';
import { BillableServiceCustomercontingentModule } from '@myasi/api/billable-service/customercontingent';
import { BillableServiceKeyfigureModule } from '@myasi/api/billable-service/keyfigure';
import { BillableServiceQuarterModule } from '@myasi/api/billable-service/quarter';
import { BillableServiceSysteminfoModule } from '@myasi/api/billable-service/systeminfo';
import { BillableServiceWorkinghoursModule } from '@myasi/api/billable-service/workinghours';
import { CustomersActivationModule } from '@myasi/api/customers/activation';
import { CustomersAddressesModule } from '@myasi/api/customers/addresses';
import { CustomersBillingModule } from '@myasi/api/customers/billing';
import { CustomersBusinesssectorsModule } from '@myasi/api/customers/businesssectors';
import { CustomersCompanydescriptionModule } from '@myasi/api/customers/companydescription';
import { CustomersContactsModule } from '@myasi/api/customers/contacts';
import { CustomersContingentModule } from '@myasi/api/customers/contingent';
import { CustomersContractsModule } from '@myasi/api/customers/contracts';
import { CustomersCoreModule } from '@myasi/api/customers/core';
import { CustomersCountriesModule } from '@myasi/api/customers/countries';
import { CustomersDocumentsModule } from '@myasi/api/customers/documents';
import { CustomersProjectModule } from '@myasi/api/customers/project';
import { CustomersRiskassessmentModule } from '@myasi/api/customers/riskassessment';
import { CustomersSecurity_expertsModule } from '@myasi/api/customers/security_experts';
import { CustomersSvginfoModule } from '@myasi/api/customers/svginfo';
import { CustomersSysteminfoModule } from '@myasi/api/customers/systeminfo';
import { CustomersTagsModule } from '@myasi/api/customers/tags';
import { CustomersTrainingModule } from '@myasi/api/customers/training';
import { CustomersUsersModule } from '@myasi/api/customers/users';
import { CustomersVehiclesModule } from '@myasi/api/customers/vehicles';
import { DocumentModule } from '@myasi/api/document';
import { MyasiAuthPermissionsModule } from '@myasi/api/myasi-auth/permissions';
import { MyasiAuthSysteminfoModule } from '@myasi/api/myasi-auth/systeminfo';
import { ExcelModule } from '@z-service/api/excel';
import { ReleaseNotesModule } from '@z-service/api/release-notes';

import { API_BASE_URLS } from '../environments/api-base-urls';

@NgModule({
	imports: [
		MyasiAuthPermissionsModule.forRoot({ rootUrl: API_BASE_URLS.auth }),
		MyasiAuthSysteminfoModule.forRoot({ rootUrl: API_BASE_URLS.auth }),
		BillableServiceAsdlockModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceBudgetModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceCoreModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceCustomercontingentModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceKeyfigureModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceQuarterModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceWorkinghoursModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		BillableServiceSysteminfoModule.forRoot({ rootUrl: API_BASE_URLS.billableService }),
		CustomersActivationModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersAddressesModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersBillingModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersBusinesssectorsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersCompanydescriptionModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersContactsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersContingentModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersContractsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersCoreModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersCountriesModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersDocumentsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersProjectModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersRiskassessmentModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersSecurity_expertsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersSvginfoModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersTagsModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersTrainingModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersUsersModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersVehiclesModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		CustomersSysteminfoModule.forRoot({ rootUrl: API_BASE_URLS.customers }),
		DocumentModule.forRoot({ rootUrl: API_BASE_URLS.document }),
		ExcelModule.forRoot({ rootUrl: API_BASE_URLS.excel }),
		ReleaseNotesModule.forRoot({ rootUrl: API_BASE_URLS.releaseNotes }),
	],
})
export class ApiConfigurationModule {}
