import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent, PageNotFoundModule } from '@myasi/shared/page-not-found';
import { ErrorContentComponent, ErrorModule, NewReleaseChunkErrorPageComponent } from '@svg-frontends/error';
import { InitialAuthenticationGuard } from '@svg-frontends/session';

const ROUTES: Routes = [
	{
		loadChildren: () => import('./feature/public-base/public-base.module').then((module) => module.PublicBaseModule),
		path: 'public',
	},
	{ path: 'error', component: ErrorContentComponent },
	{ path: 'update-error', component: NewReleaseChunkErrorPageComponent },
	{
		canActivate: [InitialAuthenticationGuard],
		loadChildren: () => import('./feature/app-base/app-base.module').then((module) => module.AppBaseModule),
		path: '',
	},
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
	exports: [RouterModule],
	imports: [
		ErrorModule,
		PageNotFoundModule,
		RouterModule.forRoot(ROUTES, {
			// `anchorScrolling` needs to be disabled for oauth implicit flow
			anchorScrolling: 'disabled',
			preloadingStrategy: NoPreloading,
			relativeLinkResolution: 'corrected',
			scrollPositionRestoration: 'enabled',
			// `urlUpdateStrategy` default is `deferred`, but eager is needed for clean url after login via oauth2
			urlUpdateStrategy: 'eager',
		}),
	],
})
export class AppRoutingModule {}
