/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentMetadataDto } from '../models/document-metadata-dto';
import { mergeDocuments } from '../fn/document-generator-controller/merge-documents';
import { MergeDocuments$Params } from '../fn/document-generator-controller/merge-documents';
import { mergeDocumentsPersist } from '../fn/document-generator-controller/merge-documents-persist';
import { MergeDocumentsPersist$Params } from '../fn/document-generator-controller/merge-documents-persist';
import { previewDocument } from '../fn/document-generator-controller/preview-document';
import { PreviewDocument$Params } from '../fn/document-generator-controller/preview-document';

@Injectable({ providedIn: 'root' })
export class DocumentGeneratorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mergeDocuments()` */
  static readonly MergeDocumentsPath = '/documents/merge-documents';

  /**
   * Generate merge of files in the cache (will not be saved in the database, only in Azure Blob Storage).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergeDocuments$Response(params: MergeDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return mergeDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate merge of files in the cache (will not be saved in the database, only in Azure Blob Storage).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergeDocuments(params: MergeDocuments$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.mergeDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `mergeDocumentsPersist()` */
  static readonly MergeDocumentsPersistPath = '/documents/merge-documents-persist';

  /**
   * Generate merge of files in the cache (will not be saved in the database, only in Azure Blob Storage).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeDocumentsPersist()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergeDocumentsPersist$Response(params: MergeDocumentsPersist$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMetadataDto>> {
    return mergeDocumentsPersist(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate merge of files in the cache (will not be saved in the database, only in Azure Blob Storage).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeDocumentsPersist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mergeDocumentsPersist(params: MergeDocumentsPersist$Params, context?: HttpContext): Observable<DocumentMetadataDto> {
    return this.mergeDocumentsPersist$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMetadataDto>): DocumentMetadataDto => r.body)
    );
  }

  /** Path part for operation `previewDocument()` */
  static readonly PreviewDocumentPath = '/documents/merge-documents-preview';

  /**
   * Generate a preview of merged files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `previewDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  previewDocument$Response(params: PreviewDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return previewDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate a preview of merged files
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `previewDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  previewDocument(params: PreviewDocument$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.previewDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

}
