/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteAllAndCreateNew } from '../fn/hazard-controller/delete-all-and-create-new';
import { DeleteAllAndCreateNew$Params } from '../fn/hazard-controller/delete-all-and-create-new';
import { deleteHazard } from '../fn/hazard-controller/delete-hazard';
import { DeleteHazard$Params } from '../fn/hazard-controller/delete-hazard';
import { getHazardsForRiskAssessment } from '../fn/hazard-controller/get-hazards-for-risk-assessment';
import { GetHazardsForRiskAssessment$Params } from '../fn/hazard-controller/get-hazards-for-risk-assessment';
import { HazardDto } from '../models/hazard-dto';


/**
 * This controller handles all operations on hazards. Multiple hazards can be assigned to a hazard factor.
 */
@Injectable({ providedIn: 'root' })
export class HazardControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deleteAllAndCreateNew()` */
  static readonly DeleteAllAndCreateNewPath = '/customers/{customerId}/hazards/delete-all-and-create-new';

  /**
   * Create and update a hazard and its measures.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllAndCreateNew()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAllAndCreateNew$Response(params: DeleteAllAndCreateNew$Params, context?: HttpContext): Observable<StrictHttpResponse<HazardDto>> {
    return deleteAllAndCreateNew(this.http, this.rootUrl, params, context);
  }

  /**
   * Create and update a hazard and its measures.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllAndCreateNew$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteAllAndCreateNew(params: DeleteAllAndCreateNew$Params, context?: HttpContext): Observable<HazardDto> {
    return this.deleteAllAndCreateNew$Response(params, context).pipe(
      map((r: StrictHttpResponse<HazardDto>): HazardDto => r.body)
    );
  }

  /** Path part for operation `getHazardsForRiskAssessment()` */
  static readonly GetHazardsForRiskAssessmentPath = '/customers/{customerId}/hazards';

  /**
   * Get all hazards for a customer risk assessment and a hazard factor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHazardsForRiskAssessment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHazardsForRiskAssessment$Response(params: GetHazardsForRiskAssessment$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HazardDto>>> {
    return getHazardsForRiskAssessment(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all hazards for a customer risk assessment and a hazard factor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHazardsForRiskAssessment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHazardsForRiskAssessment(params: GetHazardsForRiskAssessment$Params, context?: HttpContext): Observable<Array<HazardDto>> {
    return this.getHazardsForRiskAssessment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HazardDto>>): Array<HazardDto> => r.body)
    );
  }

  /** Path part for operation `deleteHazard()` */
  static readonly DeleteHazardPath = '/customers/{customerId}/hazards/{hazardId}';

  /**
   * Delete  hazard for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHazard()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHazard$Response(params: DeleteHazard$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteHazard(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete  hazard for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteHazard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHazard(params: DeleteHazard$Params, context?: HttpContext): Observable<void> {
    return this.deleteHazard$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
