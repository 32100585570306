/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createParticipant } from '../fn/participants-controller/create-participant';
import { CreateParticipant$Params } from '../fn/participants-controller/create-participant';
import { deleteParticipant } from '../fn/participants-controller/delete-participant';
import { DeleteParticipant$Params } from '../fn/participants-controller/delete-participant';
import { getParticipants } from '../fn/participants-controller/get-participants';
import { GetParticipants$Params } from '../fn/participants-controller/get-participants';
import { ParticipantDto } from '../models/participant-dto';
import { updateParticipant } from '../fn/participants-controller/update-participant';
import { UpdateParticipant$Params } from '../fn/participants-controller/update-participant';

@Injectable({ providedIn: 'root' })
export class ParticipantsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateParticipant()` */
  static readonly UpdateParticipantPath = '/training/{type}/{trainingId}/participant/{id}';

  /**
   * update participant of training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipant$Response(params: UpdateParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
    return updateParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * update participant of training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParticipant(params: UpdateParticipant$Params, context?: HttpContext): Observable<ParticipantDto> {
    return this.updateParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantDto>): ParticipantDto => r.body)
    );
  }

  /** Path part for operation `createParticipant()` */
  static readonly CreateParticipantPath = '/training/{type}/{trainingId}/participant/';

  /**
   * add new participant to training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createParticipant$Response(params: CreateParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<ParticipantDto>> {
    return createParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * add new participant to training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createParticipant(params: CreateParticipant$Params, context?: HttpContext): Observable<ParticipantDto> {
    return this.createParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParticipantDto>): ParticipantDto => r.body)
    );
  }

  /** Path part for operation `getParticipants()` */
  static readonly GetParticipantsPath = '/training/{type}/{trainingId}/customer/{customerId}/participants';

  /**
   * get participants of a training customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipants$Response(params: GetParticipants$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ParticipantDto>>> {
    return getParticipants(this.http, this.rootUrl, params, context);
  }

  /**
   * get participants of a training customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParticipants(params: GetParticipants$Params, context?: HttpContext): Observable<Array<ParticipantDto>> {
    return this.getParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ParticipantDto>>): Array<ParticipantDto> => r.body)
    );
  }

  /** Path part for operation `deleteParticipant()` */
  static readonly DeleteParticipantPath = '/training/{type}/participant/{id}';

  /**
   * remove a participant from training
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteParticipant$Response(params: DeleteParticipant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteParticipant(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a participant from training
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteParticipant(params: DeleteParticipant$Params, context?: HttpContext): Observable<void> {
    return this.deleteParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
