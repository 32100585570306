/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { lockDocument } from '../fn/external-document-controller/lock-document';
import { LockDocument$Params } from '../fn/external-document-controller/lock-document';
import { releaseDocument } from '../fn/external-document-controller/release-document';
import { ReleaseDocument$Params } from '../fn/external-document-controller/release-document';


/**
 * Release documents operations.
 */
@Injectable({ providedIn: 'root' })
export class ExternalDocumentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `releaseDocument()` */
  static readonly ReleaseDocumentPath = '/documents/external/{documentId}/release';

  /**
   * Release an external  document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `releaseDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  releaseDocument$Response(params: ReleaseDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return releaseDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Release an external  document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `releaseDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  releaseDocument(params: ReleaseDocument$Params, context?: HttpContext): Observable<void> {
    return this.releaseDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `lockDocument()` */
  static readonly LockDocumentPath = '/documents/external/{documentId}/lock';

  /**
   * Lock an external document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockDocument$Response(params: LockDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return lockDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock an external document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockDocument(params: LockDocument$Params, context?: HttpContext): Observable<void> {
    return this.lockDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
