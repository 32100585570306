/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DefaultWorkingTimeOverdraftDto } from '../models/default-working-time-overdraft-dto';
import { getAllDefaultWorkingTimeOverdrafts } from '../fn/default-working-time-overdraft-controller/get-all-default-working-time-overdrafts';
import { GetAllDefaultWorkingTimeOverdrafts$Params } from '../fn/default-working-time-overdraft-controller/get-all-default-working-time-overdrafts';
import { getDefaultWorkingTimeOverdraft } from '../fn/default-working-time-overdraft-controller/get-default-working-time-overdraft';
import { GetDefaultWorkingTimeOverdraft$Params } from '../fn/default-working-time-overdraft-controller/get-default-working-time-overdraft';
import { getDefaultWorkingTimeOverdraftsBySvgId } from '../fn/default-working-time-overdraft-controller/get-default-working-time-overdrafts-by-svg-id';
import { GetDefaultWorkingTimeOverdraftsBySvgId$Params } from '../fn/default-working-time-overdraft-controller/get-default-working-time-overdrafts-by-svg-id';
import { saveOrUpdate } from '../fn/default-working-time-overdraft-controller/save-or-update';
import { SaveOrUpdate$Params } from '../fn/default-working-time-overdraft-controller/save-or-update';


/**
 * Manage the Default Working Time Overdrafts across SVGs
 */
@Injectable({ providedIn: 'root' })
export class DefaultWorkingTimeOverdraftControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllDefaultWorkingTimeOverdrafts()` */
  static readonly GetAllDefaultWorkingTimeOverdraftsPath = '/working-time-overdrafts';

  /**
   * Get all default working time overdrafts across all SVGs; mapped by the SVG ID and Working Time Type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDefaultWorkingTimeOverdrafts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultWorkingTimeOverdrafts$Response(params?: GetAllDefaultWorkingTimeOverdrafts$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: DefaultWorkingTimeOverdraftDto;
};
}>> {
    return getAllDefaultWorkingTimeOverdrafts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all default working time overdrafts across all SVGs; mapped by the SVG ID and Working Time Type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDefaultWorkingTimeOverdrafts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultWorkingTimeOverdrafts(params?: GetAllDefaultWorkingTimeOverdrafts$Params, context?: HttpContext): Observable<{
[key: string]: {
[key: string]: DefaultWorkingTimeOverdraftDto;
};
}> {
    return this.getAllDefaultWorkingTimeOverdrafts$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: DefaultWorkingTimeOverdraftDto;
};
}>): {
[key: string]: {
[key: string]: DefaultWorkingTimeOverdraftDto;
};
} => r.body)
    );
  }

  /** Path part for operation `saveOrUpdate()` */
  static readonly SaveOrUpdatePath = '/working-time-overdrafts';

  /**
   * Updates in bulk each of the given defaults for the working time overdraft.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOrUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOrUpdate$Response(params: SaveOrUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DefaultWorkingTimeOverdraftDto>>> {
    return saveOrUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates in bulk each of the given defaults for the working time overdraft.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveOrUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOrUpdate(params: SaveOrUpdate$Params, context?: HttpContext): Observable<Array<DefaultWorkingTimeOverdraftDto>> {
    return this.saveOrUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DefaultWorkingTimeOverdraftDto>>): Array<DefaultWorkingTimeOverdraftDto> => r.body)
    );
  }

  /** Path part for operation `getDefaultWorkingTimeOverdraftsBySvgId()` */
  static readonly GetDefaultWorkingTimeOverdraftsBySvgIdPath = '/working-time-overdrafts/svg/{svgId}';

  /**
   * Get all default working time overdrafts for ONE SVG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultWorkingTimeOverdraftsBySvgId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultWorkingTimeOverdraftsBySvgId$Response(params: GetDefaultWorkingTimeOverdraftsBySvgId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DefaultWorkingTimeOverdraftDto>>> {
    return getDefaultWorkingTimeOverdraftsBySvgId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all default working time overdrafts for ONE SVG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultWorkingTimeOverdraftsBySvgId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultWorkingTimeOverdraftsBySvgId(params: GetDefaultWorkingTimeOverdraftsBySvgId$Params, context?: HttpContext): Observable<Array<DefaultWorkingTimeOverdraftDto>> {
    return this.getDefaultWorkingTimeOverdraftsBySvgId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DefaultWorkingTimeOverdraftDto>>): Array<DefaultWorkingTimeOverdraftDto> => r.body)
    );
  }

  /** Path part for operation `getDefaultWorkingTimeOverdraft()` */
  static readonly GetDefaultWorkingTimeOverdraftPath = '/working-time-overdrafts/svg/{svgId}/working-type/{workingTimeType}';

  /**
   * Get the default working time overdrafts for ONE SVG and given working time type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultWorkingTimeOverdraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultWorkingTimeOverdraft$Response(params: GetDefaultWorkingTimeOverdraft$Params, context?: HttpContext): Observable<StrictHttpResponse<DefaultWorkingTimeOverdraftDto>> {
    return getDefaultWorkingTimeOverdraft(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the default working time overdrafts for ONE SVG and given working time type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultWorkingTimeOverdraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultWorkingTimeOverdraft(params: GetDefaultWorkingTimeOverdraft$Params, context?: HttpContext): Observable<DefaultWorkingTimeOverdraftDto> {
    return this.getDefaultWorkingTimeOverdraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<DefaultWorkingTimeOverdraftDto>): DefaultWorkingTimeOverdraftDto => r.body)
    );
  }

}
