/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllSalutations } from '../fn/contact-salutation-controller/get-all-salutations';
import { GetAllSalutations$Params } from '../fn/contact-salutation-controller/get-all-salutations';
import { SalutationDto } from '../models/salutation-dto';

@Injectable({ providedIn: 'root' })
export class ContactSalutationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSalutations()` */
  static readonly GetAllSalutationsPath = '/salutations';

  /**
   * get all available salutations. zB: Herr, Frau...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSalutations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalutations$Response(params?: GetAllSalutations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SalutationDto>>> {
    return getAllSalutations(this.http, this.rootUrl, params, context);
  }

  /**
   * get all available salutations. zB: Herr, Frau...
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSalutations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSalutations(params?: GetAllSalutations$Params, context?: HttpContext): Observable<Array<SalutationDto>> {
    return this.getAllSalutations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SalutationDto>>): Array<SalutationDto> => r.body)
    );
  }

}
