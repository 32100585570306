/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyDescriptionDto } from '../models/company-description-dto';
import { deleteCompanyDescription } from '../fn/company-description-controller/delete-company-description';
import { DeleteCompanyDescription$Params } from '../fn/company-description-controller/delete-company-description';
import { getCompanyDescription } from '../fn/company-description-controller/get-company-description';
import { GetCompanyDescription$Params } from '../fn/company-description-controller/get-company-description';
import { updateCompanyDescription } from '../fn/company-description-controller/update-company-description';
import { UpdateCompanyDescription$Params } from '../fn/company-description-controller/update-company-description';


/**
 * The controller for CRUD operations on company descriptions
 */
@Injectable({ providedIn: 'root' })
export class CompanyDescriptionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyDescription()` */
  static readonly GetCompanyDescriptionPath = '/customers/{customerId}/company-description';

  /**
   * Get a company description for the customer's company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDescription$Response(params: GetCompanyDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDescriptionDto>> {
    return getCompanyDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a company description for the customer's company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDescription(params: GetCompanyDescription$Params, context?: HttpContext): Observable<CompanyDescriptionDto> {
    return this.getCompanyDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDescriptionDto>): CompanyDescriptionDto => r.body)
    );
  }

  /** Path part for operation `deleteCompanyDescription()` */
  static readonly DeleteCompanyDescriptionPath = '/customers/{customerId}/company-description';

  /**
   * Delete a companyÂ´s description
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDescription$Response(params: DeleteCompanyDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a companyÂ´s description
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDescription(params: DeleteCompanyDescription$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCompanyDescription()` */
  static readonly UpdateCompanyDescriptionPath = '/customers/{customerId}/company-description';

  /**
   * Update the description for the customer's company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCompanyDescription$Response(params: UpdateCompanyDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDescriptionDto>> {
    return updateCompanyDescription(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the description for the customer's company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCompanyDescription(params: UpdateCompanyDescription$Params, context?: HttpContext): Observable<CompanyDescriptionDto> {
    return this.updateCompanyDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyDescriptionDto>): CompanyDescriptionDto => r.body)
    );
  }

}
