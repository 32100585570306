/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseShtTrainingDto } from '../../models/response-sht-training-dto';

export interface GetAllShtTrainings$Params {

/**
 * filter for only billed or not billed sht trainings
 */
  isBilled: boolean;

/**
 * filter for the sht training id
 */
  id?: string;

/**
 * filter for the type of training id
 */
  typeId?: string;

/**
 * filter for the training day from/to
 */
  date?: string;

/**
 * filter for the time span during which the training is held (e.g. 9:00-17.00)
 */
  dailyHours?: string;

/**
 * place id of training
 */
  placeId?: string;

/**
 * match code
 */
  matchCode?: string;

/**
 * filter for the security expert id
 */
  securityExpertId?: string;

/**
 * moderator id
 */
  moderatorId?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getAllShtTrainings(http: HttpClient, rootUrl: string, params: GetAllShtTrainings$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseShtTrainingDto>> {
  const rb = new RequestBuilder(rootUrl, getAllShtTrainings.PATH, 'get');
  if (params) {
    rb.query('isBilled', params.isBilled, {});
    rb.query('id', params.id, {});
    rb.query('typeId', params.typeId, {});
    rb.query('date', params.date, {});
    rb.query('dailyHours', params.dailyHours, {});
    rb.query('placeId', params.placeId, {});
    rb.query('matchCode', params.matchCode, {});
    rb.query('securityExpertId', params.securityExpertId, {});
    rb.query('moderatorId', params.moderatorId, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseShtTrainingDto>;
    })
  );
}

getAllShtTrainings.PATH = '/sht-training';
