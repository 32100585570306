import { AsiCustomerDto, AsiSvgInfoDto, AsiUserLevel } from '@myasi/api/myasi-auth/permissions';

import { isDefined } from '@mysvg/utils';

import { ContextState } from '../enums/context-state.enum';
import { Context } from './context.model';

export class AsiContext implements Context {
	constructor(
		public activities: string[],
		public state: ContextState = ContextState.NOT_LOGGED_IN,
		// optionals (set depending on state)
		public asdRegisterNumber?: string,
		public customerInfo?: AsiCustomerDto,
		public email?: string,
		public firstName?: string,
		public id?: number,
		public lastName?: string,
		public level?: AsiUserLevel,
		public projectIds?: number[], // project id,
		public svgInfo?: AsiSvgInfoDto,
		public userName?: string,
	) {}

	getName(): string {
		return isDefined(this.firstName) && isDefined(this.lastName) ? `${this.firstName} ${this.lastName}` : this.userName;
	}

	isStaffState(): boolean {
		return this.state === ContextState.STAFF;
	}

	isCustomerState(): boolean {
		return this.state === ContextState.STAFF_CUSTOMER;
	}

	/**
	 * [NOTE]: Token and userInfo must be set.
	 */
	isNotLoggedInState(): boolean {
		return this.state === ContextState.NOT_LOGGED_IN;
	}
}
