/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTemplateCategory } from '../fn/risk-assessment-template-controller/create-template-category';
import { CreateTemplateCategory$Params } from '../fn/risk-assessment-template-controller/create-template-category';
import { createTemplateFromRiskAssessment } from '../fn/risk-assessment-template-controller/create-template-from-risk-assessment';
import { CreateTemplateFromRiskAssessment$Params } from '../fn/risk-assessment-template-controller/create-template-from-risk-assessment';
import { deleteTemplate } from '../fn/risk-assessment-template-controller/delete-template';
import { DeleteTemplate$Params } from '../fn/risk-assessment-template-controller/delete-template';
import { getAsdTemplates } from '../fn/risk-assessment-template-controller/get-asd-templates';
import { GetAsdTemplates$Params } from '../fn/risk-assessment-template-controller/get-asd-templates';
import { getTemplates } from '../fn/risk-assessment-template-controller/get-templates';
import { GetTemplates$Params } from '../fn/risk-assessment-template-controller/get-templates';
import { patchDeleteChecklist } from '../fn/risk-assessment-template-controller/patch-delete-checklist';
import { PatchDeleteChecklist$Params } from '../fn/risk-assessment-template-controller/patch-delete-checklist';
import { TemplateNodeDto } from '../models/template-node-dto';
import { updateTemplate } from '../fn/risk-assessment-template-controller/update-template';
import { UpdateTemplate$Params } from '../fn/risk-assessment-template-controller/update-template';
import { uploadChecklistTemplate } from '../fn/risk-assessment-template-controller/upload-checklist-template';
import { UploadChecklistTemplate$Params } from '../fn/risk-assessment-template-controller/upload-checklist-template';


/**
 * Controller handles the templates of standard risk assessments. This is the top level of the so called 'standards'
 */
@Injectable({ providedIn: 'root' })
export class RiskAssessmentTemplateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateTemplate()` */
  static readonly UpdateTemplatePath = '/risk-assessment-templates/{id}';

  /**
   * Update all kinds of templates (e.g. also template category)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate$Response(params: UpdateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateNodeDto>> {
    return updateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update all kinds of templates (e.g. also template category)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate(params: UpdateTemplate$Params, context?: HttpContext): Observable<TemplateNodeDto> {
    return this.updateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateNodeDto>): TemplateNodeDto => r.body)
    );
  }

  /** Path part for operation `deleteTemplate()` */
  static readonly DeleteTemplatePath = '/risk-assessment-templates/{id}';

  /**
   * Deletes all kinds of templates (e.g. also template category)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate$Response(params: DeleteTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes all kinds of templates (e.g. also template category)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate(params: DeleteTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTemplates()` */
  static readonly GetTemplatesPath = '/risk-assessment-templates';

  /**
   * Get all templates of standard risk assessments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates$Response(params?: GetTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateNodeDto>>> {
    return getTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all templates of standard risk assessments
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates(params?: GetTemplates$Params, context?: HttpContext): Observable<Array<TemplateNodeDto>> {
    return this.getTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateNodeDto>>): Array<TemplateNodeDto> => r.body)
    );
  }

  /** Path part for operation `createTemplateCategory()` */
  static readonly CreateTemplateCategoryPath = '/risk-assessment-templates';

  /**
   * Create a new template category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplateCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplateCategory$Response(params: CreateTemplateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateNodeDto>> {
    return createTemplateCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new template category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemplateCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplateCategory(params: CreateTemplateCategory$Params, context?: HttpContext): Observable<TemplateNodeDto> {
    return this.createTemplateCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateNodeDto>): TemplateNodeDto => r.body)
    );
  }

  /** Path part for operation `uploadChecklistTemplate()` */
  static readonly UploadChecklistTemplatePath = '/risk-assessment-templates/{id}/upload-checklist';

  /**
   * Upload a checklist template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadChecklistTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistTemplate$Response(params: UploadChecklistTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateNodeDto>> {
    return uploadChecklistTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a checklist template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadChecklistTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistTemplate(params: UploadChecklistTemplate$Params, context?: HttpContext): Observable<TemplateNodeDto> {
    return this.uploadChecklistTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateNodeDto>): TemplateNodeDto => r.body)
    );
  }

  /** Path part for operation `createTemplateFromRiskAssessment()` */
  static readonly CreateTemplateFromRiskAssessmentPath = '/risk-assessment-templates/create-by-risk-assessment';

  /**
   * Create a new template with a given risk assessment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplateFromRiskAssessment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplateFromRiskAssessment$Response(params: CreateTemplateFromRiskAssessment$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateNodeDto>> {
    return createTemplateFromRiskAssessment(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new template with a given risk assessment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemplateFromRiskAssessment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplateFromRiskAssessment(params: CreateTemplateFromRiskAssessment$Params, context?: HttpContext): Observable<TemplateNodeDto> {
    return this.createTemplateFromRiskAssessment$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateNodeDto>): TemplateNodeDto => r.body)
    );
  }

  /** Path part for operation `patchDeleteChecklist()` */
  static readonly PatchDeleteChecklistPath = '/risk-assessment-templates/{id}/delete-checklist';

  /**
   * Update the checklistTemplateId associated to this template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDeleteChecklist()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchDeleteChecklist$Response(params: PatchDeleteChecklist$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateNodeDto>> {
    return patchDeleteChecklist(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the checklistTemplateId associated to this template
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDeleteChecklist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchDeleteChecklist(params: PatchDeleteChecklist$Params, context?: HttpContext): Observable<TemplateNodeDto> {
    return this.patchDeleteChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateNodeDto>): TemplateNodeDto => r.body)
    );
  }

  /** Path part for operation `getAsdTemplates()` */
  static readonly GetAsdTemplatesPath = '/risk-assessment-templates/asd';

  /**
   * Get all templates of standard risk assessments only for ASD
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAsdTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdTemplates$Response(params?: GetAsdTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateNodeDto>>> {
    return getAsdTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all templates of standard risk assessments only for ASD
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAsdTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAsdTemplates(params?: GetAsdTemplates$Params, context?: HttpContext): Observable<Array<TemplateNodeDto>> {
    return this.getAsdTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateNodeDto>>): Array<TemplateNodeDto> => r.body)
    );
  }

}
