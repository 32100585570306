/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerDto } from '../models/customer-dto';
import { getSecurityExpert } from '../fn/customer-security-expert-controller/get-security-expert';
import { GetSecurityExpert$Params } from '../fn/customer-security-expert-controller/get-security-expert';
import { SecurityExpertDto } from '../models/security-expert-dto';
import { updateAssignmentSecurityExpert } from '../fn/customer-security-expert-controller/update-assignment-security-expert';
import { UpdateAssignmentSecurityExpert$Params } from '../fn/customer-security-expert-controller/update-assignment-security-expert';


/**
 * Provides operations for security experts that are managing the account of a customer.
 */
@Injectable({ providedIn: 'root' })
export class CustomerSecurityExpertControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSecurityExpert()` */
  static readonly GetSecurityExpertPath = '/customers/{customerId}/security-expert';

  /**
   * Get the account managing security expert of this customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecurityExpert()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpert$Response(params: GetSecurityExpert$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityExpertDto>> {
    return getSecurityExpert(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the account managing security expert of this customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecurityExpert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecurityExpert(params: GetSecurityExpert$Params, context?: HttpContext): Observable<SecurityExpertDto> {
    return this.getSecurityExpert$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityExpertDto>): SecurityExpertDto => r.body)
    );
  }

  /** Path part for operation `updateAssignmentSecurityExpert()` */
  static readonly UpdateAssignmentSecurityExpertPath = '/customers/{customerId}/security-expert';

  /**
   * Assign an account managing security expert to the customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignmentSecurityExpert()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignmentSecurityExpert$Response(params: UpdateAssignmentSecurityExpert$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDto>> {
    return updateAssignmentSecurityExpert(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign an account managing security expert to the customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignmentSecurityExpert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignmentSecurityExpert(params: UpdateAssignmentSecurityExpert$Params, context?: HttpContext): Observable<CustomerDto> {
    return this.updateAssignmentSecurityExpert$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDto>): CustomerDto => r.body)
    );
  }

}
