/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerContactDto } from '../models/customer-contact-dto';
import { getContactByCustomerId } from '../fn/customer-contact-controller/get-contact-by-customer-id';
import { GetContactByCustomerId$Params } from '../fn/customer-contact-controller/get-contact-by-customer-id';
import { updateCustomerContact } from '../fn/customer-contact-controller/update-customer-contact';
import { UpdateCustomerContact$Params } from '../fn/customer-contact-controller/update-customer-contact';


/**
 * CustomerContactController
 */
@Injectable({ providedIn: 'root' })
export class CustomerContactControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getContactByCustomerId()` */
  static readonly GetContactByCustomerIdPath = '/customer-contacts/{customerId}';

  /**
   * Get customers contact information which includes phoneNumber, faxNumber, mail, mobileNumber and the customers website.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContactByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactByCustomerId$Response(params: GetContactByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerContactDto>> {
    return getContactByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customers contact information which includes phoneNumber, faxNumber, mail, mobileNumber and the customers website.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContactByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactByCustomerId(params: GetContactByCustomerId$Params, context?: HttpContext): Observable<CustomerContactDto> {
    return this.getContactByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerContactDto>): CustomerContactDto => r.body)
    );
  }

  /** Path part for operation `updateCustomerContact()` */
  static readonly UpdateCustomerContactPath = '/customer-contacts/{customerId}';

  /**
   * update customers contact information which includes phoneNumber, faxNumber, mail, mobileNumber and the customers website.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerContact$Response(params: UpdateCustomerContact$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerContactDto>> {
    return updateCustomerContact(this.http, this.rootUrl, params, context);
  }

  /**
   * update customers contact information which includes phoneNumber, faxNumber, mail, mobileNumber and the customers website.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerContact(params: UpdateCustomerContact$Params, context?: HttpContext): Observable<CustomerContactDto> {
    return this.updateCustomerContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerContactDto>): CustomerContactDto => r.body)
    );
  }

}
