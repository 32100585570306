import { Level } from '@nsalaun/ng-logger';

import { ApplicationNamesEnum, EnvironmentModel, StagesEnum } from '@svg/environment';

export const ENVIRONMENT: EnvironmentModel = {
	apiInfix: 'api.staging.myasi.de',
	applicationName: ApplicationNamesEnum.myASI,
	clientId: 'myasi',
	enabledUpdateServiceWorker: true,
	logLevel: Level.INFO,
	name: StagesEnum.STAGING,
	scope: 'openid',
	sentry: {
		url: 'https://651b1988e89d441393db16b9f0b23f43@o1347961.ingest.sentry.io/6631214',
		tracesSampleRate: 1,
	},
	tokenIssuer: 'https://staging.svg-auth.de/realms/myasi',
};
