/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CompanyDescriptionDto } from '../../models/company-description-dto';

export interface UpdateCompanyDescription$Params {

/**
 * the customer technical id
 */
  customerId: number;

/**
 * the description of the company
 */
  companyDescription: string;
}

export function updateCompanyDescription(http: HttpClient, rootUrl: string, params: UpdateCompanyDescription$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyDescriptionDto>> {
  const rb = new RequestBuilder(rootUrl, updateCompanyDescription.PATH, 'patch');
  if (params) {
    rb.path('customerId', params.customerId, {});
    rb.query('companyDescription', params.companyDescription, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CompanyDescriptionDto>;
    })
  );
}

updateCompanyDescription.PATH = '/customers/{customerId}/company-description';
