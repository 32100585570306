/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { CompanyDoctorControllerService } from './services/company-doctor-controller.service';
import { MedicalCenterControllerService } from './services/medical-center-controller.service';
import { AddressControllerService } from './services/address-controller.service';
import { AddressTypeControllerService } from './services/address-type-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CompanyDoctorControllerService,
    MedicalCenterControllerService,
    AddressControllerService,
    AddressTypeControllerService,
    ApiConfiguration
  ],
})
export class CustomersAddressesModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersAddressesModule> {
    return {
      ngModule: CustomersAddressesModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersAddressesModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersAddressesModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
