/* tslint:disable */
/* eslint-disable */

/**
 * the logical representation of the above raw value as traffic light with four states
 */
export enum RiskPriorityTrafficLight {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  RED = 'RED'
}
