/* tslint:disable */
/* eslint-disable */

/**
 * represents the state of participation
 */
export enum ParticipationStatus {
  PARTICIPATED = 'PARTICIPATED',
  NOT_PARTICIPATED = 'NOT_PARTICIPATED',
  CANCELLED = 'CANCELLED'
}
