/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ScopeOfCareControllerService } from './services/scope-of-care-controller.service';
import { RiskAssessmentTemplateControllerService } from './services/risk-assessment-template-controller.service';
import { RiskPriorityDetailsControllerService } from './services/risk-priority-details-controller.service';
import { RiskAssessmentControllerService } from './services/risk-assessment-controller.service';
import { MeasuresControllerService } from './services/measures-controller.service';
import { HealthSafetyOrganizationControllerService } from './services/health-safety-organization-controller.service';
import { HazardControllerService } from './services/hazard-controller.service';
import { RegulationControllerService } from './services/regulation-controller.service';
import { HazardFactorControllerService } from './services/hazard-factor-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ScopeOfCareControllerService,
    RiskAssessmentTemplateControllerService,
    RiskPriorityDetailsControllerService,
    RiskAssessmentControllerService,
    MeasuresControllerService,
    HealthSafetyOrganizationControllerService,
    HazardControllerService,
    RegulationControllerService,
    HazardFactorControllerService,
    ApiConfiguration
  ],
})
export class CustomersRiskassessmentModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersRiskassessmentModule> {
    return {
      ngModule: CustomersRiskassessmentModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersRiskassessmentModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersRiskassessmentModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
