/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getHazardFactors } from '../fn/hazard-factor-controller/get-hazard-factors';
import { GetHazardFactors$Params } from '../fn/hazard-factor-controller/get-hazard-factors';
import { HazardFactorDto } from '../models/hazard-factor-dto';
import { HazardFactorStatusDto } from '../models/hazard-factor-status-dto';
import { updateHazardFactors } from '../fn/hazard-factor-controller/update-hazard-factors';
import { UpdateHazardFactors$Params } from '../fn/hazard-factor-controller/update-hazard-factors';


/**
 * This controller handles all operations on hazard factors (aka as the cells of a risk assessment matrix)
 */
@Injectable({ providedIn: 'root' })
export class HazardFactorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHazardFactors()` */
  static readonly GetHazardFactorsPath = '/customers/{customerId}/hazard-factors';

  /**
   * Get all hazard factors of a parent risk assessment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHazardFactors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHazardFactors$Response(params: GetHazardFactors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HazardFactorDto>>> {
    return getHazardFactors(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all hazard factors of a parent risk assessment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHazardFactors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHazardFactors(params: GetHazardFactors$Params, context?: HttpContext): Observable<Array<HazardFactorDto>> {
    return this.getHazardFactors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HazardFactorDto>>): Array<HazardFactorDto> => r.body)
    );
  }

  /** Path part for operation `updateHazardFactors()` */
  static readonly UpdateHazardFactorsPath = '/customers/{customerId}/hazard-factors';

  /**
   * Patch hazard factors on the active property, disable or enable the hazard factor (the cell).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHazardFactors()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHazardFactors$Response(params: UpdateHazardFactors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HazardFactorStatusDto>>> {
    return updateHazardFactors(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch hazard factors on the active property, disable or enable the hazard factor (the cell).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateHazardFactors$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHazardFactors(params: UpdateHazardFactors$Params, context?: HttpContext): Observable<Array<HazardFactorStatusDto>> {
    return this.updateHazardFactors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HazardFactorStatusDto>>): Array<HazardFactorStatusDto> => r.body)
    );
  }

}
