/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseStandardTrainingDto } from '../../models/response-standard-training-dto';

export interface GetAllStandardTrainings$Params {

/**
 * filter for bille or not yet billed trainings
 */
  isBilled: boolean;

/**
 * filter for the training id
 */
  id?: string;

/**
 * filter training day date by range from/to
 */
  trainingDay?: string;

/**
 * filter hours of expected duration from/to
 */
  expectedDuration?: string;

/**
 * filter for the description
 */
  description?: string;

/**
 * filter for the asd training subject
 */
  trainingAsdSubject?: string;

/**
 * filter for the id of the training subject
 */
  trainingSubjectId?: string;

/**
 * filter for the id of the security expert
 */
  securityExpertId?: string;

/**
 * filter for the type of service
 */
  typeOfService?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getAllStandardTrainings(http: HttpClient, rootUrl: string, params: GetAllStandardTrainings$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseStandardTrainingDto>> {
  const rb = new RequestBuilder(rootUrl, getAllStandardTrainings.PATH, 'get');
  if (params) {
    rb.query('isBilled', params.isBilled, {});
    rb.query('id', params.id, {});
    rb.query('trainingDay', params.trainingDay, {});
    rb.query('expectedDuration', params.expectedDuration, {});
    rb.query('description', params.description, {});
    rb.query('trainingAsdSubject', params.trainingAsdSubject, {});
    rb.query('trainingSubjectId', params.trainingSubjectId, {});
    rb.query('securityExpertId', params.securityExpertId, {});
    rb.query('typeOfService', params.typeOfService, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseStandardTrainingDto>;
    })
  );
}

getAllStandardTrainings.PATH = '/standard-training';
