import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Logger } from '@nsalaun/ng-logger';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AsiContext } from '../models/asi-context.model';
import { AsiContextService } from '../services/asi-context.service';

@Injectable({
	providedIn: 'root',
})
export class AsiCustomerContextGuard implements CanActivate {
	constructor(private router: Router, private asiContextService: AsiContextService, private logger: Logger) {}

	canActivate(): Observable<boolean> {
		return this.asiContextService.getFirstAsiContext().pipe(
			map((asiContext: AsiContext) => asiContext.isCustomerState()),
			tap((boo: boolean) => {
				if (!boo) {
					this.router.navigateByUrl('/staff').catch((error) => {
						this.logger.error('Navigation failed', error);
					});
				}
			}),
		);
	}
}
