/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createRiskPriority } from '../fn/risk-priority-details-controller/create-risk-priority';
import { CreateRiskPriority$Params } from '../fn/risk-priority-details-controller/create-risk-priority';
import { deleteRiskPriority } from '../fn/risk-priority-details-controller/delete-risk-priority';
import { DeleteRiskPriority$Params } from '../fn/risk-priority-details-controller/delete-risk-priority';
import { getAllOccurrenceImpactLevels } from '../fn/risk-priority-details-controller/get-all-occurrence-impact-levels';
import { GetAllOccurrenceImpactLevels$Params } from '../fn/risk-priority-details-controller/get-all-occurrence-impact-levels';
import { getAllOccurrenceProbabilityLevels } from '../fn/risk-priority-details-controller/get-all-occurrence-probability-levels';
import { GetAllOccurrenceProbabilityLevels$Params } from '../fn/risk-priority-details-controller/get-all-occurrence-probability-levels';
import { getRiskPriority } from '../fn/risk-priority-details-controller/get-risk-priority';
import { GetRiskPriority$Params } from '../fn/risk-priority-details-controller/get-risk-priority';
import { OccurrenceImpactLevelDto } from '../models/occurrence-impact-level-dto';
import { OccurrenceProbabilityLevelDto } from '../models/occurrence-probability-level-dto';
import { RiskPriorityDetailsDto } from '../models/risk-priority-details-dto';
import { updateRiskPriority } from '../fn/risk-priority-details-controller/update-risk-priority';
import { UpdateRiskPriority$Params } from '../fn/risk-priority-details-controller/update-risk-priority';


/**
 * Controller handles requests on the risk priority
 */
@Injectable({ providedIn: 'root' })
export class RiskPriorityDetailsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRiskPriority()` */
  static readonly GetRiskPriorityPath = '/customers/{customerId}/risk-priority-details/{riskPriorityDetailsId}';

  /**
   * Get the risk priority of a hazard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRiskPriority()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskPriority$Response(params: GetRiskPriority$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskPriorityDetailsDto>> {
    return getRiskPriority(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the risk priority of a hazard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRiskPriority$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskPriority(params: GetRiskPriority$Params, context?: HttpContext): Observable<RiskPriorityDetailsDto> {
    return this.getRiskPriority$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskPriorityDetailsDto>): RiskPriorityDetailsDto => r.body)
    );
  }

  /** Path part for operation `updateRiskPriority()` */
  static readonly UpdateRiskPriorityPath = '/customers/{customerId}/risk-priority-details/{riskPriorityDetailsId}';

  /**
   * Update a risk priority of a hazard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRiskPriority()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRiskPriority$Response(params: UpdateRiskPriority$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskPriorityDetailsDto>> {
    return updateRiskPriority(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a risk priority of a hazard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRiskPriority$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRiskPriority(params: UpdateRiskPriority$Params, context?: HttpContext): Observable<RiskPriorityDetailsDto> {
    return this.updateRiskPriority$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskPriorityDetailsDto>): RiskPriorityDetailsDto => r.body)
    );
  }

  /** Path part for operation `deleteRiskPriority()` */
  static readonly DeleteRiskPriorityPath = '/customers/{customerId}/risk-priority-details/{riskPriorityDetailsId}';

  /**
   * Delete a risk priority of a hazard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRiskPriority()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiskPriority$Response(params: DeleteRiskPriority$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskPriorityDetailsDto>> {
    return deleteRiskPriority(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a risk priority of a hazard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRiskPriority$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiskPriority(params: DeleteRiskPriority$Params, context?: HttpContext): Observable<RiskPriorityDetailsDto> {
    return this.deleteRiskPriority$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskPriorityDetailsDto>): RiskPriorityDetailsDto => r.body)
    );
  }

  /** Path part for operation `createRiskPriority()` */
  static readonly CreateRiskPriorityPath = '/customers/{customerId}/risk-priority-details';

  /**
   * Create a risk priority of a hazard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRiskPriority()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRiskPriority$Response(params: CreateRiskPriority$Params, context?: HttpContext): Observable<StrictHttpResponse<RiskPriorityDetailsDto>> {
    return createRiskPriority(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a risk priority of a hazard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRiskPriority$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRiskPriority(params: CreateRiskPriority$Params, context?: HttpContext): Observable<RiskPriorityDetailsDto> {
    return this.createRiskPriority$Response(params, context).pipe(
      map((r: StrictHttpResponse<RiskPriorityDetailsDto>): RiskPriorityDetailsDto => r.body)
    );
  }

  /** Path part for operation `getAllOccurrenceProbabilityLevels()` */
  static readonly GetAllOccurrenceProbabilityLevelsPath = '/occurrence-probability-levels';

  /**
   * Get all occurrence probability levels. A occurrence probability level
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOccurrenceProbabilityLevels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccurrenceProbabilityLevels$Response(params?: GetAllOccurrenceProbabilityLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OccurrenceProbabilityLevelDto>>> {
    return getAllOccurrenceProbabilityLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all occurrence probability levels. A occurrence probability level
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllOccurrenceProbabilityLevels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccurrenceProbabilityLevels(params?: GetAllOccurrenceProbabilityLevels$Params, context?: HttpContext): Observable<Array<OccurrenceProbabilityLevelDto>> {
    return this.getAllOccurrenceProbabilityLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OccurrenceProbabilityLevelDto>>): Array<OccurrenceProbabilityLevelDto> => r.body)
    );
  }

  /** Path part for operation `getAllOccurrenceImpactLevels()` */
  static readonly GetAllOccurrenceImpactLevelsPath = '/occurrence-impact-levels';

  /**
   * Get all occurrence impact levels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOccurrenceImpactLevels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccurrenceImpactLevels$Response(params?: GetAllOccurrenceImpactLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OccurrenceImpactLevelDto>>> {
    return getAllOccurrenceImpactLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all occurrence impact levels
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllOccurrenceImpactLevels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOccurrenceImpactLevels(params?: GetAllOccurrenceImpactLevels$Params, context?: HttpContext): Observable<Array<OccurrenceImpactLevelDto>> {
    return this.getAllOccurrenceImpactLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OccurrenceImpactLevelDto>>): Array<OccurrenceImpactLevelDto> => r.body)
    );
  }

}
