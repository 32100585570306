import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../../utils/general.utils';

@Pipe({
	name: 'booleanYesNo',
})
export class BooleanYesNoPipe implements PipeTransform {
	transform(value: boolean | undefined | null): string {
		if (!isDefined(value)) {
			return '-';
		} else if (value) {
			return 'ja';
		} else {
			return 'nein';
		}
	}
}
