/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addRegulations } from '../fn/regulation-controller/add-regulations';
import { AddRegulations$Params } from '../fn/regulation-controller/add-regulations';
import { getAllRegulations } from '../fn/regulation-controller/get-all-regulations';
import { GetAllRegulations$Params } from '../fn/regulation-controller/get-all-regulations';
import { getRegulations } from '../fn/regulation-controller/get-regulations';
import { GetRegulations$Params } from '../fn/regulation-controller/get-regulations';
import { RegulationDto } from '../models/regulation-dto';
import { removeRegulation } from '../fn/regulation-controller/remove-regulation';
import { RemoveRegulation$Params } from '../fn/regulation-controller/remove-regulation';
import { ResponseRegulationDto } from '../models/response-regulation-dto';
import { validateRegulationsCanBeAdded } from '../fn/regulation-controller/validate-regulations-can-be-added';
import { ValidateRegulationsCanBeAdded$Params } from '../fn/regulation-controller/validate-regulations-can-be-added';


/**
 * This controller handles operations on regulations, which are grouped by hazard factors
 */
@Injectable({ providedIn: 'root' })
export class RegulationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRegulations()` */
  static readonly GetRegulationsPath = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations';

  /**
   * Get all regulations of a hazard factor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegulations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegulations$Response(params: GetRegulations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseRegulationDto>> {
    return getRegulations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all regulations of a hazard factor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegulations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegulations(params: GetRegulations$Params, context?: HttpContext): Observable<ResponseRegulationDto> {
    return this.getRegulations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseRegulationDto>): ResponseRegulationDto => r.body)
    );
  }

  /** Path part for operation `addRegulations()` */
  static readonly AddRegulationsPath = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations';

  /**
   * Add regulation to hazard factor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRegulations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRegulations$Response(params: AddRegulations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegulationDto>>> {
    return addRegulations(this.http, this.rootUrl, params, context);
  }

  /**
   * Add regulation to hazard factor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRegulations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRegulations(params: AddRegulations$Params, context?: HttpContext): Observable<Array<RegulationDto>> {
    return this.addRegulations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegulationDto>>): Array<RegulationDto> => r.body)
    );
  }

  /** Path part for operation `removeRegulation()` */
  static readonly RemoveRegulationPath = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations';

  /**
   * Remove regulation to hazard factor.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRegulation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRegulation$Response(params: RemoveRegulation$Params, context?: HttpContext): Observable<StrictHttpResponse<RegulationDto>> {
    return removeRegulation(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove regulation to hazard factor.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRegulation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRegulation(params: RemoveRegulation$Params, context?: HttpContext): Observable<RegulationDto> {
    return this.removeRegulation$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegulationDto>): RegulationDto => r.body)
    );
  }

  /** Path part for operation `getAllRegulations()` */
  static readonly GetAllRegulationsPath = '/regulations';

  /**
   * Get all available regulations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRegulations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRegulations$Response(params?: GetAllRegulations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseRegulationDto>> {
    return getAllRegulations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all available regulations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllRegulations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRegulations(params?: GetAllRegulations$Params, context?: HttpContext): Observable<ResponseRegulationDto> {
    return this.getAllRegulations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseRegulationDto>): ResponseRegulationDto => r.body)
    );
  }

  /** Path part for operation `validateRegulationsCanBeAdded()` */
  static readonly ValidateRegulationsCanBeAddedPath = '/customers/{customerId}/hazard-factor/{hazardFactorId}/regulations/validate';

  /**
   * Returns 200 if all regulations provided can be added to the hazard factor, 409 if at least one of the provided regulations is already found for the hazard factor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateRegulationsCanBeAdded()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateRegulationsCanBeAdded$Response(params: ValidateRegulationsCanBeAdded$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateRegulationsCanBeAdded(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns 200 if all regulations provided can be added to the hazard factor, 409 if at least one of the provided regulations is already found for the hazard factor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateRegulationsCanBeAdded$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateRegulationsCanBeAdded(params: ValidateRegulationsCanBeAdded$Params, context?: HttpContext): Observable<void> {
    return this.validateRegulationsCanBeAdded$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
