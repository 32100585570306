/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillableServiceCategoryOverviewDto } from '../models/billable-service-category-overview-dto';
import { CentStream } from '../models/cent-stream';
import { getAvailableBillableServiceCategoriesForSelectedCustomers } from '../fn/collective-billing-controller/get-available-billable-service-categories-for-selected-customers';
import { GetAvailableBillableServiceCategoriesForSelectedCustomers$Params } from '../fn/collective-billing-controller/get-available-billable-service-categories-for-selected-customers';
import { processCollectiveBilling } from '../fn/collective-billing-controller/process-collective-billing';
import { ProcessCollectiveBilling$Params } from '../fn/collective-billing-controller/process-collective-billing';
import { processCollectiveBillingWithCustomerSecurityExpert } from '../fn/collective-billing-controller/process-collective-billing-with-customer-security-expert';
import { ProcessCollectiveBillingWithCustomerSecurityExpert$Params } from '../fn/collective-billing-controller/process-collective-billing-with-customer-security-expert';


/**
 * Collective Billing
 */
@Injectable({ providedIn: 'root' })
export class CollectiveBillingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `processCollectiveBilling()` */
  static readonly ProcessCollectiveBillingPath = '/customers/collective-billing';

  /**
   * create billable service for several customers collectively by using the selected security expert
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollectiveBilling()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processCollectiveBilling$Response(params: ProcessCollectiveBilling$Params, context?: HttpContext): Observable<StrictHttpResponse<CentStream>> {
    return processCollectiveBilling(this.http, this.rootUrl, params, context);
  }

  /**
   * create billable service for several customers collectively by using the selected security expert
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollectiveBilling$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processCollectiveBilling(params: ProcessCollectiveBilling$Params, context?: HttpContext): Observable<CentStream> {
    return this.processCollectiveBilling$Response(params, context).pipe(
      map((r: StrictHttpResponse<CentStream>): CentStream => r.body)
    );
  }

  /** Path part for operation `processCollectiveBillingWithCustomerSecurityExpert()` */
  static readonly ProcessCollectiveBillingWithCustomerSecurityExpertPath = '/customers/collective-billing-with-customer-security-expert';

  /**
   * create billable service for several customers collectively by using the security expert assigned to the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollectiveBillingWithCustomerSecurityExpert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processCollectiveBillingWithCustomerSecurityExpert$Response(params: ProcessCollectiveBillingWithCustomerSecurityExpert$Params, context?: HttpContext): Observable<StrictHttpResponse<CentStream>> {
    return processCollectiveBillingWithCustomerSecurityExpert(this.http, this.rootUrl, params, context);
  }

  /**
   * create billable service for several customers collectively by using the security expert assigned to the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollectiveBillingWithCustomerSecurityExpert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processCollectiveBillingWithCustomerSecurityExpert(params: ProcessCollectiveBillingWithCustomerSecurityExpert$Params, context?: HttpContext): Observable<CentStream> {
    return this.processCollectiveBillingWithCustomerSecurityExpert$Response(params, context).pipe(
      map((r: StrictHttpResponse<CentStream>): CentStream => r.body)
    );
  }

  /** Path part for operation `getAvailableBillableServiceCategoriesForSelectedCustomers()` */
  static readonly GetAvailableBillableServiceCategoriesForSelectedCustomersPath = '/customers/available-billable-service-categories';

  /**
   * get billable services categories that are available for all selected customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableBillableServiceCategoriesForSelectedCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableBillableServiceCategoriesForSelectedCustomers$Response(params?: GetAvailableBillableServiceCategoriesForSelectedCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>> {
    return getAvailableBillableServiceCategoriesForSelectedCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * get billable services categories that are available for all selected customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableBillableServiceCategoriesForSelectedCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableBillableServiceCategoriesForSelectedCustomers(params?: GetAvailableBillableServiceCategoriesForSelectedCustomers$Params, context?: HttpContext): Observable<Array<BillableServiceCategoryOverviewDto>> {
    return this.getAvailableBillableServiceCategoriesForSelectedCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BillableServiceCategoryOverviewDto>>): Array<BillableServiceCategoryOverviewDto> => r.body)
    );
  }

}
