/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CentStream } from '../../models/cent-stream';
import { ProcessCollectiveBillingForm } from '../../models/process-collective-billing-form';

export interface ProcessCollectiveBilling$Params {
      body: ProcessCollectiveBillingForm
}

export function processCollectiveBilling(http: HttpClient, rootUrl: string, params: ProcessCollectiveBilling$Params, context?: HttpContext): Observable<StrictHttpResponse<CentStream>> {
  const rb = new RequestBuilder(rootUrl, processCollectiveBilling.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CentStream>;
    })
  );
}

processCollectiveBilling.PATH = '/customers/collective-billing';
