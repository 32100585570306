/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseSubmittedToKoodiBillableServiceDto } from '../../models/response-submitted-to-koodi-billable-service-dto';

export interface GetSubmittedToKoodiBillableServices$Params {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  id?: string;
  customerNumber?: string;

/**
 * filter for property securityExpertId by ids
 */
  securityExpertIds?: Array<number>;

/**
 * filter time in decimal by number range from/to
 */
  time?: string;

/**
 * filter date by range from/to
 */
  date?: string;

/**
 * filter for property billableServiceTypeIds by ids
 */
  billableServiceTypeIds?: Array<number>;
  quarter?: string;
  asdNote?: string;
  svgNote?: string;
}

export function getSubmittedToKoodiBillableServices(http: HttpClient, rootUrl: string, params?: GetSubmittedToKoodiBillableServices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSubmittedToKoodiBillableServiceDto>> {
  const rb = new RequestBuilder(rootUrl, getSubmittedToKoodiBillableServices.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('id', params.id, {});
    rb.query('customerNumber', params.customerNumber, {});
    rb.query('securityExpertIds', params.securityExpertIds, {});
    rb.query('time', params.time, {});
    rb.query('date', params.date, {});
    rb.query('billableServiceTypeIds', params.billableServiceTypeIds, {});
    rb.query('quarter', params.quarter, {});
    rb.query('asdNote', params.asdNote, {});
    rb.query('svgNote', params.svgNote, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseSubmittedToKoodiBillableServiceDto>;
    })
  );
}

getSubmittedToKoodiBillableServices.PATH = '/submitted-to-koodi-billable-services';
