/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DefaultWorkingTimeOverdraftDto } from '../../models/default-working-time-overdraft-dto';

export interface GetDefaultWorkingTimeOverdraft$Params {
  svgId: number;
  workingTimeType: number;
}

export function getDefaultWorkingTimeOverdraft(http: HttpClient, rootUrl: string, params: GetDefaultWorkingTimeOverdraft$Params, context?: HttpContext): Observable<StrictHttpResponse<DefaultWorkingTimeOverdraftDto>> {
  const rb = new RequestBuilder(rootUrl, getDefaultWorkingTimeOverdraft.PATH, 'get');
  if (params) {
    rb.path('svgId', params.svgId, {});
    rb.path('workingTimeType', params.workingTimeType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DefaultWorkingTimeOverdraftDto>;
    })
  );
}

getDefaultWorkingTimeOverdraft.PATH = '/working-time-overdrafts/svg/{svgId}/working-type/{workingTimeType}';
